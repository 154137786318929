import { Button } from "@mui/material";

interface CustomButtonProps {
  portfolioName: string;
  selectedPortfolio: string;
  onClickHandle: (portfolioName: string) => void;
}

export default function PortfoliosButton({
  portfolioName,
  selectedPortfolio,
  onClickHandle,
}: CustomButtonProps) {
  const isContained = selectedPortfolio === portfolioName;
  return (
    <Button
      variant={isContained ? "contained" : "outlined"}
      sx={{
        textTransform: "unset !important",
        padding: "10px 30px 10px 30px",
        borderRadius: "10px",
        fontSize: "16px",
        marginRight: "10px",
      }}
      onClick={() => onClickHandle(portfolioName)}
    >
      {portfolioName}
    </Button>
  );
}
