import React from "react";
import * as PropTypes from "prop-types";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

AddButton.propTypes = {
  handleAdd: PropTypes.func
};

export default function AddButton({ handleAdd }) {
  return (
    <div className={""}>
      <Fab
        color="primary"
        aria-label="Add Delivery Reporter"
        size="small"
        onClick={handleAdd}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
