import { useContext } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { useQuery } from "@tanstack/react-query";
import { Region } from "./useRegionStorage";

function useRegions() {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: ["regions"],
    queryFn: async (): Promise<Region[]> => {
      const data = await apiClient.getRegions();
      return data.regions;
    }
  });
}

export default useRegions;
