import { useContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { ApiClientContext } from "../../ApiClient/context";
import Alert from "@material-ui/lab/Alert";
import ViewProjectDetails from "./ViewProjectDetails";
import EditProjectDetails from "./EditProjectDetails";

export default function ProjectDetails({ project, setUpdatedDate, onUpdate }) {
  const apiClient = useContext(ApiClientContext);
  const [editable, setEditable] = useState(false);
  const [response, setResponse] = useState({ message: "", severity: "" });
  const [showMessage, setShowMessage] = useState(false);

  const toggleEditing = () => {
    setEditable(!editable);
  };

  const updateProjectHandler = async (projectId, projectDetails) => {
    apiClient
      .updateProject(projectId, projectDetails)
      .then(() => {
        setResponse({
          message: "Project details updated successfully",
          severity: "success",
        });
        if (setUpdatedDate) {
          setUpdatedDate(new Date());
        }
        toggleEditing();
        onUpdate && onUpdate();
        setShowMessage(true);
      })
      .catch((error) => {
        setResponse({ message: error.message, severity: "error" });
        setShowMessage(true);
      });
  };

  return (
    <>
      {editable ? (
        <EditProjectDetails
          project={project}
          updateProjectHandler={updateProjectHandler}
          toggleEditing={toggleEditing}
        />
      ) : (
        <ViewProjectDetails project={project} toggleEditing={toggleEditing} />
      )}
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(false)}
      >
        <Alert severity={response.severity}>{response.message}</Alert>
      </Snackbar>
    </>
  );
}
