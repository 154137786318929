import { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  List,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

import CreateEvent from "./CreateEvent";
import ViewEvent from "./ViewEvent";

const useStyles = makeStyles(() => ({
  emptyEventsMessage: {
    color: grey["600"],
  },
}));

export default function Events(props) {
  const [events, setEvents] = useState(props.value || []);
  const [openModal, setOpenModal] = useState(false);
  const [alertDisplayed, setAlertDisplayed] = useState(false);
  const classes = useStyles();

  const addEvent = (event) => {
    setEvents((oldEvents) => [...oldEvents, event]);
  };

  const deleteEvent = (id) => {
    setEvents(events.filter((e) => e.id !== id));
  };

  return (
    <Card aria-label="events-section">
      <CardContent>
        {events.length === 0 && (
          <Typography
            variant="overline"
            aria-label="empty-events-message"
            className={classes.emptyEventsMessage}
          >
            No events for this project
          </Typography>
        )}
        <List dense className={classes.root}>
          {events
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((event, index) => {
              return (
                <ViewEvent
                  value={event}
                  key={index}
                  onDelete={deleteEvent}
                  projectId={props.projectId}
                />
              );
            })}
        </List>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CreateIcon />}
          onClick={() => {
            setOpenModal(true);
            setAlertDisplayed(false);
          }}
        >
          Create
        </Button>
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-label="create-event-modal"
          maxWidth="md"
          disablePortal
        >
          <CreateEvent
            setAlertDisplayed={setAlertDisplayed}
            projectId={props.projectId}
            openModal={setOpenModal}
            onEventCreated={addEvent}
          />
        </Dialog>
      </CardActions>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertDisplayed}
        autoHideDuration={3000}
        onClose={() => setAlertDisplayed(false)}
      >
        <Alert severity="success" variant="filled">
          Event created successfully
        </Alert>
      </Snackbar>
    </Card>
  );
}
