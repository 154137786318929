import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { green, red, amber, grey } from "@material-ui/core/colors";
import { isCompulsory } from "../../../../shared/constants/attributes";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => (
  <Radio inputProps={{ "aria-label": "green" }} color="default" {...props} />
));

const RedRadio = withStyles({
  root: {
    color: red[400],
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => (
  <Radio inputProps={{ "aria-label": "red" }} color="default" {...props} />
));

const AmberRadio = withStyles({
  root: {
    color: amber[400],
    "&$checked": {
      color: amber[600],
    },
  },
  checked: {},
})((props) => (
  <Radio inputProps={{ "aria-label": "amber" }} color="default" {...props} />
));

const NotApplicableRadio = withStyles({
  root: {
    color: grey[400],
    "&$checked": {
      color: grey[600],
    },
  },
  checked: {},
})((props) => (
  <Radio
    inputProps={{ "aria-label": "not applicable" }}
    color="default"
    {...props}
  />
));
const GreenLabel = () => <span aria-hidden="True">Green</span>;
const AmberLabel = () => <span aria-hidden="True">Amber</span>;
const RedLabel = () => <span aria-hidden="True">Red</span>;
const NotApplicableLabel = () => <span aria-hidden="True">N/A</span>;

export default function Rag({ onChange, status, name }) {
  const [value, setValue] = useState(status ?? "UNKNOWN");
  const handleOnChange = (e) => {
    setValue(e.target.value);
    onChange(e, name, "status");
  };

  useEffect(() => {
    setValue(status);
  }, [status]);

  return (
    <RadioGroup
      row={true}
      name={name + " Status"}
      value={value}
      onChange={(e) => {
        handleOnChange(e);
      }}
    >
      <FormControlLabel
        value="GREEN"
        control={<GreenRadio />}
        label={<GreenLabel />}
      />
      <FormControlLabel
        value="AMBER"
        control={<AmberRadio />}
        label={<AmberLabel />}
      />
      <FormControlLabel
        value="RED"
        control={<RedRadio />}
        label={<RedLabel />}
      />
      {!isCompulsory(name) && (
        <FormControlLabel
          value="NOTAPPLICABLE"
          control={<NotApplicableRadio />}
          label={<NotApplicableLabel />}
        />
      )}
    </RadioGroup>
  );
}
