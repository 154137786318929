import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { ApiClientContext } from "../../ApiClient/context";
import DashboardFilter from "../constants/DashboardFilter";

const useAvailableDeliveryReportDates = () => {
  const apiClient = useContext(ApiClientContext);
  const storageFilter = localStorage.getItem("filter");
  const selectedFilter = storageFilter
      ? JSON.parse(storageFilter): DashboardFilter.Region;

  return useQuery<string[]>({
    queryKey: ["availableDeliveryReportDates"],
    queryFn: () => {
      if (selectedFilter === DashboardFilter.Region) {
        return apiClient.getAvailableDeliveryReportDates()
      } else {
        return apiClient.getAvailableMarketDeliveryReportDates();
      }
    },
  });
};

export default useAvailableDeliveryReportDates;
