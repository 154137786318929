import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Rag from "./rag";
import Subcategories from "./subcategories";

export default function EditMetric({
  onChange,
  status,
  notes,
  name,
  title,
  subcategories,
}) {
  const [value, setValue] = useState(notes ?? "");

  useEffect(() => {
    setValue(notes);
  }, [notes]);

  function getLabel() {
    let label = `${title} Notes`;
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }

  return (
    <>
      <Rag onChange={onChange(name, "status")} status={status} name={name} />
      {subcategories.length > 0 ? (
        <Subcategories
          children={subcategories}
          onChange={onChange(name, "subcategories")}
          categoryName={name}
        />
      ) : null}
      <TextField
        minRows={6}
        multiline
        label={getLabel()}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(name, "notes")(e);
        }}
        style={{ maxWidth: "100%" }}
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
}
