import useLocalStorageState from "use-local-storage-state";

export type Region = {
  id: number;
  shortName: string;
  longName: string;
}

export const useRegionStorage = () => {
  return useLocalStorageState<Region>("region");
};
