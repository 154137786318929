import { useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ApiClientContext } from "../../ApiClient/context";
import ApiClient from "../../ApiClient";

export default function ApiClientManager({ children }) {
  const { oktaAuth } = useOktaAuth();
  const apiClient = useMemo(() => new ApiClient(oktaAuth), [oktaAuth]);

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}
