import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ApiClientContext } from "../../ApiClient/context";

export default function CopyPendingReportEmailsButton({
  classes,
  onSuccess,
  onError,
  size,
}) {
  const apiClient = useContext(ApiClientContext);

  const copyPendingReportsButtonClick = () => {
    apiClient
      .getPendingReportEmails()
      .then((data) => {
        navigator.clipboard.writeText(data.join(", "));
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  };

  return (
    <Button
      className={classes}
      onClick={copyPendingReportsButtonClick}
      size={size}
      startIcon={<ContentCopyIcon />}
    >
      Copy Pending Report Emails
    </Button>
  );
}
