import React from "react";
import { Card, CardContent, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  techStackContent: {
    padding: "5px",
  },
}));

export default function TechStackTab() {
  const classes = useStyles();

  return (
    <Card className={classes.techStackContent}>
      <CardContent>
        <Typography variant="body1">
          Please update your Tech Stack in Thoughtworks&apos; preferred
          engineering practices tool, Tech Dash! You can find the link here, or
          via OKTA:
        </Typography>
        <br />
        <Link
          variant="body1"
          href="https://web.techdash.thoughtworks.net/dashboard"
          target="_blank"
        >
          https://web.techdash.thoughtworks.net/dashboard
        </Link>
      </CardContent>
    </Card>
  );
}
