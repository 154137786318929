import { useState } from "react";
import { AppBar, Container, Paper, Tabs, Tab, Typography } from "@mui/material";
import {
  Code,
  Equalizer,
  PersonPin,
  Today,
  Attachment as AttachmentIcon,
  WarningRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import TechStackTab from "./TechStackTab";
import MetricsTab from "./MetricsTab";
import PeopleOfInterest from "./PeopleOfInterestTab";
import Events from "./EventsTab";
import Artefacts from "../../../../Components/Artefacts";
import Risks from "../../../../Components/Risks";
import { Project } from "../../../../shared/hooks/useProject";
import useIsDeliveryDirector from "../../../../shared/hooks/useIsDeliveryDirector";
import useLoggedInUser from "../../../../shared/hooks/useLoggedInUser";

interface ProjectDetailsTabPanelProps {
  project: Project;
}

const useStyles = makeStyles(() => ({
  tabButtonStyle: { minWidth: "160px", padding: "9px 12px 6px" },
  noRiskAccessMessageStyle: { position: "relative", padding: "16px" },
}));

export default function ProjectDetailsTabPanel(
  props: ProjectDetailsTabPanelProps,
) {
  const classes = useStyles();
  const project = props.project;
  const [tabIndex, setTabIndex] = useState(0);
  const { data: currentUser } = useLoggedInUser();
  const { data: isDeliveryDirector } = useIsDeliveryDirector();

  const isDeliveryReporter = project.deliveryReporter
    ?.map((reporter) => reporter.email)
    .includes(currentUser?.email || "");

  const renderRisksPanel = (project: Project) => {
    if (isDeliveryDirector || isDeliveryReporter) {
      return <Risks risks={project?.risks} projectId={project?.id} />;
    } else {
      return (
        <div className={classes.noRiskAccessMessageStyle}>
          <>
            <Typography variant="body2">
              Only the Delivery Diretor or Delivery Reporter can see the risks
              associated.
            </Typography>
          </>
        </div>
      );
    }
  };

  return (
    <Container disableGutters maxWidth={false}>
      <AppBar position="static" color="default">
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          value={tabIndex}
          onChange={(_event, newIndex) => setTabIndex(newIndex)}
        >
          <Tab
            icon={<Code />}
            label="Tech Stack"
            className={classes.tabButtonStyle}
          />
          <Tab
            icon={<Equalizer />}
            label="Metrics"
            className={classes.tabButtonStyle}
          />
          <Tab
            icon={<Today />}
            label="Events"
            className={classes.tabButtonStyle}
          />
          <Tab
            icon={<PersonPin />}
            label="People"
            className={classes.tabButtonStyle}
          />
          <Tab
            icon={<AttachmentIcon />}
            label="Artefacts"
            className={classes.tabButtonStyle}
          />
          <Tab
            icon={<WarningRounded />}
            label="Risks"
            className={classes.tabButtonStyle}
          />
        </Tabs>
      </AppBar>
      <Paper
        role="tabpanel"
        aria-label="Tech Stack Tab"
        hidden={tabIndex !== 0}
        square
      >
        <TechStackTab />
      </Paper>
      <Paper
        role="tabpanel"
        aria-label="Metrics Tab"
        hidden={tabIndex !== 1}
        square
      >
        <MetricsTab />
      </Paper>
      <Paper
        role="tabpanel"
        aria-label="Events Tab"
        hidden={tabIndex !== 2}
        square
      >
        <Events value={project.events} projectId={project.id} />
      </Paper>
      <Paper
        role="tabpanel"
        aria-label="People Tab"
        hidden={tabIndex !== 3}
        square
      >
        <PeopleOfInterest
          people={project.peopleOfInterest}
          projectId={project.id}
        />
      </Paper>
      <Paper
        role="tabpanel"
        aria-label="Artefacts Tab"
        hidden={tabIndex !== 4}
        square
      >
        <Artefacts artefacts={project.artefacts} projectId={project.id} />
      </Paper>
      <Paper
        role="tabpanel"
        aria-label="Risks Tab"
        hidden={tabIndex !== 5}
        square
      >
        {renderRisksPanel(project)}
      </Paper>
    </Container>
  );
}
