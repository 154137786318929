import { getAttributeListFromReport } from "../../../shared/constants/attributes";

const initEmptyMetrics = (categories) => {
  return categories?.map((category) => ({
    name: category.value,
    title: category.name,
    notes: "",
    status: "UNKNOWN",
  }));
};
const mapToMetrics = (report) => {
  const categories = getAttributeListFromReport(report);
  if (report.draft) {
    return initEmptyMetrics(categories) || [];
  }
  const metrics = [];

  categories?.forEach((category) => {
    metrics.push({
      name: category.value,
      title: category.name,
      notes: report?.attributes?.[category.value]?.notes ?? "",
      status: report?.attributes?.[category.value]?.status ?? "UNKNOWN",
    });
  });
  return metrics;
};

export const fromResponse = (response) => {
  const metrics = mapToMetrics(response);
  return {
    weekEnding: response?.weekEnding,
    author: response?.author,
    source: response?.source,
    projectName: response?.projectName,
    clientName: response?.clientName,
    metrics: metrics,
    isDraft: response?.draft,
  };
};
