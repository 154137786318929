import { Grid, Typography, Box } from "@mui/material";
import StatusBarChart from "../../StatusBarChart/StatusBarChart";
import BarChartTrendHeader from "./BarChartTrendHeader";
import {
  BarChartData,
  Portfolio,
  PortfolioDeliveryReport,
} from "../../../../../shared/types/Interfaces";

interface PorfoliosTrendsProps {
  portfolios?: Portfolio[];
  portfolioDeliveryReport?: PortfolioDeliveryReport[];
  portfolioTrends?: BarChartData[][];
  weekEnding: string;
}

export default function PorfoliosTrends({
  portfolios,
  portfolioDeliveryReport,
  portfolioTrends,
  weekEnding,
}: PorfoliosTrendsProps) {
  return (
    <Grid container item xs={12} spacing="16px">
      <Grid item xs={12} lg={12}>
        <Box>
          <Typography variant="h2" sx={{ color: "#003D4F" }}>
            Portfolios
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Box>
          <Grid container>
            {portfolios &&
              portfolios
                .filter(
                  (portfolio) => portfolio.name !== "TW Internal Projects"
                )
                .map((portfolio) => {
                  const portfolioReport = portfolioDeliveryReport?.find(
                    (report) => portfolio.id === report.id
                  );
                  const portfolioTrend = portfolioTrends
                    ?.map((data: BarChartData[]) =>
                      data.filter((obj) => obj.portfolioId === portfolio.id)
                    )
                    .flat();
                  return (
                    portfolioReport &&
                    portfolioTrend && (
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        key={portfolioReport?.name}
                        sx={{
                          backgroundColor: "#EEF1F3",
                          borderRadius: "12px",
                          padding: "24px",
                        }}
                      >
                        {
                          <BarChartTrendHeader
                            label={portfolioReport?.name}
                            count={portfolioReport?.overall.projectsCount}
                            date={weekEnding}
                          />
                        }
                        <Typography
                          sx={{
                            color: "#003D4F",
                            fontSize: "16px",
                            fontWeight: 500,
                            padding: "10px 10px 10px 0",
                          }}
                        >
                          Overall status
                        </Typography>
                        <Grid item xs={12}>
                          <Grid
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "12px",
                              padding: "24px",
                              marginBottom: "10px",
                            }}
                          >
                            <StatusBarChart
                              barChartData={portfolioTrend}
                              angle={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  );
                })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
