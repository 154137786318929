import React from "react";
import { Box, List, ListItem, Typography } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FolderShared, Slideshow } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  TypographyInList: {
    paddingLeft: "1em",
  },

  IconBlue: {
    color: "blue",
  },

  IconYellow: {
    color: "#fc8c03",
  },
}));

export default function ContributingPage() {
  const classes = styles();

  return (
    <Box ml={3} mt={8}>
      <Box mb={3}>
        <Typography variant="h4" id="Contributing">
          Contributing
        </Typography>
        <Typography variant="h5">How to get involved with Projector</Typography>
      </Box>
      <Typography variant={"body1"} mb={3}>
        Projector is primarily a UK beach project and generally available for
        all roles - but its worth checking with Mathew Worthington (Tech Lead)
        or Simon Jenkinson (PO) for the current team structure and state of
        project development.
      </Typography>
      <Typography variant="subtitle1">
        Getting started and useful resources
      </Typography>
      <Typography variant={"body1"}>
        <List>
          <ListItem
            role="button"
            component="a"
            target="_blank"
            href="https://docs.google.com/presentation/d/15leDQTmiuN2HeMeT8cxS0nTUcWptAfld2Jc6x0iV47k/edit?usp=sharing"
            button
          >
            <ListItemIcon className={classes.IconYellow}>
              <Slideshow />
              <Typography
                color="textPrimary"
                className={classes.TypographyInList}
              >
                Onboarding deck
              </Typography>
            </ListItemIcon>
          </ListItem>
          <ListItem
            role="button"
            component="a"
            target="_blank"
            href="https://drive.google.com/drive/u/0/folders/0AHYNWxveoK13Uk9PVA"
            button
          >
            <ListItemIcon className={classes.IconBlue}>
              <FolderShared />
              <Typography
                color="textPrimary"
                className={classes.TypographyInList}
              >
                Google Drive of project resources (you may need to request
                access)
              </Typography>
            </ListItemIcon>
          </ListItem>
          <ListItem
            role="button"
            component="a"
            target="_blank"
            href="https://docs.google.com/presentation/d/1dkCv2X36q7rIl3LSsgW6Lmj6PXGa33jzSymRASOK8jo/edit?usp=sharing"
            button
          >
            <ListItemIcon className={classes.IconYellow}>
              <Slideshow />
              <Typography
                color="textPrimary"
                className={classes.TypographyInList}
                style={{ paddingLeft: "1em" }}
              >
                Offboarding deck
              </Typography>
            </ListItemIcon>
          </ListItem>
        </List>
      </Typography>
    </Box>
  );
}
