import { useState } from "react";
import { Clear, Save } from "@material-ui/icons";
import SensitivityIndicator from "../../Sensitivity/SensitivityIndicator";
import SensitivitySelector from "../../Sensitivity/SensitivitySelector";
import AccountabilityLevel from "../../../Components/AccountabilityLevel";
import useIsDeliveryDirector from "../../../shared/hooks/useIsDeliveryDirector";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Portfolios from "../../Portfolios";
import Markets from "../../Markets";

const useStyles = makeStyles((StyleTheme) => ({
  projectName: {
    color: StyleTheme.palette.primary.main,
  },
  saveButton: StyleTheme.saveButton,
  cancelButton: StyleTheme.cancelButton,
}));

const isBlank = (value) => !value;

const TextView = ({ id, label, value, onChange, isRequired }) => {
  return (
    <TextField
      required={isRequired}
      id={id}
      label={label}
      value={value.value || ""}
      onChange={(event) =>
        onChange({ value: event.target.value, hasError: false })
      }
      error={value.hasError}
      helperText={value.hasError ? "*Required" : ""}
      variant="standard"
      minRows={4}
      fullWidth
    />
  );
};

export default function EditProjectDetails({
  project,
  updateProjectHandler,
  toggleEditing,
}) {
  const [name, setName] = useState({ value: project.name, hasError: false });
  const [clientName, setClientName] = useState({
    value: project.clientName,
    hasError: false,
  });
  const [deliveryReportEnabled, setDeliveryReportEnabled] = useState(
    project.deliveryReportEnabled || false,
  );
  const [location, setLocation] = useState({
    value: project.location,
    hasError: false,
  });
  const [domain, setDomain] = useState({
    value: project.domain,
    hasError: false,
  });
  const [crmAccountReference, setCrmAccountReference] = useState(
    project.crmAccountReference,
  );
  const [description, setDescription] = useState(project.description);
  const [projectEndDate, setProjectEndDate] = useState(project.projectEndDate);
  const [projectStartDate, setProjectStartDate] = useState(
    project.projectStartDate,
  );
  const [accountabilityLevel, setAccountabilityLevel] = useState(
    project.accountabilityLevel,
  );
  const [portfolioId, setPortfolioId] = useState(() => {
    if (project.portfolio) {
      return project.portfolio.id;
    }
  });
  const [crmMarketReference, setCrmMarketReference] = useState(() => {
    if (project.market) {
      return project.market.crmMarketReference;
    }
  });
  const [sensitivity, setSensitivity] = useState(
    project.sensitivity || "UNKNOWN",
  );
  const [startDateHasError, setStartDateHasError] = useState(false);
  const [deliveryDirectorsNotes, setDeliveryDirectorsNotes] = useState(
    project.deliveryDirectorsNotes || "",
  );

  const isDeliveryDirector = useIsDeliveryDirector();
  const classes = useStyles();

  function validateNameField() {
    if (isBlank(name.value)) {
      setName({ ...name, hasError: true });
      return false;
    }
    return true;
  }

  function validateClientField() {
    if (isBlank(clientName.value)) {
      setClientName({ ...clientName, hasError: true });
      return false;
    }
    return true;
  }

  function validateProjectDates() {
    if (!isBlank(projectEndDate)) {
      if (projectStartDate < projectEndDate) {
        return true;
      }
    }
    setStartDateHasError(true);
    return false;
  }

  function areRequiredFieldsValid() {
    let valid = true;
    valid = validateClientField() && valid;
    valid = validateNameField() && valid;
    if (!isBlank(projectStartDate) && !isBlank(projectEndDate)) {
      valid = validateProjectDates() && valid;
    }
    return valid;
  }

  const updateProjectDetails = async () => {
    if (!areRequiredFieldsValid()) {
      return;
    }

    const response = {
      name: name.value,
      clientName: clientName.value,
      location: location.value,
      domain: domain.value,
      description,
      projectStartDate,
      projectEndDate,
      accountabilityLevel,
      sensitivity,
      deliveryReportEnabled,
      portfolio: portfolioId,
      crmMarketReference: crmMarketReference,
      deliveryDirectorsNotes,
      crmAccountReference,
    };
    await updateProjectHandler(project.id, response);
  };

  return (
    <>
      <Grid container>
        <Grid item md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.projectName}>
              <TextView
                id="project-name"
                label="Project Name"
                value={name}
                onChange={setName}
                isRequired
              />
            </Grid>
            <Grid item xs={6} className={classes.projectName}>
              <TextView
                id="client-name"
                label="Client Name"
                value={clientName}
                onChange={setClientName}
                isRequired
              />
            </Grid>
            <Grid item md={6}>
              <TextView
                id="project-location"
                label="Client Location"
                value={location}
                onChange={setLocation}
              />
            </Grid>
            <Grid item md={6}>
              <TextView
                id="project-domain"
                label="Domain"
                value={domain}
                onChange={setDomain}
              />
            </Grid>
            <Grid item md={6}>
              <Portfolios
                onChange={(id) => {
                  setPortfolioId(id);
                }}
                defaultValue={portfolioId}
              />
            </Grid>
            <Grid item md={6}>
              <Markets
                onChange={(id) => {
                  setCrmMarketReference(id);
                }}
                defaultValue={crmMarketReference}
              />
            </Grid>
            <Grid item md={6}>
              <AccountabilityLevel
                value={accountabilityLevel}
                onChange={setAccountabilityLevel}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="project-description"
                label="Description / Link to Engagement Report(s)"
                value={description || ""}
                onChange={(event) => setDescription(event.target.value)}
                variant="standard"
                minRows={4}
                fullWidth
                multiline={true}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                id="project-start-date"
                label="Project Start"
                type="date"
                onChange={(event) => {
                  setProjectStartDate(event.target.value);
                }}
                value={projectStartDate || ""}
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={startDateHasError}
                helperText={
                  startDateHasError && "Start date must be before End Date"
                }
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                id="project-end-date"
                label="Project Close"
                type="date"
                onChange={(event) => {
                  setProjectEndDate(event.target.value);
                }}
                value={projectEndDate || ""}
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="crm-account-reference"
                label="CRM Account Reference"
                value={crmAccountReference || ""}
                onChange={(event) => setCrmAccountReference(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deliveryReportEnabled}
                    onChange={(e) => setDeliveryReportEnabled(e.target.checked)}
                  />
                }
                label="Enable delivery reporting"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid container direction="column" alignItems="center">
            <SensitivityIndicator sensitivity={sensitivity} />

            <SensitivitySelector
              value={sensitivity}
              onChange={(value) => setSensitivity(value)}
            />
          </Grid>
        </Grid>
        {isDeliveryDirector.data && toggleEditing && (
          <Grid item md={12}>
            <TextField
              id="delivery-directors-notes"
              label="Delivery Directors Notes"
              value={deliveryDirectorsNotes || ""}
              onChange={(event) =>
                setDeliveryDirectorsNotes(event.target.value)
              }
              variant="standard"
              minRows={4}
              fullWidth
              multiline={true}
              maxRows={20}
            />
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid item md={9}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              onClick={updateProjectDetails}
            >
              Save
            </Button>
            <Box component="span" marginLeft={1} />
            <Button
              variant="contained"
              startIcon={<Clear />}
              onClick={toggleEditing}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
