import { Grid, Typography, Box } from "@mui/material";
import usePortfolios from "../../../../shared/hooks/usePortfolios";
import { useRegionStorage } from "../../../../shared/hooks/useRegionStorage";
import StatusChart from "../StatusChart";
import { regions } from "../../../../shared/constants";
import useDeliveryReportsSummary from "../../../../shared/hooks/useDeliveryReportsSummary";

interface WeekSnapshotProps {
  weekEnding: string;
}

export default function WeekSnapshot({ weekEnding }: WeekSnapshotProps) {
  const [region] = useRegionStorage();
  const { data: portfolios } = usePortfolios();
  const { data: deliveryReportSummary } = useDeliveryReportsSummary(weekEnding);

  return (
    <Grid container item xs={12} rowSpacing="24px">
      <Grid item xs={12}>
        <Typography variant="h1" color="#003D4F">
          Snapshot
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing="16px">
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              backgroundColor: "#003D4F",
              borderRadius: "12px",
              padding: "24px",
              height: "100%",
            }}
          >
            <Typography variant="h2" sx={{ color: "white" }}>
              All projects
            </Typography>
            {deliveryReportSummary?.overall && (
              <StatusChart
                title="Overall Status"
                statusData={deliveryReportSummary.overall.rag}
                change={deliveryReportSummary.overall.change}
                size="LARGE"
                showLegend
                showValues
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              backgroundColor: "#003D4F",
              borderRadius: "12px",
              padding: "24px",
              height: "100%",
            }}
          >
            <Typography variant="h2" sx={{ color: "white" }}>
              Portfolios
            </Typography>
            <Grid container>
              {portfolios
                ?.filter(
                  (portfolio) => portfolio.name !== "TW Internal Projects"
                )
                ?.map((portfolio) => {
                  const portfolioReport =
                    deliveryReportSummary?.portfolios.find(
                      (report) => portfolio.id === report.id
                    );
                  return (
                    portfolioReport && (
                      <Grid item xs={6} key={portfolio.name}>
                        <StatusChart
                          title={portfolio.name}
                          statusData={portfolioReport.overall.rag}
                          change={portfolioReport.overall.change}
                          size="MEDIUM"
                        />
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              backgroundColor: "#003D4F",
              borderRadius: "12px",
              padding: "24px",
              height: "100%",
            }}
          >
            <Typography variant="h2" sx={{ color: "white" }}>
              Risk criteria
            </Typography>
            <Grid container>
              {region &&
                regions[region.shortName]?.categories
                  ?.filter((category) => category.value !== "overall")
                  ?.map(
                    (category) =>
                      deliveryReportSummary?.riskCriteria[category.value] && (
                        <Grid item xs={4} key={category.value}>
                          <StatusChart
                            title={category.name}
                            statusData={
                              deliveryReportSummary.riskCriteria[category.value]
                                .rag
                            }
                            change={
                              deliveryReportSummary.riskCriteria[category.value]
                                .change
                            }
                            size="SMALL"
                          />
                        </Grid>
                      )
                  )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="#003D4F">
          % change is calculated as the percentage point increase or decrease in
          green status.
        </Typography>
        <Typography variant="body1" color="#003D4F">
          Red coloured % means there was a decrease in green status. Green
          coloured % means there was an increase in green status.
        </Typography>
      </Grid>
    </Grid>
  );
}
