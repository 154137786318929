import React from "react";
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography
} from "@material-ui/core";
import SubcategoryItem from "./subcategory-item";

const subcategoriesStatusNames: string[] = [
  "Already have it",
  "Needs updating",
  "Working on it",
  "Don't have it",
  "Need help",
  "Not applicable"
];

interface Subcategory {
  name: string;
  status: string;
}

interface Props {
  children: Subcategory[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>, categoryName: string, subcategory: string) => void;
  categoryName: string;
}

const Subcategories: React.FC<Props> = ({ onChange, children, categoryName }) => {
  return (
    <TableContainer
      style={{
        marginBottom: "16px",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {subcategoriesStatusNames.map((status, index) => {
              return (
                <TableCell key={status} align="center">
                  <Typography style={{ fontWeight: "bold" }} variant="caption">
                    {status}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {children.map(({ name, status }) => (
            <SubcategoryItem
              key={name}
              name={name}
              status={status}
              categoryName={categoryName}
              onChange={onChange}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Subcategories;