import React, { useEffect, useState } from 'react';
import { TableRow, TableCell } from "@material-ui/core";

interface SubcategoryItemProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, categoryName: string, subcategory: string) => void,
  name: string,
  status: string,
  categoryName: string,
}

const subcategoriesStatusColumns = [
  { id: 1, name: "ALREADY_HAVE_IT" },
  { id: 2, name: "NEEDS_UPDATING" },
  { id: 3, name: "WORKING_ON_IT" },
  { id: 4, name: "DONT_HAVE" },
  { id: 5, name: "NEED_HELP" },
  { id: 6, name: "NOT_APPLICABLE"},
];

export default function SubcategoryItem({
  name,
  status,
  categoryName,
  onChange,
}: SubcategoryItemProps) {
  const [selected, setSelected] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
    onChange(event, categoryName, "subcategories");
  };

  useEffect(() => {
    setSelected(status);
  }, [status]);

  return (
    <TableRow key={name}>
      <TableCell>{name}</TableCell>
      {subcategoriesStatusColumns.map(({ id, name: optionName }) => (
        <TableCell key={id}>
          {optionName === "ALREADY_HAVE_IT" &&
            <input
              name={name}
              type="radio"
              value={optionName}
              checked={selected === optionName}
              onChange={handleChange}
              style={{ accentColor: "green" }}
            />
          }
          {optionName === "NEEDS_UPDATING" &&
            <input
              name={name}
              type="radio"
              value={optionName}
              checked={selected === optionName}
              onChange={handleChange}
              style={{ accentColor: "#FFD191" }}
            />
          }
          {optionName === "WORKING_ON_IT" &&
            <input
              name={name}
              type="radio"
              value={optionName}
              checked={selected === optionName}
              onChange={handleChange}
              style={{ accentColor: "#FFFFBF" }}
            />
          }
          {optionName === "DONT_HAVE" &&
            <input
              name={name}
              type="radio"
              value={optionName}
              checked={selected === optionName}
              onChange={handleChange}
              style={{ accentColor: "#FFDFD4" }}
            />
          }
          {optionName === "NEED_HELP" &&
            <input
              name={name}
              type="radio"
              value={optionName}
              checked={selected === optionName}
              onChange={handleChange}
              style={{ accentColor: "red" }}
            />
          }
          {optionName === "NOT_APPLICABLE" &&
              <input
                  name={name}
                  type="radio"
                  value={optionName}
                  checked={selected === optionName}
                  onChange={handleChange}
                  style={{ accentColor: "darkgrey" }}
              />
          }
        </TableCell>
      ))}
    </TableRow>
  );
}