import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ApiClientContext } from "../../ApiClient/context";
import ProjectDetails from "../ProjectDetails";
import ProjectDetailsTabPanel from "../../pages/DeliveryReportPage/components/ProjectDetailsTabPanel";
import LastUpdatedBy from "../LastUpdatedBy";
import DeliveryReport from "../DeliveryReport";
import RetrievalError from "../../Components/RetrievalError";
import DashboardFilter from "../../shared/constants/DashboardFilter";

export default function ProjectDetailView(props) {
  const id = props.id;
  const [project, setProject] = useState(null);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [isError, setIsError] = useState(false);
  const apiClient = useContext(ApiClientContext);
  const storageFilter = localStorage.getItem("filter");
  const selectedFilter = storageFilter
      ? JSON.parse(storageFilter)
      : DashboardFilter.Region;

  // TODO: refactor to useProject hook
  const fetchProject = useCallback(async () => {
    await apiClient
      .getProject(id)
      .then((data) => {
        data && setProject(data);
      })
      .catch(() => setIsError(true));
  }, [apiClient, id]);

  useEffect(() => {
    if (props.open) {
      fetchProject();
    }
  }, [props.open, id, apiClient, updatedDate, fetchProject]);

  const onUpdateProjectDetail = () => fetchProject();

  return (
    <Dialog
      maxWidth="xl"
      open={props.open}
      fullScreen
      onClose={() => {
        setProject(null);
        props.onClose(true);
      }}
      aria-label="project detail view"
    >
      <DialogActions>
        <IconButton
          color="primary"
          aria-label="close-modal-button"
          onClick={() => {
            setProject(null);
            props.onClose(true);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        {isError && (
          <RetrievalError
            message="Could not fetch project"
            path="/projects"
            actionText="Back to projects"
          />
        )}
        {project && !isError && (
          <Container>
            <Paper>
              <Box padding={3}>
                <ProjectDetails
                  setUpdatedDate={setUpdatedDate}
                  project={project}
                  onUpdate={props.onUpdate}
                />
              </Box>
              <Box marginTop={3} />
            </Paper>
            <Paper>
              <DeliveryReport
                setUpdatedDate={setUpdatedDate}
                projectId={project.id}
                onUpdate={onUpdateProjectDetail}
                onUpdateCurrentWeek={props.onUpdate}
                selectedReportDate={props.selectedReportDate}
                deliveryReporters={project.deliveryReporter}
                projectDetails={{
                  name: project.name,
                  clientName: project.clientName,
                  location: project.location,
                  domain: project.domain,
                  description: project.description,
                  projectEndDate: project.projectEndDate,
                  accountabilityLevel: project.accountabilityLevel,
                  sensitivity: project.sensitivity,
                  deliveryReportEnabled: project.deliveryReportEnabled,
                  portfolio: project.portfolioId,
                  monthlyReportingEnabled: project.monthlyReportingEnabled,
                }}
              >
                {selectedFilter === DashboardFilter.Market ?  project.marketDeliveryReports: project.deliveryReports}
              </DeliveryReport>
            </Paper>
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <ProjectDetailsTabPanel project={project} />
            </Grid>
          </Container>
        )}
      </DialogContent>
      <DialogActions>
        {project && (
          <Container>
            <LastUpdatedBy
              data={{
                updatedBy: project?.updatedBy,
                updatedOn: project?.updatedOn,
                createdBy: project?.createdBy,
                createdOn: project?.createdOn,
              }}
            />
          </Container>
        )}
      </DialogActions>
    </Dialog>
  );
}
