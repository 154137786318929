import React from "react";
import Alert from "@mui/material/Alert";
import { Stack } from "@mui/material";

export default function LockedAlert() {
  return (
    <Stack direction="row" justifyContent="center">
      <Alert variant="filled" severity="error" data-testid="alert-error">
        This report is locked and no longer editable.
      </Alert>
    </Stack>
  );
}
