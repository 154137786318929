import { useContext, useState, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import Heading from "./Heading";
import WeeklyReportDialog from "./WeeklyReportDialog";
import Reporter from "./Reporter";
import { ApiClientContext } from "../../ApiClient/context";
import ErrorIcon from "@material-ui/icons/Error";
import config from "../../config";
import { useRegionStorage } from "../../shared/hooks/useRegionStorage";
import useIsDeliveryDirector from "../../shared/hooks/useIsDeliveryDirector";
import useLoggedInUser from "../../shared/hooks/useLoggedInUser";
import { fromResponse } from "./WeeklyReportDialog/mapper";

const useStyles = makeStyles({
  graph: {
    paddingTop: "2em",
    width: "100%",
    display: "inline-grid",
    gridTemplateColumns: "auto 1fr",
    justifyItems: "flex-end",
  },
  metric: {
    display: "grid",
    gridTemplateRows: "repeat(7, 35px)",
    listStyleType: "none",
    paddingLeft: "0px",
    width: "auto",
  },
  cell: {
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.54)" },
    border: "1px solid hsl(12, 0%, 98%)",
    cursor: "pointer",
  },
  nonClickableCell: {
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.54)" },
    border: "1px solid hsl(12, 0%, 98%)",
    cursor: "default",
  },
  red: {
    backgroundColor: "lightcoral",
  },
  amber: {
    backgroundColor: "rgb(252, 199, 101)",
  },
  green: {
    backgroundColor: "lightgreen",
  },
  notapplicable: {
    backgroundColor: "darkgrey",
  },
  unknown: {
    backgroundColor: "#f5f5f5",
  },
  yellow: {
    backgroundColor: "#ffffbf",
  },
  orange: {
    backgroundColor: "#ffd191",
  },
  lightred: {
    backgroundColor: "#ffdfd4",
  },
  darkgrey: {
    backgroundColor: "darkgray",
  },
  error: {
    paddingLeft: 25,
    marginBottom: -10,
    color: "red",
    paddingBottom: 0,
  },
  squares: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "35px",
    listStyleType: "none",
    cursor: "pointer",
  },
  emailDomainLabel: {
    "&>p": {
      padding: 0,
    },
  },
  dateHeaders: {
    fontSize: "11px",
    width: "34.5px",
    whiteSpace: "nowrap",
    transform: "rotate(310deg) translate(8px, 1px)",
    cursor: "auto",
  },
  subCategoriesLi: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    textIndent: "15px",
  },
});

DeliveryReport.propTypes = {
  children: PropTypes.any,
  projectId: PropTypes.any,
  onUpdate: PropTypes.func,
  onUpdateCurrentWeek: PropTypes.func,
  selectedReportDate: PropTypes.any,
  setUpdatedDate: PropTypes.func,
  deliveryReporters: PropTypes.array,
  projectDetails: PropTypes.any,
};

export default function DeliveryReport(props) {
  const {
    children,
    projectId,
    onUpdate,
    onUpdateCurrentWeek,
    selectedReportDate,
    setUpdatedDate,
    deliveryReporters,
    projectDetails,
  } = props;

  const [currentWeek, setCurrentWeek] = useState(false);
  const [deliveryReporterError, setDeliveryReporterError] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [openDeliveryReportDialog, setOpenDeliveryReportDialog] =
    useState(false);
  const [lastReportWorkedOn, setlastReportWorkedOn] = useState(null);

  const apiClient = useContext(ApiClientContext);
  const { data: isDeliveryDirector } = useIsDeliveryDirector();
  const { data: currentUser } = useLoggedInUser();
  const [region] = useRegionStorage();

  const months = children?.map((x) => new Date(x.weekEnding));
  const gridStartDate = new Date(Math.min.apply(null, months));
  const gridEndDate = new Date(Math.max.apply(null, months));
  const hasMultipleYears =
    gridStartDate.getFullYear - gridEndDate.getFullYear !== 0;
  const thisWeeksReport =
    children &&
    children.sort((x, y) => new Date(x.weekEnding) - new Date(y.weekEnding))[
      children.length - 1
    ];

  const getPreviousWeeksReport = (report) => {
    const reports = children?.sort(
      (x, y) => new Date(y.weekEnding) - new Date(x.weekEnding),
    );
    const selectedWeekEnding = report?.weekEnding;
    const index = reports
      ?.map((report) => report.weekEnding)
      .indexOf(selectedWeekEnding);
    const previousReport = reports[index + 1]; // Due to sorted array

    return previousReport?.source !== "GENERATED" ? previousReport : null;
  };

  const getLastReportWorkedOn = () => {
    const sortedChildren = children?.sort(
      (x, y) => new Date(y.weekEnding) - new Date(x.weekEnding),
    );
    return sortedChildren?.find((child) => {
      return child.source !== "GENERATED";
    });
  };

  const canUpdate = (currentUser, deliveryReporters) => {
    return (
      deliveryReporters
        ?.map((reporter) => reporter.email)
        .includes(currentUser?.email) || isDeliveryDirector
    );
  };

  const deliveryReportingLockDisabled = () => {
    return (
      config.featureToggles.disableLockingDeliveryReportEditingForRegions.includes(
        region?.shortName,
      ) || isDeliveryDirector
    );
  };

  const openDialogIfReporterExists = (report) => {
    const lastWorkedReport = getLastReportWorkedOn();
    const isLastColumn = thisWeeksReport.weekEnding === report.weekEnding;
    const isLastColumnUnLocked =
      (isLastColumn && !isTuesdayOrLockingDisabled()) ||
      deliveryReportingLockDisabled();

    if (
      isLastColumnUnLocked &&
      !canUpdate(currentUser, deliveryReporters) &&
      !isDeliveryDirector
    ) {
      return setDeliveryReporterError(true);
    }

    if (deliveryReporters?.length > 0) {
      setDeliveryReporterError(false);

      isLastColumnUnLocked ? setIsLocked(false) : setIsLocked(true);
      new Date(report.weekEnding).getTime() >
      new Date(lastWorkedReport?.weekEnding).getTime()
        ? setlastReportWorkedOn(lastWorkedReport)
        : setlastReportWorkedOn(null);
      setCurrentWeek(report);
      setOpenDeliveryReportDialog(true);
    } else {
      setDeliveryReporterError(true);
    }
  };

  const isTuesdayOrLockingDisabled = () => {
    const dayOfWeek = new Date(Date.now()).getDay();
    const tuesday = 2;

    return projectDetails.monthlyReportingEnabled === true
      ? false
      : dayOfWeek === tuesday;
  };

  const getCellClass = (metric) => {
    return metric?.status === "RED"
      ? classes.red
      : metric?.status === "AMBER"
        ? classes.amber
        : metric?.status === "GREEN"
          ? classes.green
          : metric?.status === "NOTAPPLICABLE"
            ? classes.notapplicable
            : classes.unknown;
  };

  const getCellClassSubcategories = (subcategory) => {
    return subcategory?.status === "DONT_HAVE"
      ? classes.lightred
      : subcategory?.status === "NEED_HELP"
        ? classes.red
        : subcategory?.status === "ALREADY_HAVE_IT"
          ? classes.green
          : subcategory?.status === "NEEDS_UPDATING"
            ? classes.orange
            : subcategory?.status === "WORKING_ON_IT"
              ? classes.yellow
              : subcategory?.status === "NOT_APPLICABLE"
                ? classes.drakgrey
                : classes.unknown;
  };

  const getColorStatusSubcategory = (status) => {
    return status === "DONT_HAVE"
      ? "lightred"
      : status === "NEED_HELP"
        ? "red"
        : status === "ALREADY_HAVE_IT"
          ? "green"
          : status === "NEEDS_UPDATING"
            ? "orange"
            : status === "WORKING_ON_IT"
              ? "yellow"
              : status === "NOT_APPLICABLE"
                ? "not applicable"
                : "unknown";
  };

  const getCellTitle = (report, metricName) => {
    const metricData = report?.attributes?.[metricName];
    const weekEnding = getDate(report.weekEnding);
    const title = weekEnding + " " + metricName + " status ";
    const status = metricData?.status
      ? metricData.status
          .toLowerCase()
          .replace("notapplicable", "not applicable")
      : "unknown";
    return title + status;
  };

  const getCellTitleSubcategory = (report, subcategory) => {
    const weekEnding = getDate(report.weekEnding);
    const title = weekEnding + " " + subcategory?.name + " status ";
    const status = subcategory?.status
      ? getColorStatusSubcategory(subcategory.status)
          .toLowerCase()
          .replace("notapplicable", "not applicable")
      : "unknown";
    return title + status;
  };

  const getCell = (report, metricName, key) => {
    return (
      <button
        key={key}
        data-testid="cell"
        type="button"
        title={getCellTitle(report, metricName)}
        className={[
          getCellClass(report?.attributes?.[metricName]),
          projectDetails.deliveryReportEnabled
            ? classes.cell
            : classes.nonClickableCell,
        ].join(" ")}
        onClick={() =>
          projectDetails.deliveryReportEnabled &&
          openDialogIfReporterExists(report)
        }
      />
    );
  };

  const getCellSubcategories = (report, subcategory, key) => {
    return (
      <button
        key={key}
        data-testid="cell"
        type="button"
        title={getCellTitleSubcategory(report, subcategory)}
        className={[
          getCellClassSubcategories(subcategory),
          projectDetails.deliveryReportEnabled
            ? classes.cell
            : classes.nonClickableCell,
        ].join(" ")}
        onClick={() =>
          projectDetails.deliveryReportEnabled &&
          openDialogIfReporterExists(report)
        }
      />
    );
  };

  const getCells = (report, canUpdate, index) => {
    const cells = [];
    const metrics = fromResponse(report).metrics;
    for (let i = 0; i < metrics?.length; i++) {
      const metric = metrics[i]?.name;
      cells.push(getCell(report, canUpdate ? null : metric, `${index}-${i}`));
      for (let s = 0; s < metrics[i]?.subcategories?.length; s++) {
        const subcategory = report.attributes?.[metric].subcategories[s];
        const subName = subcategory.name;
        cells.push(
          getCellSubcategories(report, subcategory, `${index}-${subName}-${i}`),
        );
      }
    }
    return cells;
  };

  const getSquareGridRowCount = (report) => {
    const metrics = report.metrics;
    let subcategoriesLength = 0;

    for (let i = 0; i < metrics.length; i++) {
      subcategoriesLength += metrics[i].subcategories?.length ?? 0;
    }

    return metrics.length + subcategoriesLength;
  };

  function getDate(inputDate, hasMultipleYears) {
    const baseFormat = { day: "numeric", month: "short" };
    const format = hasMultipleYears
      ? { ...baseFormat, year: "2-digit" }
      : baseFormat;
    return new Intl.DateTimeFormat(undefined, format).format(
      new Date(inputDate),
    );
  }

  const handleReportersSave = async (reporters) => {
    try {
      await apiClient.updateDeliveryReporter(projectId, reporters);

      if (onUpdate) {
        onUpdate();
        setDeliveryReporterError(false);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const classes = useStyles();

  if (!children) {
    return null;
  }

  const lastReport = fromResponse(children[children.length - 1]);

  return (
    <Grid container style={{ overflowY: "auto", padding: "2em" }}>
      <Grid item md={12}>
        <Heading start={gridStartDate} end={gridEndDate}></Heading>
        {!projectDetails.deliveryReportEnabled && (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ padding: "2em" }}
          >
            <ErrorIcon color="error" /> Delivery Reporting disabled
          </Grid>
        )}
        <div className={classes.graph}>
          <ul className={classes.metric}>
            <li
              style={{
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              Period Ending
            </li>
            {lastReport?.metrics?.length > 0 &&
              lastReport?.metrics?.map((metric) => (
                <Fragment key={metric.name}>
                  <li
                    key={metric.name}
                    style={{ whiteSpace: "nowrap" }}
                    title={metric.title}
                  >
                    {metric.title}
                  </li>
                  {metric.subcategories?.map((subcategory) => (
                    <li
                      key={subcategory.name}
                      title={subcategory.name}
                      className={classes.subCategoriesLi}
                    >
                      {subcategory.name}
                    </li>
                  ))}
                </Fragment>
              ))}
          </ul>
          <ul
            className={classes.squares}
            style={{
              gridTemplateRows: `repeat(${getSquareGridRowCount(lastReport) + 1}, 35px)`,
            }}
          >
            {children
              .sort((x, y) => new Date(x.weekEnding) - new Date(y.weekEnding))
              .map((report, index) => {
                const editable =
                  !canUpdate(currentUser, deliveryReporters) && report?.draft;

                const cells = [];
                const date = getDate(report.weekEnding, hasMultipleYears);
                cells.push(
                  <li className={classes.dateHeaders} key={date}>
                    {date}
                  </li>,
                );

                cells.push(getCells(report, editable, index));

                return cells;
              })}
          </ul>
        </div>
        <WeeklyReportDialog
          open={openDeliveryReportDialog}
          onClose={() => {
            setOpenDeliveryReportDialog(false);
            setUpdatedDate(new Date());
          }}
          projectId={projectId}
          isLocked={isLocked}
          projectDetails={projectDetails}
          onUpdate={onUpdate}
          onUpdateCurrentWeek={onUpdateCurrentWeek}
          selectedReportDate={selectedReportDate}
          data={currentWeek}
          lastReportWorkedOn={lastReportWorkedOn}
          lastWeeksReport={getPreviousWeeksReport(currentWeek)}
          deliveryReporters={deliveryReporters}
        />
        {deliveryReporterError && (
          <p className={classes.error}>
            {" "}
            * Please add yourself as reporter to be able to submit or edit the
            latest report
          </p>
        )}
        <Reporter
          onSaveReporters={handleReportersSave}
          reporters={deliveryReporters}
          deliveryReportEnabled={projectDetails.deliveryReportEnabled}
        />
        {projectDetails.deliveryReportEnabled && (
          <Box paddingLeft={3} paddingBottom={3}>
            <Button
              disabled={
                isTuesdayOrLockingDisabled() && !deliveryReportingLockDisabled()
              }
              variant="contained"
              color="primary"
              onClick={() => openDialogIfReporterExists(thisWeeksReport)}
            >
              {thisWeeksReport.author
                ? "Edit current report"
                : "Submit current report"}
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
