import React, { useState, useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import {
  Button,
  Card,
  CardActions,
  Grid,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import Artefact from "./Artefact";
import ConfirmationDialog from "../ConfirmationDialog";
import { ApiClientContext } from "../../ApiClient/context";
import validateInput from "../../shared/utils/inputValidator";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  emptyArtefactsMessage: {
    color: grey["600"],
    padding: "20px",
  },
}));

export default function Artefacts({ artefacts, projectId }) {
  const classes = useStyles();
  const apiClient = useContext(ApiClientContext);

  const [artefactsList, setArtefactsList] = useState(artefacts || []);

  const [label, setLabel] = useState("");
  const [labelErrorMessage, setLabelErrorMessage] = useState("");

  const [url, setUrl] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");

  const [alertMessage, setAlertMessage] = useState("");

  const [deletingArtefactId, setDeletingArtefactId] = useState();

  const getSeverity = () => {
    return alertMessage.toLowerCase().includes("success") ? "success" : "error";
  };

  const isLabelValid = () => {
    let valid = true;
    if (!label) {
      valid = false;
      setLabelErrorMessage("Label is required");
    }
    if (label.length > 32) {
      valid = false;
      setLabelErrorMessage("Label must be less than 32 characters");
    }

    valid && setLabelErrorMessage("");
    return valid;
  };

  const openConfirmationDialog = (artefactId) => {
    setDeletingArtefactId(artefactId);
  };

  const isUrlValid = () => {
    let valid = true;

    try {
      new URL(url);
    } catch (e) {
      valid = false;
      setUrlErrorMessage("URL is not valid");
    }

    valid && setUrlErrorMessage("");

    return valid;
  };

  const areInputsValid = () => {
    let valid = true;
    valid = isUrlValid() && valid;
    valid = isLabelValid() && valid;
    return valid;
  };

  const handleSubmit = async () => {
    if (areInputsValid()) {
      let artefact = { label: label, url: url };
      if (!artefact.url.includes("//")) {
        artefact.url = "http://" + artefact.url;
      }
      apiClient
        .createArtefact(projectId, artefact)
        .then((response) => {
          setAlertMessage("Artefact successfully created");
          setArtefactsList([...artefactsList, { ...artefact, id: response }]);
          setLabel("");
          setUrl("");
        })
        .catch(() => {
          setAlertMessage("Artefact could not be created");
        });
    }
  };

  const handleDelete = async (artefactId) => {
    setDeletingArtefactId(null);
    apiClient
      .deleteArtefact(projectId, artefactId)
      .then(() => {
        setArtefactsList(
          [...artefactsList].filter((artefact) => artefact.id !== artefactId)
        );
        setAlertMessage("Artefact successfully deleted");
      })
      .catch((error) => {
        setAlertMessage("Artefact could not be deleted");
      });
  };

  const handleCancel = () => {
    setDeletingArtefactId(null);
  };

  const displayArtefacts = () => {
    return artefactsList.map((artefact) => (
      <Artefact
        artefact={artefact}
        onDelete={openConfirmationDialog}
        key={"artefact_" + artefact.id + "_link"}
      />
    ));
  };

  return (
    <Card data-testid="artefact-card" style={{ minHeight: "220px" }}>
      {!artefactsList.length ? (
        <Typography
          variant="overline"
          aria-label="empty-events-message"
          className={classes.emptyArtefactsMessage}
        >
          There are currently no artefacts associated with this project
        </Typography>
      ) : (
        <div align="center" style={{ padding: "20px" }}>
          {displayArtefacts()}
        </div>
      )}
      <CardActions>
        <Grid
          container
          alignItems="flex-start"
          spacing={3}
          className={classes.gridContainer}
        >
          <Grid item xs>
            <TextField
              id="label"
              label="Label *"
              value={label}
              error={Boolean(labelErrorMessage)}
              helperText={labelErrorMessage}
              onChange={(e) => {
                if (validateInput(e.currentTarget.value, "")) {
                  setLabel(e.target.value);
                }
              }}
              onBlur={isLabelValid}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="URL"
              label="URL *"
              value={url}
              error={Boolean(urlErrorMessage)}
              helperText={urlErrorMessage}
              onChange={(e) => {
                const emoji =
                  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
                if (!e.target.value.match(emoji)) {
                  setUrl(e.target.value);
                }
              }}
              onBlur={isUrlValid}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                handleSubmit();
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {Boolean(alertMessage) && (
          <Snackbar
            open={Boolean(alertMessage)}
            autoHideDuration={6000}
            onClose={() => setAlertMessage("")}
          >
            <Alert severity={getSeverity()}>{alertMessage}</Alert>
          </Snackbar>
        )}
      </CardActions>
      <ConfirmationDialog
        deletingId={deletingArtefactId}
        onCancel={handleCancel}
        onDelete={handleDelete}
        title="Are you sure you want to delete this artefact?"
        message="Deleting artefacts is irreversible, you will not be able to undo this"
      />
    </Card>
  );
}
