import { Grid, Typography } from "@mui/material";
import usePortfolios from "../../../../shared/hooks/usePortfolios";
import useDeliveryReportsSummary from "../../../../shared/hooks/useDeliveryReportsSummary";
import useDeliveryTrendsReports from "../../../../shared/hooks/useDeliveryTrendsReports";
import AllProjectsTrends from "./components/AllProjectsTrends";
import PorfoliosTrends from "./components/PortfoliosTrends";
import RiskCriteriaTrends from "./components/RiskCriteriaTrends";
interface SixMonthTrendsProps {
  weekEnding: string;
}

export default function SixMonthTrends({ weekEnding }: SixMonthTrendsProps) {
  const portfolios = usePortfolios();
  const { data: deliveryReportSummary } = useDeliveryReportsSummary(weekEnding);
  const { data: availableDeliveryReports } = useDeliveryTrendsReports();

  return (
    <Grid container item xs={12} rowSpacing="24px">
      <Grid item xs={12}>
        <Typography variant="h1" color="#003D4F">
          6 month trends
        </Typography>
      </Grid>
      <AllProjectsTrends
        activeClientProjects={deliveryReportSummary?.activeClientProjects}
        allProjectsData={availableDeliveryReports?.allProjects}
        weekEnding={weekEnding}
      />
      <PorfoliosTrends
        portfolios={portfolios && portfolios.data}
        portfolioDeliveryReport={deliveryReportSummary?.portfolios}
        portfolioTrends={availableDeliveryReports?.allPortfolios}
        weekEnding={weekEnding}
      />
      <RiskCriteriaTrends
        portfoliosRiskCriteria={
          availableDeliveryReports?.allPortfolioRiskCriteria
        }
        riskCriteria={availableDeliveryReports?.allRiskCriteria}
        weekEnding={weekEnding}
        activeClientProjects={deliveryReportSummary?.activeClientProjects}
        portfolioDeliveryReport={deliveryReportSummary?.portfolios}
      />
    </Grid>
  );
}
