import { useState, useContext } from "react";
import {
  Alert,
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FlagIcon from "@mui/icons-material/Flag";
import NoteIcon from "@mui/icons-material/Note";
import SchoolIcon from "@mui/icons-material/School";
import SecurityIcon from "@mui/icons-material/Security";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

import { ApiClientContext } from "../../../../../../ApiClient/context";

function EventListItemAccordionTitle(props) {
  const { value } = props;
  return (
    <div>
      <Typography variant="h6">{value.title}</Typography>
      <Typography variant="subtitle2" color={grey[500]}>
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(new Date(value.date))}
      </Typography>
      <Typography variant="subtitle2" color={grey[500]}>
        {value.type}
      </Typography>
    </div>
  );
}

export default function ViewEvent(props) {
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const apiClient = useContext(ApiClientContext);

  const renderTypeIcons = (type) => {
    const defaultProps = { htmlColor: grey[600] };
    switch (type) {
      case "Milestone":
        return <FlagIcon {...defaultProps} />;
      case "Go Live":
        return <DoubleArrowIcon {...defaultProps} />;
      case "Learning":
        return <SchoolIcon {...defaultProps} />;
      case "Success":
        return <EmojiEventsIcon {...defaultProps} />;
      case "Security Kick-off":
        return <SecurityIcon {...defaultProps} />;
      default:
        return <NoteIcon {...defaultProps} />;
    }
  };

  const renderDescription = (description) => {
    return description.split(/\r?\n/).map((paragraph, i) => (
      <Typography key={i} variant="body1">
        {paragraph}
      </Typography>
    ));
  };

  const renderAuthor = (author) => {
    return (
      <Typography variant="subtitle2" color={grey[500]}>
        {author && "created by " + author}
      </Typography>
    );
  };

  const handleDelete = (id) => {
    apiClient
      .deleteEvent(props.projectId, id)
      .then((response) => {
        props.onDelete(id);
      })
      .catch((error) => {
        setErrorMessage("Request failed");
        setErrorAlert(true);
      });
  };

  return (
    <>
      <Accordion
        disableGutters
        elevation={0}
        square
        data-testid="event-accordion"
      >
        <Box display="flex">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ margin: 0, flexGrow: 1 }}
          >
            <Box display="flex" alignItems="center" gap={3}>
              {renderTypeIcons(props.value.type)}
              <EventListItemAccordionTitle value={props.value} />
            </Box>
          </AccordionSummary>
          <Button
            aria-label="delete"
            onClick={() => handleDelete(props.value.id)}
          >
            <DeleteIcon />
          </Button>
        </Box>
        <AccordionDetails sx={{ padding: 0, paddingLeft: "18px" }}>
          <Box>
            {renderDescription(props.value.description)}
            {renderAuthor(props.value.author)}
          </Box>
        </AccordionDetails>
      </Accordion>
      {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
    </>
  );
}
