import { useContext } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { useQuery } from "@tanstack/react-query";
import { Market } from "../types/Interfaces";

interface Markets {
  markets: Market[];
}

const useMarkets = (inUseOnly: boolean) => {
  const apiClient = useContext(ApiClientContext);

  return useQuery<Market[]>({
    queryKey: ["markets" + inUseOnly],
    queryFn: async () => {
      const data: Markets = await apiClient.getMarkets(inUseOnly);

      return data.markets || [];
    },
  });
};

export default useMarkets;
