import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { isEmailNameLegal } from "../../../../shared/utils";
import CopyPendingReportEmailsButton from "../../../../Components/CopyPendingReportEmailsButton";

import { ApiClientContext } from "../../../../ApiClient/context";

export default function EmailReminder({ isOpen, onClose, deliveryReportData }) {
  // const [disabled, setDisabled] = useState(true);
  const apiClient = useContext(ApiClientContext);
  const defaultBody =
    "Morning all,\n\n" +
    "It's that time of the week again, we hope you've all had a good week and we'd like to hear about it in your weekly reports.\n\n" +
    "Here's the link to the projector for submissions:\n" +
    "https://projector.thoughtworks-labs.net/\n\n" +
    "Many thanks\n";
  const defaultForm = {
    recipientEmail: { value: "" },
    emailBody: {
      value: defaultBody,
    },
  };

  const [form, setForm] = useState(defaultForm);
  const [showSnackMessage, setShowSnackMessage] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Email Sent!");

  const isBlank = (value) =>
    value === undefined || value === "" || !value.replace(/\s/g, "").length;

  const resetError = () => {
    onClose();
    const { recipientEmail: { value: emailList } = {} } = form;
    const { emailBody: { value: content } = {} } = form;
    setForm((prevState) => ({
      ...prevState,
      emailBody: {
        value: content,
        hasError: false,
        errorMsg: null,
      },
      recipientEmail: {
        value: emailList,
        hasError: false,
        errorMsg: null,
      },
    }));
  };

  const validateEmailRecipient = () => {
    const { recipientEmail: { value: emailList } = {} } = form;
    if (!isBlank(emailList)) {
      const emails = emailList.split(",").map((email) => email.trim());
      const isValid = emails.every((email) => isEmailNameLegal(email));
      const emailSet = new Set(emails);
      if (emailSet.size !== emails.length) {
        setForm((prevState) => ({
          ...prevState,
          recipientEmail: {
            value: emailList,
            hasError: true,
            errorMsg: "*Duplicate Email",
          },
        }));
        return false;
      }

      if (!isValid) {
        setForm((prevState) => ({
          ...prevState,
          recipientEmail: {
            value: emailList,
            hasError: true,
            errorMsg: "*Illegal Character",
          },
        }));
        return false;
      }
    } else {
      setForm((prevState) => ({
        ...prevState,
        recipientEmail: {
          value: emailList,
          hasError: true,
          errorMsg: "*Required",
        },
      }));
      return false;
    }
    return true;
    // Figure out a way to include "Chip" instead of the text area
  };

  const validateEmailBody = () => {
    const { emailBody: { value: content } = {} } = form;
    if (isBlank(content)) {
      setForm((prevState) => ({
        ...prevState,
        emailBody: {
          value: content,
          hasError: true,
          errorMsg: "*Required",
        },
      }));
      return false;
    }
    return true;
  };

  const handleSendEmail = async () => {
    const isValidBody = validateEmailBody();
    const isValidEmail = validateEmailRecipient();
    if (isValidEmail && isValidBody) {
      const { value: recipients } = form.recipientEmail;
      const { value: body } = form.emailBody;
      const emailList = recipients.split(",").map((email) => email.trim());
      console.log(emailList[0]);
      for (let i = 0; i < emailList.length; i++) {
        try {
          console.log("email sent");
          await apiClient.sendDeliveryReportReminder(emailList[i], body);
          // TODO show success message or close
          setShowSnackMessage(true);
          setSnackMessage("Email Sent!");
        } catch (e) {
          // TODO Handle API ERROR
          console.log("error", e);
        }
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackMessage(false);
  };

  const handleChange = ({ target }) => {
    const { value, id } = target;
    setForm({
      ...form,
      [id]: {
        value,
      },
    });
  };

  const copyPendingReportEmails = (data) => {
    setSnackMessage("Copied to Clipboard");
    setShowSnackMessage(true);

    setForm({
      ...form,
      recipientEmail: {
        value: data.join(", ").replaceAll("@thoughtworks.com", ""),
      },
    });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle id="email-reminder-dialog" onClose={onClose}>
        <Typography>Reminder Email</Typography>
        <IconButton
          className="close-btn-modal"
          color="primary"
          aria-label="close-modal-button"
          onClick={resetError}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <CopyPendingReportEmailsButton
          onSuccess={copyPendingReportEmails}
          onError={() => {
            setSnackMessage("Something went wrong.");
            setShowSnackMessage(true);
          }}
          size={"small"}
        />
        <TextField
          minRows={2}
          multiline
          label={
            <span>
              Recipient Email(s) -{" "}
              <em>
                Use only name alias (<strong>john</strong>
                <strike>@thoughtworks.com</strike>,{" "}
                <strong>smith, sammy.spiers</strong>)
              </em>
            </span>
          }
          name="recipientEmail"
          id="recipientEmail"
          style={{ maxWidth: "100%" }}
          variant="standard"
          fullWidth
          data-testid="recipientEmail"
          onChange={handleChange}
          error={form.recipientEmail?.hasError}
          helperText={
            form.recipientEmail?.hasError ? form.recipientEmail?.errorMsg : ""
          }
          value={form.recipientEmail.value}
        />
        <br />
        <br />
        <br />
        <TextField
          multiline
          label="Email Body"
          name="emailBody"
          id="emailBody"
          style={{ maxWidth: "100%" }}
          minRows={6}
          variant="standard"
          fullWidth
          data-testid="emailBody"
          onChange={handleChange}
          error={form.emailBody?.hasError}
          helperText={form.emailBody?.hasError ? form.emailBody?.errorMsg : ""}
          defaultValue={defaultBody}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSendEmail}>
          Send Email
        </Button>
      </DialogActions>
      <Snackbar
        open={showSnackMessage}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <SnackbarContent
          style={{ backgroundColor: "#4caf50" }}
          message={<span id="client-snackbar">{snackMessage}</span>}
        />
      </Snackbar>
    </Dialog>
  );
}
