import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import config from "../../../config";
import ApiClientManager from "../../../Components/ApiClientManager";
import mockOktaAuth from "../../utils/MockOktaAuth";
import "remixicon/fonts/remixicon.css";
import SideBar from "../../../Components/SideBar"
import MarketsNavigationBar from "../../../Components/NavigationBar/MarketsNavigationBar";
import Routes from "../Routes";

const oktaAuth =
  process.env.REACT_APP_ENV === "dev-no-auth"
    ? mockOktaAuth
    : new OktaAuth(config.oidc);

// const Layout: React.FC = ({ component: Component }) => {
//   return (
//     <Route path="/" exact element={RequiredAuth}>
//     <Route
//       render={(matchProps) => (
//         <>
//           <MarketsNavigationBar />
//           <div>
//             <SideBar />
//             <div style={{ marginLeft: "4em" }}>
//               <Component {...matchProps} />
//             </div>
//           </div>
//         </>
//       )}
//     />
//     </Route>
//   );
// };

const Main = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SideBar />
      <MarketsNavigationBar />
      <ApiClientManager>
      <Routes />
      </ApiClientManager>
    </Security>
  );
};

export default Main;
