import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core";
import usePortfolios from "../../shared/hooks/usePortfolios";

export default function Portfolios({
  onChange,
  defaultValue,
  styling,
  error = false,
}) {
  const [value, setValue] = useState(defaultValue ? defaultValue : -1);
  const portfolios = usePortfolios();

  return (
    <>
      {portfolios.isError && (
        <Typography>
          There was an issue fetching data please reload the browser or try
          again in a bit.
        </Typography>
      )}
      {portfolios.data && portfolios.data.length > 0 && (
        <FormControl variant={styling && "outlined"} error={error} fullWidth>
          {!styling && (
            <InputLabel id="portfolio-label">Portfolio *</InputLabel>
          )}
          <Select
            id="portfolios"
            labelId="portfolio-label"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChange && onChange(e.target.value);
            }}
            fullWidth
          >
            <MenuItem selected disabled value={-1}>
              <em>Choose a portfolio</em>
            </MenuItem>
            {portfolios.data.map((portfolio) => (
              <MenuItem key={portfolio.id} value={portfolio.id}>
                {portfolio.name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>Portfolio required</FormHelperText>}
        </FormControl>
      )}
    </>
  );
}
