import React from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { accountabilityLevelsMap } from "../../shared/constants";

function AccountabilityLevel({ onChange, value }) {
  const useStyles = makeStyles(() => ({
    selectMenu: {
      "&>div": {
        whiteSpace: "pre-wrap",
      },
    },
    menuItem: {
      minHeight: "48px",
    },
  }));
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <InputLabel variant="standard" id="accountability-label">
        Accountability Level
      </InputLabel>
      <Select
        id="accountability-level"
        value={value}
        variant="standard"
        labelId="accountability-label"
        onChange={(e) => onChange(e.target.value)}
        className={classes.selectMenu}
      >
        <MenuItem value={null} className={classes.menuItem}></MenuItem>
        <MenuItem value="CLIENT">{accountabilityLevelsMap.CLIENT}</MenuItem>
        <MenuItem value="TW_DELIVERY">
          {accountabilityLevelsMap.TW_DELIVERY}
        </MenuItem>
        <MenuItem value="TW_DELIVERY_AND_COMMERCIAL">
          {accountabilityLevelsMap.TW_DELIVERY_AND_COMMERCIAL}
        </MenuItem>
        <MenuItem value="OTHER">{accountabilityLevelsMap.OTHER}</MenuItem>
      </Select>
    </FormControl>
  );
}

export default AccountabilityLevel;
