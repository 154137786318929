import React from "react";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

import {
  Feedback,
  ExitToApp,
  Help,
  Code,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

const drawerWidth = "4em";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
  },
  drawerContainer: {
    overflow: "auto",
    paddingTop: "1em",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    borderRightColor: "hsl(185, 57%, 50%)",
    borderRight: "solid 3px",
  },
  drawFooter: {
    position: "fixed",
    bottom: "0",
    width: drawerWidth,
    paddingBottom: "2em",
  },
  miniBarIcon: {
    minWidth: "inherit",
  },
}));

export default function SideBar() {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();

  const drawer = (
    <div className={classes.drawerContainer}>
      <List component="nav">
        <div className={classes.drawFooter}>
          <Divider />
          <ListItem alt="Help" button>
            <HashLink to="/help#RAG">
              <ListItemIcon className={classes.miniBarIcon} title="Help">
                <Help />
                <Typography variant="srOnly">Help</Typography>
              </ListItemIcon>
            </HashLink>
          </ListItem>
          <ListItem alt="Contributing" button>
            <NavLink to="/contributing">
              <ListItemIcon
                className={classes.miniBarIcon}
                title="Contributing"
              >
                <Code />
                <Typography variant="srOnly">Contributing</Typography>
              </ListItemIcon>
            </NavLink>
          </ListItem>
          <ListItem
            alt="Feedback"
            button
            component="a"
            role="button"
            href="https://forms.gle/kniC7qkhWT68yqi37"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <ListItemIcon className={classes.miniBarIcon} title="Feedback">
              <Feedback />
              <Typography variant="srOnly">Feedback</Typography>
            </ListItemIcon>
          </ListItem>
          <ListItem
            button
            alt="Logout"
            title="Logout"
            onClick={() => oktaAuth.signOut()}
          >
            <ListItemIcon className={classes.miniBarIcon}>
              <ExitToApp />
              <Typography variant="srOnly">Logout</Typography>
            </ListItemIcon>
          </ListItem>
        </div>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        {drawer}
      </Drawer>
    </div>
  );
}
