import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, Typography } from "@mui/material";
import usePortfolios from "../../../../../shared/hooks/usePortfolios";
import PortfoliosButton from "./PortfoliosButton";
import { useRegionStorage } from "../../../../../shared/hooks/useRegionStorage";
import { PortfolioDeliveryReport } from "../../../../../shared/types/Interfaces";

interface RiskCriteriaFilterProps {
  setSelectedPortfolioId: (portfolioId: number) => unknown;
  portfolioDeliveryReport: PortfolioDeliveryReport[] | undefined;
}

export default function RiskCriteriaFilter({
  setSelectedPortfolioId,
  portfolioDeliveryReport
}: RiskCriteriaFilterProps) {
  const [region] = useRegionStorage();
  const { data: portfolios } = usePortfolios();
  const [selectedPortfolio, setSelectedPortfolio] = useState("All portfolios");

  const handleSelected = useCallback((portfolioName: string, id: number) => {
    setSelectedPortfolio(portfolioName);
    setSelectedPortfolioId(id);
  }, [setSelectedPortfolioId]);

  useEffect(() => {
    handleSelected("All portfolios", 0);
  }, [handleSelected, region]);

  return (
    <Grid container item xs={12} spacing="16px" marginTop={"10px"}>
      <Grid item xs={12} lg={12}>
        <Box>
          <Grid
            item
            xs={12}
            lg={12}
            key={"category.value"}
            sx={{
              backgroundColor: "#EEF1F3",
              borderRadius: "12px",
              padding: "24px",
            }}
          >
            <Typography
              marginBottom={"15px"}
              variant="h3"
              sx={{ color: "#003D4F" }}
            >
              Filter portfolio
            </Typography>
            <PortfoliosButton
              key={selectedPortfolio}
              portfolioName="All portfolios"
              selectedPortfolio={selectedPortfolio}
              onClickHandle={(portfolioName = "All portfolios") =>
                handleSelected(portfolioName, 0)
              }
            />
            {portfolios &&
              portfolios
                .filter(
                  (portfolio) => portfolio.name !== "TW Internal Projects"
                )
                .map((portfolio) => {
                  const portfolioReport = portfolioDeliveryReport?.find(
                    (report) => portfolio.id === report.id
                  );
                  return ( portfolioReport?.name &&
                    <React.Fragment key={portfolioReport.name}>
                      <PortfoliosButton
                        portfolioName={portfolioReport.name}
                        selectedPortfolio={selectedPortfolio}
                        onClickHandle={(portfolioName = "All portfolios") =>
                          handleSelected(portfolioName, portfolio.id)
                        }
                      />
                    </React.Fragment>
                  );
                })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
