import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@material-ui/core";
import OverallStatus from "./OverallStatus";
import StatusChange from "./StatusChange";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  height: {
    minHeight: "300px",
    boxShadow: "none",
    backgroundColor: "#EDF1F3",
  },
  remarkContainer: {
    boxShadow: "none",
    backgroundColor: "#EDF1F3",
    color: "white",
  },
  remark: {
    backgroundColor: "#003D4F",
    padding: "0.5em",
    textAlign: "center",
  },
  reportSummary: {
    backgroundColor: "#EDF1F3",
    marginTop: "1.2rem",
  },
});

export default function ReportSummary(props) {
  const classes = useStyles();

  const getStatus = (status) => {
    return status ? status : 0;
  };

  return (
    <Grid className={classes.reportSummary}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.height}>
            <OverallStatus
              ragFilter={props.ragFilter}
              setRagFilter={props.setRagFilter}
              overallStatus={props.overallStatus}
              getStatus={getStatus}
              region={props.region}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.height}>
            <StatusChange
              nonInternalActiveProjects={props.nonInternalActiveProjects}
              deliveredProjects={props.nonInternalDeliveredProjects}
              statusChange={props.overallStatus.change}
              getStatus={getStatus}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.remarkContainer}>
          <Grid className={classes.remark}>
            <Typography>Totals only include client projects</Typography>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

ReportSummary.propTypes = {
  ragFilter: PropTypes.string.isRequired,
  setRagFilter: PropTypes.func.isRequired,
  overallStatus: PropTypes.shape({
    change: PropTypes.shape({
      DETERIORATED: PropTypes.number,
      STATIONARY: PropTypes.number,
      IMPROVED: PropTypes.number,
      UNKNOWN: PropTypes.number,
    }),
    rag: PropTypes.shape({
      RED: PropTypes.number,
      AMBER: PropTypes.number,
      GREEN: PropTypes.number,
      UNKNOWN: PropTypes.number,
    }),
  }).isRequired,
  nonInternalActiveProjects: PropTypes.number.isRequired,
  nonInternalDeliveredProjects: PropTypes.number.isRequired,
};
