import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
const noPointer = { cursor: "default" };
const translateDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(date));
};

export default function LastUpdatedBy({ data }) {
  return data && (data.createdOn || data.updatedOn) ? (
    <>
      {data.updatedBy || data.createdBy ? (
        <Tooltip title={data.updatedBy || data.createdBy}>
          <IconButton style={noPointer}>
            <PersonIcon style={noPointer} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      <span>
        {data.updatedOn
         ? "Last updated on " + translateDate(data.updatedOn)
         : "Created on " + translateDate(data.createdOn)}
      </span>
    </>
  ) : (
    ""
  );
}
