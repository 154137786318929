import axios from "axios";
import config from "../config";

const BASE_URI = config.api.baseURL;
const UK_REGION_ID = 1;

const client = axios.create({
  baseURL: BASE_URI,
});

class ApiClient {
  constructor(oktaAuth) {
    this.accessToken = null;
    this.oktaAuth = oktaAuth;
    this.portfolioList = null;
    this.regions = null;
    this.riskMetatdata = null;

    oktaAuth.authStateManager.subscribe((authState) => {
      if (authState.accessToken) {
        this.accessToken = oktaAuth.getAccessToken();
      }
    });
  }

  createError(statusCode, action) {
    return new Error(
      (statusCode ? statusCode + ": " : "") + `Issue while ${action}`,
    );
  }

  createErrorFromResponse(errorMessage) {
    return new Error(`Failed as ${errorMessage}`);
  }

  async perform(method, resource, data, responseType = "json") {
    if (this.oktaAuth.getAccessToken() === undefined) {
      throw new Error("Unauthorized request");
    }
    if (!this.accessToken) {
      this.accessToken = this.oktaAuth.getAccessToken();
    }
    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
      responseType,
    });
  }

  // /v1/project/...

  async createProject(name, clientName, portfolio, regionId) {
    try {
      await this.perform("post", `/v2/region/${regionId}/project`, {
        name,
        clientName,
        portfolio,
      });
    } catch (error) {
      if (error.response?.data.message == null) {
        throw this.createError(error.response?.status, "creating a project");
      } else {
        throw this.createErrorFromResponse(error.response.data.message);
      }
    }
    return null;
  }

  async createProjectV2(name, clientName, portfolio, regionId, crmMarketReference) {
    try {
      await this.perform("post", `/v1/project`, {
        name,
        clientName,
        portfolio,
        regionId,
        crmMarketReference
      });
    } catch (error) {
      if (error.response?.data.message == null) {
        throw this.createError(error.response?.status, "creating a project");
      } else {
        throw this.createErrorFromResponse(error.response.data.message);
      }
    }
    return null;
  }

  async updateProject(id, projectData) {
    try {
      await this.perform("put", `/v1/project/${id}`, projectData);
    } catch (error) {
      if (error.response?.data.message == null) {
        throw this.createError(error.response?.status, "updating a project");
      } else {
        throw this.createErrorFromResponse(error.response.data.message);
      }
    }
    return null;
  }

  async updateTechStack(id, techStack) {
    try {
      await this.perform("put", `/v1/project/${id}/tech-stack`, {
        technologies: techStack,
      });
    } catch (error) {
      throw this.createError(error.response?.status, "updating a tech stack");
    }
    return null;
  }

  async updateMetrics(id, metrics) {
    try {
      await this.perform("put", `/v1/project/${id}/metrics`, metrics);
    } catch (error) {
      throw this.createError(error.response?.status, "updating metrics");
    }
  }

  async updatePeopleOfInterest(id, people) {
    try {
      await this.perform("put", `/v1/project/${id}/people-of-interest`, {
        people: people,
      });
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "updating people of interest",
      );
    }
  }

  async createEvent(id, event) {
    try {
      const result = await this.perform(
        "post",
        `/v1/project/${id}/event`,
        event,
      );
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "creating an event");
    }
  }

  async deleteEvent(id, eventId) {
    try {
      await this.perform("delete", `/v1/project/${id}/event/${eventId}`);
    } catch (error) {
      throw this.createError(error.response?.status, "deleting an event");
    }
    return null;
  }

  async createArtefact(id, artefact) {
    try {
      const result = await this.perform(
        "post",
        `/v1/project/${id}/artefact`,
        artefact,
      );
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "creating an artefact");
    }
  }

  async deleteArtefact(id, artefactId) {
    try {
      await this.perform("delete", `/v1/project/${id}/artefact/${artefactId}`);
    } catch (error) {
      throw this.createError(error.response?.status, "deleting an artefact");
    }
    return null;
  }

  async getRiskMetadata() {
    if (this.riskMetatdata) {
      return this.riskMetatdata;
    }
    try {
      const result = await this.perform("get", `/v1/risk/metadata`);
      this.riskMetatdata = result.data;
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving risk metadata",
      );
    }
  }

  async createRisk(id, risk) {
    try {
      const result = await this.perform("post", `/v1/project/${id}/risk`, risk);
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "creating a risk");
    }
  }

  async updateRisk(id, risk) {
    try {
      const result = await this.perform(
        "put",
        `/v1/project/${id}/risk/${risk.id}`,
        risk,
      );
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "updating a risk");
    }
  }

  async deleteRisk(id, riskId) {
    try {
      await this.perform("delete", `/v1/project/${id}/risk/${riskId}`);
    } catch (error) {
      throw this.createError(error.response?.status, "deleting a risk");
    }
    return null;
  }

  async getAllProjectsForSelectedRegion() {
    try {
      let regionId = JSON.parse(localStorage.getItem("region"))?.id || 1;
      const result = await this.perform(
        "get",
        `/v2/region/${regionId}/projects`,
      );
      return result.data.projects;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving projects");
    }
  }

  async getAllProjectsForSelectedMarket() {
    try {
      let crmMarketReference = JSON.parse(localStorage.getItem("market"))?.crmMarketReference || null;
      const result = await this.perform(
          "get",
          `/v1/market/${crmMarketReference}/projects`,
      );
      return result.data.projects;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving projects");
    }
  }

  async getProject(id) {
    try {
      const result = await this.perform("get", `/v1/project/${id}`);
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving a project");
    }
  }

  async getMarkets(inUseOnly) {
    try {
      const response = await this.perform("get", "/v1/markets?inUseOnly=" + inUseOnly);
      this.markets = response?.data;
      return response?.data;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving markets");
    }
  }

  async updateDeliveryReporter(id, deliveryReporters) {
    try {
      const result = await this.perform(
        "put",
        `/v1/project/${id}/delivery_reporter`,
        deliveryReporters,
      );
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "updating delivery reporter",
      );
    }
  }

  async deleteDeliveryReporter(id) {
    try {
      await this.perform("delete", `/v1/project/${id}/delivery_reporter`);
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "deleting a delivery reporter",
      );
    }
  }

  // /v1/region/...
  async getRegions() {
    if (this.regions) {
      return this.regions;
    }
    try {
      const result = await this.perform("get", `/v1/region`);
      this.regions = result.data;
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving regions");
    }
  }

  // /v2 'delivery-report'
  async getStatus(projectId, weekEnd) {
    try {
      const result = await this.perform(
        "get",
        `/v2/project/${projectId}/delivery-report/${weekEnd}`,
      );
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving a status report",
      );
    }
  }

  async updateStatus(projectId, weekEnd, body) {
    try {
      const result = await this.perform(
        "put",
        `/v2/project/${projectId}/delivery-report/${weekEnd}`,
        body,
      );
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "updating a status");
    }
  }

  async getAvailableDeliveryReportDates() {
    try {
      let regionId =
        JSON.parse(localStorage.getItem("region"))?.id || UK_REGION_ID;
      var result = await this.perform(
        "get",
        `/v2/region/${regionId}/reporting-dates`,
      );
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving available delivery reports dates",
      );
    }
  }

  async getAvailableMarketDeliveryReportDates() {
    try {
      var result = await this.perform("get", `/v1/market/reporting-dates`);
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving available delivery reports dates",
      );
    }
  }

  async getDeliveryTrendsReports() {
    try {
      let regionId =
        JSON.parse(localStorage.getItem("region"))?.id || UK_REGION_ID;
      var result = await this.perform(
        "get",
        `/v2/region/${regionId}/delivery-trends`,
      );
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving available delivery trends reports",
      );
    }
  }

  async getReportsAndTotals(weekEnding) {
    try {
      let regionId =
        JSON.parse(localStorage.getItem("region"))?.id || UK_REGION_ID;
      const result = await this.perform(
        "get",
        `/v2/region/${regionId}/delivery-report/${weekEnding}/`,
      );
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving a delivery report",
      );
    }
  }

  async getMarketReportsAndTotals(weekEnding) {
    try {
      let market = JSON.parse(localStorage.getItem("market"));
      if (!market || !market.crmMarketReference) {
        throw Error(
          "Issue while retrieving market delivery report - market not selected",
        );
      }

      let crmMarketReference = market.crmMarketReference;
      const result = await this.perform(
        "get",
        `/v1/market/${crmMarketReference}/delivery-report/${weekEnding}/`,
      );
      return result.data;
    } catch (error) {
      if (
        error.message ===
        "Issue while retrieving market delivery report - market not selected"
      ) {
        throw error;
      } else {
        throw this.createError(
          error.response?.status,
          "retrieving market delivery report",
        );
      }
    }
  }

  async getPendingReportEmails() {
    try {
      let regionId =
        JSON.parse(localStorage.getItem("region"))?.id || UK_REGION_ID;
      const result = await this.perform(
        "get",
        `/v1/region/${regionId}/delivery-reporters/`,
      );
      return result.data;
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "retrieving a delivery reporters",
      );
    }
  }

  // /v2/portfolio/...
  clearPortfoliosCache() {
    this.portfolioList = null;
  }

  async getPortfolios() {
    try {
      let regionId = JSON.parse(localStorage.getItem("region"))?.id || 1;
      const response = await this.perform(
        "get",
        `/v2/region/${regionId}/portfolios`,
      );
      return response?.data;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving portfolios");
    }
  }

  // other
  async isDeliveryDirector() {
    try {
      const result = await this.perform("get", "/v1/delivery-directors");
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "getting user role");
    }
  }

  async sendDeliveryReportReminder(recipients, body) {
    try {
      await this.perform("post", "/v1/delivery-report-reminder", {
        recipients,
        body,
      });
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "sending a delivery report reminder",
      );
    }
    return null;
  }

  async sendDeliveryReportCycleOpenReminder(regionShortName) {
    try {
      await this.perform("post", "/v1/delivery-report-reminder/cycle-open", {
        regionShortName,
      });
    } catch (error) {
      throw this.createError(
        error.response?.status,
        "sending a delivery report cycle open reminder",
      );
    }
    return null;
  }

  downloadAllDeliveryReport() {
    try {
      let regionId =
        JSON.parse(localStorage.getItem("region"))?.id || UK_REGION_ID;
      this.perform(
        "get",
        `/v2/region/${regionId}/delivery-report/export`,
        undefined,
        "blob",
      ).then((response) => {
        const csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "Deliver Reports.csv");
        tempLink.click();
      });
    } catch (error) {
      throw this.createError(
        error?.response?.status,
        "downloading delivery reports",
      );
    }
  }

  downloadRegionRisksReport() {
    try {
      let regionId =
        JSON.parse(localStorage.getItem("region"))?.id || UK_REGION_ID;
      this.perform(
        "get",
        `/v1/region/${regionId}/risk/export`,
        undefined,
        "blob",
      ).then((response) => {
        const csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "Risks Report.csv");
        tempLink.click();
      });
    } catch (error) {
      throw this.createError(
        error?.response?.status,
        "downloading risks report",
      );
    }
  }


  async getUsers() {
    try {
      const result = await this.perform("get", `/v1/user/audit`);
      return result.data;
    } catch (error) {
      throw this.createError(error.response?.status, "retrieving users");
    }
  }

}

export default ApiClient;
