interface Category {
  name: string;
  value: string;
  compulsory?: boolean;
  subcategories?: { name: string, status: string }[];
}

interface Region {
  categories: Category[];
}

const UK: Region = {
  categories: [
    { name: "Overall Status", value: "overall" },
    { name: "Plan", value: "plan" },
    { name: "Team Mix", value: "team_mix" },
    { name: "Team Morale", value: "team_morale" },
    { name: "Dependencies", value: "dependencies" },
    { name: "Technology", value: "technology" },
    { name: "Commercial", value: "commercial" },
    { name: "Legal", value: "legal" },
    { name: "Continuous Delivery", value: "continuous_delivery" },
    { name: "InfoSec", value: "info_sec" },
  ],
};

const SEAANZ: Region = {
  categories: [
    { name: "Overall Status", value: "overall" },
    { name: "Plan", value: "plan" },
    { name: "Risk Exposure", value: "risk_exposure" },
    { name: "Technology", value: "technology" },
    { name: "Commercials & Legal", value: "commercials_and_legal" },
    { name: "Customer Relationship", value: "customer_relationship" },
    { name: "Team Morale", value: "team_morale" },
    { name: "Team Mix", value: "team_mix" },
    { name: "InfoSec Tier One", value: "info_sec_tier_one", compulsory: true },
    { name: "InfoSec Tier Two", value: "info_sec_tier_two" },
  ],
};

const NL: Region = {
  categories: [
    { name: "Overall Status", value: "overall" },
    { name: "Plan", value: "plan" },
    { name: "Staffing", value: "staffing" },
    { name: "Team Morale", value: "team_morale" },
    { name: "Customer Relations", value: "customer_relations" },
    { name: "Commercials & Legal", value: "commercials_and_legal" },
    { name: "Technology", value: "technology" },
    { name: "InfoSec", value: "info_sec" },
    { name: "Future Business", value: "future_business" },
  ],
};

const DE: Region = {
  categories: [
    { name: "Delivery Confidence", value: "overall" },
    { name: "Risk Exposure", value: "risk_exposure" },
    { name: "Plan", value: "plan" },
    { name: "Staffing", value: "staffing" },
    { name: "Team Morale", value: "team_morale" },
    { name: "Customer Relations", value: "customer_relations" },
    { name: "Commercials", value: "commercials" },
    { name: "Legal", value: "legal" },
    { name: "Future Business", value: "future_business" },
    { name: "InfoSec", value: "info_sec" },
    { name: "Business Value", value: "business_value" },
  ],
};

const BR: Region = {
  categories: [
    { name: "Overall", value: "overall"},
    { name: "Ways of working", value: "ways_of_working",
      subcategories: [
        { name: "Continuous Improvement", status: "unknown"},
        { name: "Dailies - Progress & Blockers", status: "unknown" },
        { name: "Showcase/Demos driving outcomes", status: "unknown" },
        { name: "DoD & DoR", status: "unknown" },
        { name: "Pair Programming", status: "unknown" },
        { name: "Card Kickoffs - BAs, QA and Devs", status: "unknown" },
      ]},
    { name: "Delivery", value: "delivery",
      subcategories: [
        { name: "Rotation/Succession Plan", status: "unknown" },
        { name: "Delivery Planning (roadmap, releases, scope)", status: "unknown" },
        { name: "Leave Planner & Capacity Plan", status: "unknown" },
        { name: "Supply & Staffing", status: "unknown" },
        { name: "Tracking Delivery: Project/Release Burn-up Chart", status: "unknown" },
        { name: "Decision Log & Risk Management", status: "unknown" },
      ]},
    { name: "Technology", value: "technology",
      subcategories: [
        { name: "Post-mortem/Root Cause Analysis", status: "unknown" },
        { name: "Dailies - Progress & Blockers", status: "unknown" },
        { name: "Tech-Debt & Path to Prod mapped", status: "unknown" },
        { name: "Build/Pipeline is in place - Quick feedback", status: "unknown" },
        { name: "High-level architectural and tech vision known by the entire team", status: "unknown" },
      ]},
    { name: "Governance", value: "governance",
      subcategories: [
        { name: "Stakeholders Map/Management", status: "unknown" },
        { name: "Account Governance meeting & visibility", status: "unknown" },
        { name: "Stewardship/QBRs", status: "unknown" },
      ]},
  ],
};

const FIN: Region = {
  categories: [
    { name: "Overall Status", value: "overall" },
    { name: "Plan", value: "plan" },
    { name: "Staffing", value: "staffing" },
    { name: "Team Morale", value: "team_morale" },
    { name: "Customer Relations", value: "customer_relations" },
    { name: "Commercials & Legal", value: "commercials_and_legal" },
    { name: "Technology", value: "technology" },
    { name: "InfoSec", value: "info_sec" },
    { name: "Future Business", value: "future_business" },
  ],
};

const CHNROW: Region = {
  categories: [
    { name: "Overall Status", value: "overall"},
    { name: "Commercials & Legal", value: "commercials_and_legal",
      subcategories: [
        { name: "Contract are signed", status: "unknown" },
        { name: "Actual margin matches planned margin (during DR phase)", status: "unknown" },
        { name: "Invoices are sent to client on time", status: "unknown" },
        { name: "Invoices are paid on time", status: "unknown" },
        { name: "Revenue leakage", status: "unknown" },
        { name: "SLA breach (DAMO)", status: "unknown" },
      ]},
      { name: "InfoSec", value: "info_sec",
      subcategories: [
        { name: "InfoSec Tier One - Onboarding/IRP", status: "unknown" },
        { name: "InfoSec Tier Two - Risk Assessment/Project Security Risks", status: "unknown" },
      ]},
      { name: "Ways of Working", value: "ways_of_working",
      subcategories: [
        { name: "Backlog Grooming", status: "unknown" },
        { name: "Sprint/Iteration Planning", status: "unknown" },
        { name: "DoD & DoR", status: "unknown" },
        { name: "Daily Standup - Progress & Blockers", status: "unknown" },
        { name: "Card Kickoffs & Desk-checks", status: "unknown" },
        { name: "Showcase/Demos driving outcomes", status: "unknown" },
        { name: "Retrospectives", status: "unknown" },
      ]},
      { name: "Customer Relations", value: "customer_relations",
      subcategories: [
        { name: "C-level executive connection", status: "unknown" },
        { name: "Mid-senior level stakeholder satisfaction (NPS)", status: "unknown" },
        { name: "Working level stakeholder/Point of contact satisfaction (NPS)", status: "unknown" },
        { name: "Value articulation/ROI quantification", status: "unknown" },
      ]},
      { name: "Delivery", value: "delivery",
      subcategories: [
        { name: "Project Canvas (vision, goals, MoS, key milestones, budget...)", status: "unknown" },
        { name: "Delivery Roadmap", status: "unknown" },
        { name: "Release Plan", status: "unknown" },
        { name: "Tracking Delivery: Project/Release Burn-up Chart", status: "unknown" },
        { name: "Tracking Cost: Budget tracker or man-day burn-up Chart", status: "unknown" },
        { name: "Tracking Quality: Quality Metrics", status: "unknown" },
        { name: "RAIDs & Decision Log", status: "unknown" },
        { name: "Quarterly Impact Map (value delivered)", status: "unknown" },
        { name: "CAB Mechanism and Change Management practices", status: "unknown" },
      ]},
      { name: "Governance", value: "governance",
      subcategories: [
        { name: "Account CLT governance model", status: "unknown" },
        { name: "Account Strategy/TW Why", status: "unknown" },
        { name: "Stakeholders Map/Management", status: "unknown" },
        { name: "Account Governance meeting/QBR", status: "unknown" },
      ]},
      { name: "Team", value: "team",
      subcategories: [
        { name: "Supply & Staffing", status: "unknown" },
        { name: "Team Mix", status: "unknown" },
        { name: "Team Morale", status: "unknown" },
        { name: "Rotation/Succession Plan", status: "unknown" },
      ]},
  ],
};

export const regions: Record<string, Region> = { SEAANZ, UK, NL, DE, BR ,FIN, CHNROW };
