import { useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import WeekSelectionHeader from "./components/WeekSelectionHeader";
import WeekSnapshot from "./components/WeekSnapshot";
import RetrievalError from "../../Components/RetrievalError";
import useAvailableDeliveryReportDates from "../../shared/hooks/useAvailableDeliveryReportDates";
import usePortfolios from "../../shared/hooks/usePortfolios";
import SixMonthTrends from "./components/SixMonthTrends/SixMonthTrends";

export default function TrendsPage() {
  const [weekEnding, setWeekEnding] = useState("");
  const availableDeliveryReportDates = useAvailableDeliveryReportDates();
  const portfolios = usePortfolios();

  if (
    availableDeliveryReportDates.isLoading ||
    portfolios.isLoading ||
    availableDeliveryReportDates.isFetching
  ) {
    return (
      <Grid item xs={12}>
        <Box mt={20}>
          <Typography variant="h4" align="center" sx={{ color: grey[500] }}>
            <CircularProgress />
            <Box m={1} />
            Loading
          </Typography>
        </Box>
      </Grid>
    );
  }

  if (availableDeliveryReportDates.isError || availableDeliveryReportDates?.data === undefined || portfolios.isError) {
    return (
      <RetrievalError
        message="Could not fetch trends"
        path="/trends"
        actionText="Try again"
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#EDF1F3",
          padding: "2rem",
          marginTop: "70px"
        }}
        width="100%"
      >
        <Grid container rowSpacing="48px">
          <Grid item xs={12}>
            <WeekSelectionHeader
              weekEnding={weekEnding}
              setWeekEnding={setWeekEnding}
            />
          </Grid>
          <Grid item xs={12}>
            <WeekSnapshot weekEnding={weekEnding} />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: "2rem",
          marginTop: "10px"
        }}
        width="100%"
      >
        <Grid container>
          <SixMonthTrends weekEnding={availableDeliveryReportDates.data?.[0] ?? ""} />
        </Grid>
      </Box>
    </>
  );
}
