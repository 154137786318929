import React, { useContext, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@material-ui/core";
import { Add, Cancel } from "@material-ui/icons";
import Portfolios from "../Portfolios";
import { ApiClientContext } from "../../ApiClient/context";
import Regions from "../Regions";
import Markets from "../Markets";
import DashboardFilter from "../../shared/constants/DashboardFilter";
import config from "../../config";

export default function CreateProject(props) {
  const apiClient = useContext(ApiClientContext);

  const [projectName, setProjectName] = useState("");
  const [portfolioId, setPortfolioId] = useState(null);
  const [clientName, setClientName] = useState("");
  const [hasError, setHasError] = useState({ name: false, clientName: false });
  const storageFilter = localStorage.getItem("filter");
  const selectedFilter = storageFilter
        ? JSON.parse(storageFilter)
        : DashboardFilter.Region;
  const selectedRegionId = JSON.parse(localStorage.getItem("region"))?.id || null;
  const [regionId, setRegionId] = useState((selectedFilter === DashboardFilter.Region ? selectedRegionId : null));
  const selectedCrmMarketReference = JSON.parse(localStorage.getItem("market"))?.crmMarketReference || null;
  const [crmMarketReference, setCrmMarketReference] = useState((selectedFilter === DashboardFilter.Market ? selectedCrmMarketReference : null));

  const handleSubmit = async (e) => {
      e.preventDefault();

      const sanitisedName = projectName.trim();
      const sanitisedClientName = clientName.trim();

      if (sanitisedName === "" || sanitisedClientName === "" || !portfolioId || !regionId
          || (config.featureToggles.enableMarketDashboard && config.featureToggles.enableMarketProjects
              && !crmMarketReference)) {
          setHasError({
              name: sanitisedName === "",
              clientName: sanitisedClientName === "",
              portfolioId: !portfolioId,
              regionId: !regionId,
              crmMarketReference: !crmMarketReference
          });

          return;
      }

      setHasError({name: false, clientName: false});

      if (config.featureToggles.enableMarketDashboard &&
          config.featureToggles.enableMarketProjects) {
          apiClient
              .createProjectV2(sanitisedName, sanitisedClientName, portfolioId, regionId, crmMarketReference)
              .then((response) => {
                  setProjectName("");
                  props.onApiResponse({
                      text: "Project successfully created",
                      type: "success",
                  });
                  props.onSuccess();
                  props.onDialogClose();
              })
              .catch((error) => {
                  props.onApiResponse({text: error.message, type: "error"});
                  props.onDialogClose();
              });
      } else {
          apiClient
              .createProject(sanitisedName, sanitisedClientName, portfolioId, regionId)
              .then((response) => {
                  setProjectName("");
                  props.onApiResponse({
                      text: "Project successfully created",
                      type: "success",
                  });
                  props.onSuccess();
                  props.onDialogClose();
              })
              .catch((error) => {
                  props.onApiResponse({text: error.message, type: "error"});
                  props.onDialogClose();
              });
      }
  };

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <DialogTitle id="create-dialog-title">Create Project</DialogTitle>
      <DialogContent>
        <TextField
          id="project-name"
          label="Project Name *"
          fullWidth
          value={projectName}
          color="primary"
          onChange={(e) => {
            setProjectName(e.target.value);
            setHasError({ ...hasError, name: false });
          }}
          error={hasError.name}
          helperText={hasError.name && "Project name required"}
        />
        <TextField
          id="client-name"
          label="Client Name *"
          fullWidth
          value={clientName}
          color="primary"
          onChange={(e) => {
            setClientName(e.target.value);
            setHasError({ ...hasError, clientName: false });
          }}
          error={hasError.clientName}
          helperText={hasError.clientName && "Client name required"}
        />
        <Box marginTop={4}>
          <Portfolios
            onChange={(value) => {
              setPortfolioId(value);
              setHasError({ ...hasError, portfolioId: false });
            }}
            error={hasError.portfolioId}
          />
        </Box>
        {(config.featureToggles.enableMarketDashboard &&
            config.featureToggles.enableMarketProjects) && (
        <>
        {selectedFilter === DashboardFilter.Market &&
          <Box marginTop={4}>
            <Regions
                onChange={(value) => {
                    setRegionId(value);
                    setHasError({ ...hasError, regionId: false });
                }}
                error={hasError.regionId}
                defaultValue={selectedFilter === DashboardFilter.Region ? selectedRegionId : null}
            />
          </Box>
        }
        {selectedFilter === DashboardFilter.Region &&
          <Box marginTop={4}>
            <Markets
                onChange={(value) => {
                    setCrmMarketReference(value);
                    setHasError({ ...hasError, crmMarketReference: false });
                }}
                error={hasError.crmMarketReference}
                defaultValue={selectedFilter === DashboardFilter.Market ? crmMarketReference : null}
            />
          </Box>
        }
        </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onDialogClose}
          color="primary"
          startIcon={<Cancel />}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" startIcon={<Add />}>
          Create
        </Button>
      </DialogActions>
    </form>
  );
}
