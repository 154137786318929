import React, { useMemo, useState } from "react";
import {
  Container,
  Grid,
  InputAdornment,
  Tabs,
  Tab,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import Project from "../Project";

const ALL_PROJECTS = 0;
const ACTIVE_PROJECTS = 1;
const INACTIVE_PROJECTS = 2;

function allProjects() {
  return () => true;
}

function activeProjectsAt(timestamp) {
  return (project) =>
    !project.projectEndDate ||
    new Date(project.projectEndDate).setHours(23, 59, 59, 999) >= timestamp;
}

function inactiveProjectsAt(timestamp) {
  return (project) =>
    Boolean(project.projectEndDate) &&
    new Date(project.projectEndDate).setHours(23, 59, 59, 999) < timestamp;
}

function projectNameContains(filterValue) {
  const substring = filterValue.toLowerCase();
  return ({ name: projectName }) => {
    const normalisedProjectName = projectName.toLowerCase();

    return normalisedProjectName.includes(substring);
  };
}

function clientNameContains(filterValue) {
  const substring = filterValue.toLowerCase();
  return ({ clientName: projectClientName }) => {
    const normalisedProjectName = projectClientName.toLowerCase();

    return normalisedProjectName.includes(substring);
  };
}

const projectStatusFiltersByTabIndex = {
  [ALL_PROJECTS]: allProjects,
  [ACTIVE_PROJECTS]: activeProjectsAt,
  [INACTIVE_PROJECTS]: inactiveProjectsAt,
};

const useStyles = makeStyles(() => ({
  filterCard: {
    padding: "15px",
  },
  filterComponent: {
    marginTop: "-65px",
  },
}));

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);

export default function Filter({ projects, projectId }) {
  const [filterValue, setFilterValue] = useState("");
  const [tabIndex, setTabIndex] = useState(1);
  const classes = useStyles();

  const filteredProjects = useMemo(() => {
    const now = Date.now();
    const byStatus = projectStatusFiltersByTabIndex[tabIndex](now);
    const byName = projectNameContains(filterValue);
    const byClientName = clientNameContains(filterValue);
    return projects?.filter(
      (project) =>
        (projectId && project.id === projectId) ||
        ((byName(project) && byStatus(project)) ||
         (byClientName(project) && byStatus(project)))
    );
  }, [tabIndex, filterValue, projects, projectId]);

  const getNoResultsMessage = () => {
    let noResultsMessage;
    if (filterValue) {
      noResultsMessage = `No results found for '${filterValue}'`;
    } else {
      noResultsMessage = `There are currently no ${getProjectFilterType()}`;
    }
    return <Typography variant="h5">{noResultsMessage}</Typography>;
  };

  const getProjectFilterType = () => {
    switch (tabIndex) {
      case ALL_PROJECTS:
        return "projects";
      case ACTIVE_PROJECTS:
        return "active projects";
      case INACTIVE_PROJECTS:
        return "inactive projects";
      default:
        return null;
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        className={classes.filterComponent}
      >
        <Container maxWidth="lg" align="center">
          <Paper
            className={classes.filterCard}
            style={{ backgroundColor: "white" }}
          >
            <Grid item>
              <Tabs
                indicatorColor={"secondary"}
                value={tabIndex}
                onChange={(_event, newIndex) => setTabIndex(newIndex)}
                centered
                className={classes.tabs}
              >
                <CustomTab label="All Projects" />
                <CustomTab label="Active Projects" />
                <CustomTab label="Inactive Projects" />
              </Tabs>
            </Grid>
            <Grid item>
              <Container maxWidth="lg">
                <TextField
                  id="search-by-project-name"
                  variant="standard"
                  style={{ marginBottom: "10px" }}
                  label="Search by client name or project name"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Container>
            </Grid>
          </Paper>
        </Container>
        <Container maxWidth="lg">
          <Grid container item spacing={3} style={{ marginTop: "40px" }}>
            {filteredProjects?.length > 0 ? (
              filteredProjects.map((project) => {
                const open = (projectId && project.id === projectId) || false;
                return (
                  <Grid item xs={12} key={project.id}>
                    {project && <Project open={open} data={project} />}
                  </Grid>
                );
              })
            ) : (
              <Grid item>{getNoResultsMessage()}</Grid>
            )}
          </Grid>
        </Container>
      </Grid>
    </>
  );
}
