import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider as ThemeProviderV4 } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material";
import Box from "@material-ui/core/Box";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Main from "./shared/components/Main";
import { themeV4, themeV5 } from "./shared/components/StyleTheme";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/700-italic.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/800-italic.css";
import "./index.css";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <div>
    <ThemeProviderV4 theme={themeV4}>
      <ThemeProvider theme={themeV5}>
        <QueryClientProvider client={client}>
          <Box my={4}>
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </Box>
        </QueryClientProvider>
      </ThemeProvider>
    </ThemeProviderV4>
  </div>
);
