import LikelihoodTable from "./LikelihoodTable";
import RAGTable from "./RAGTable";
import RiskRatingTable from "./RiskRatingTable";

export default function HelpPage() {
  return (
    <>
      <RAGTable />
      <RiskRatingTable />
      <LikelihoodTable />
    </>
  );
}
