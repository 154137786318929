import React from "react";
import { DialogTitle, Typography, Box, Grid } from "@material-ui/core";

import LockedAlert from "./LockedAlert";
import ReportSubtitle from "./ReportSubtitle";

export default function NotesDialogTitle(props) {
  const { report, alert } = props;
  return (
    <DialogTitle disableTypography>
      {alert && <LockedAlert />}
      <Typography variant="h6">Report</Typography>
      <Typography variant="subtitle2">
        Period ending{" "}
        {report &&
          report?.weekEnding &&
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }).format(new Date(report.weekEnding))}
      </Typography>

      <Box mt={2}>
        <Grid container spacing={1}>
          <ReportSubtitle
            label={"Last edited by"}
            subtitle={report && report?.author ? report?.author : ""}
          />
          <ReportSubtitle
            label={"Project name"}
            subtitle={report && report?.projectName ? report?.projectName : ""}
          />
          <ReportSubtitle
            label={"Report storage location"}
            subtitle={report && report?.source ? report?.source : ""}
          />
          <ReportSubtitle
            label={"Client name"}
            subtitle={report && report?.clientName ? report?.clientName : ""}
          />
        </Grid>
      </Box>
    </DialogTitle>
  );
}
