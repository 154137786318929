import React from "react";
import { Grid, Typography } from "@material-ui/core";
import EditMetric from "./edit-metric";

export default function EditMetrics({ onChange, children }) {
  return (
    <>
      {children &&
        children.map((metric, index) => {
          return (
            <React.Fragment key={metric.name}>
              <Grid item xs={index === 0 ? 12 : 4}>
                <Typography variant="h6">{metric.title}</Typography>
                <EditMetric
                  title={metric.title}
                  name={metric.name}
                  notes={metric.notes}
                  status={metric.status}
                  subcategories={metric.subcategories}
                  onChange={onChange}
                />
              </Grid>
            </React.Fragment>
          );
        })}
    </>
  );
}
