import React from "react";
import {
  Paper,
  Snackbar,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

export default function DisplayMessage({message, onClose, duration}) {
    let defaultDuration = duration ? duration : 1000;
    return (
        <Paper elevation={0}>
            <Snackbar
                open={(Boolean(message.text))}
                autoHideDuration={(message.type && message.type === "success") ? defaultDuration : null }
                onClose={onClose && onClose}
            >
                { message.text ?
                  <Alert severity={message.type} variant="filled">
                      {message.text}
                  </Alert> : <div/>
                }
            </Snackbar>
        </Paper>
    );
}
