import { useContext } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { useQuery } from "@tanstack/react-query";
import { Portfolio } from "../types/Interfaces";

interface Portfolios {
  portfolios: Portfolio[];
}

// TODO: add unit tests to complete refactor of portfolios api call
const usePortfolios = () => {
  const apiClient = useContext(ApiClientContext);

  return useQuery<Portfolio[]>({
    queryKey: ["portfolios"],
    queryFn: async () => {
      const data: Portfolios = await apiClient.getPortfolios();
      const sortedPortfolios = data.portfolios?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return [
        ...(sortedPortfolios?.filter(
          (portfolio) => !portfolio.name.includes("TW Internal Projects")
        ) ?? []),
        ...(sortedPortfolios?.filter((portfolio) =>
          portfolio.name.includes("TW Internal Projects")
        ) ?? []),
      ];
    },
  });
};

export default usePortfolios;
