import config from "../../config";

const authState = {
  accessToken: {
    accessToken: "dev",
    tokenType: "dev",
    userinfoUrl: "url",
  },
  idToken: {
    idToken: "dev",
    // claims: UserClaims;
    issuer: "issuer",
    clientId: "clientId",
  },
  refreshToken: {
    refreshToken: "devRefresh",
    tokenUrl: "tokenUrl",
    issuer: "issuer",
  },
  isAuthenticated: true,
  isPending: false,
};

const mockOktaAuth = {
  options: config.oidc,
  authStateManager: {
    getAuthState: () => authState,
    subscribe: (handler) => handler && handler(authState),
    unsubscribe: () => ({}),
  },
  signOut: () => (window.location = "/"),
  isLoginRedirect: () => true,
  signInWithRedirect: () => (window.location = "/delivery-report"),
  getUser: async () => ({}),
  getAccessToken: () => authState.accessToken.accessToken,
  handleLoginRedirect: () => {
    window.location = "/delivery-report";
  },
  start: () => {}
};
export default mockOktaAuth;
