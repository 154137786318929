import { useQuery } from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";

const useLoggedInUser = () => {
  const { oktaAuth } = useOktaAuth();

  return useQuery({
    queryKey: ["loggedInUser"],
    queryFn: () => oktaAuth.getUser(),
  });
};

export default useLoggedInUser;
