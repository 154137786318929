import React from "react";
import RegionSelector from "../../shared/components/RegionSelector";
import {
  Button,
  Typography,
  Container,
  Box,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import logo from "../../Images/ProjectorLogo.svg";
import "@fontsource/inter";
import RegionAndMarketSelector from "../../shared/components/RegionAndMarketSelector";
import config from "../../config";

const styles = makeStyles(() => ({
  root: {
    backgroundColor: "#EDF1F3",
    textAlign: "center",
    padding: "1rem 1.2rem",
    margin: "0 auto",
    maxWidth: "800px",
    boxShadow: "none",
  },
  container: {
    backgroundColor: "red",
  },
  textHeader: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  emphasisedText: {
    color: "#F2617A",
  },

  bodyText: {
    lineHeight: "2rem",
    marginBottom: "2rem",
    fontFamily: "Inter",
  },
  contactDetail: {
    lineHeight: "2rem",
    fontFamily: "Inter",
  },
  reminderText: {
    backgroundColor: "#003D4F",
    color: "#EDF1F3",
    padding: "0.5rem 1rem",
    maxWidth: "450px",
    margin: "0 auto 2rem",
    fontFamily: "Inter",
  },
  agreeButton: {
    backgroundColor: "#EDF1F3",
    fontFamily: "Inter",
    color: "#F2617A",
    fontWeight: 700,
    marginTop: "1rem",
    "&:hover": {
      background: "white",
    },
  },
  displayText: {
    lineHeight: "2rem",
    fontFamily: "Inter",
    display: "flex",
  },
}));

export default function HomePage() {
  const { oktaAuth } = useOktaAuth();
  const styleClasses = styles();

  const login = async () => {
    await oktaAuth.signInWithRedirect({ originalUri: "/delivery-report" });
  };

  return (
    <Container maxWidth="md">
      <Paper classes={{ root: styleClasses.root }}>
        <Box padding={3}>
          <Typography variant="h5" classes={{ root: styleClasses.textHeader }}>
            Welcome to Projector
          </Typography>

          <img
            src={logo}
            style={{
              width: "240px",
              paddingTop: "34px",
              paddingBottom: "42px",
            }}
            alt="Projector Logo"
          />

          <Typography
            variant="h5"
            gutterBottom
            classes={{
              root: styleClasses.textHeader,
              h5: styleClasses.emphasisedText,
            }}
          >
            {oktaAuth.isAuthenticated
              ? "*Please read before continuing"
              : "Please login before continuing"}
          </Typography>

          {oktaAuth.isAuthenticated && (
            <Typography
              variant="body1"
              classes={{ root: styleClasses.bodyText }}
              gutterBottom
            >
              In Thoughtworks, we value transparency and feedback and so we've
              worked to increase the distribution of project metadata. We
              distribute this openly to everyone in our business. Not many other
              organisations do this. If you see something about your
              team/project that you do not understand, please reach out to your
              account leadership team.
            </Typography>
          )}

          <Typography
            variate="body1"
            classes={{ root: styleClasses.contactDetail }}
            gutterBottom
          >
            For questions related to Projector the product please reach out to:
            <br />
            <span
              style={{
                color: "#F2617A",
                fontWeight: "bold",
              }}
            >
              Matthew Worthington or Simon Jenkinson.
            </span>
          </Typography>

          <Typography
            variant="body1"
            classes={{
              root: styleClasses.reminderText,
            }}
            gutterBottom
          >
            You are about to enter Projector, this information is commercially
            sensitive. <span style={{ fontWeight: 800 }}>Do not continue</span>{" "}
            if you are in an{" "}
            <span style={{ fontWeight: 800 }}>
              exposed position or in a client call.
            </span>
          </Typography>

          {config.featureToggles.enableMarketDashboard ? (
            <RegionAndMarketSelector showLabels />
          ) : (
            <RegionSelector showLabel />
          )}

          <Typography
            variate="body1"
            classes={{ root: styleClasses.displayText }}
            gutterBottom
          >
            This is the region where projects are located. It may not
            necessarily be where staff are located.
          </Typography>

          <Box marginTop="12px">
            <Button
              variant="contained"
              onClick={login}
              classes={{
                root: styleClasses.agreeButton,
              }}
            >
              I AGREE AND UNDERSTAND, LET ME IN
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
