import React from "react";
import { TableCell, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  heading: {
    whiteSpace: "nowrap",
    fontSize: "13px",
    maxWidth: "5px",
    height: "8rem",

    "& div": {
      transform: "rotate(310deg) translate(8px, 1px)",

      "& span": {
        paddingBottom: "5px",
      },
    },
  },
});

export default function RotatedTableHeading(props) {
  const classes = useStyles();

  return (
    <TableCell className={classes.heading}>
      <div>
        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
          <span data-testid="category-heading">{props.text}</span>
        </Typography>
      </div>
    </TableCell>
  );
}
