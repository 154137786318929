import React from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import RefreshIcon from "@material-ui/icons/Refresh";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  grey: {
    color: grey[500],
  },
}));

export default function RetrievalError({ message, path, actionText }) {
  const classes = useStyles();

  return (
    <>
      {message && path && actionText && (
        <Grid item xs={12}>
          <Box mt={20}>
            <Typography variant="h4" align="center" className={classes.grey}>
              {message}
              <Box m={1} />
              <Button
                variant="contained"
                color="primary"
                startIcon={<RefreshIcon />}
                href={path}
              >
                {actionText}
              </Button>
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  );
}

RetrievalError.propTypes = {
  message: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
};
