import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  paper: {
    height: 100,
    width: 100,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontFamily: "bitter",
    color: "#003D4F",
    backgroundColor: "white",
  },
  summaryTitle: {
    marginTop: "1.3rem",
    fontFamily: "bitter",
    fontWeight: "bold",
    fontSize: 14,
    color: "white",
  },
  summaryNumber: {
    fontSize: "2.4rem",
    fontWeight: "bold",
    fontFamily: "bitter",
  },
  arrangement: {
    padding: "0.5em 0",
  },
  summary: {
    backgroundColor: "#003D4F",
    display: "flex",
    justifyContent: "space-evenly",
    padding: "2.4rem 0 2.12rem",
    marginTop: "5px",
  },
});
export default function UserSummary({users}) {
  const classes = useStyles();
  return (
    <Grid className={classes.reportSummary}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className={classes.arrangement}>
            <Grid item>
              <Grid container className={classes.summary}>
                <Grid item>
                  <Paper className={classes.paper}>
                    <Typography variant="h5" className={classes.summaryNumber}>
                      {users?.length}
                    </Typography>
                  </Paper>
                  <Typography variant="h6" className={classes.summaryTitle}>
                    Active
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
