import { useState, useContext } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FlagIcon from "@mui/icons-material/Flag";
import NoteIcon from "@mui/icons-material/Note";
import SaveIcon from "@mui/icons-material/Save";
import SchoolIcon from "@mui/icons-material/School";
import SecurityIcon from "@mui/icons-material/Security";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import { ApiClientContext } from "../../../../../../ApiClient/context";

const isNotBlank = (value) => Boolean(value);
const isBlank = (value) => !isNotBlank(value);

export default function CreateEvent(props) {
  const apiClient = useContext(ApiClientContext);
  const [event, setEvent] = useState({
    title: "",
    description: "",
    date: "",
    type: "",
  });
  const [otherType, setOtherType] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState(null);
  const [typeErrorMessage, setTypeErrorMessage] = useState(null);
  const [dateErrorMessage, setDateErrorMessage] = useState(null);

  const validateTitle = () => {
    setTitleErrorMessage(
      isNotBlank(event.title) ? null : "Title cannot be blank"
    );
    return isNotBlank(event.title);
  };

  const validateDate = () => {
    setDateErrorMessage(
      isNotBlank(event.date) ? null : "Valid date is required"
    );
    return isNotBlank(event.date);
  };

  const validateType = () => {
    if (isBlank(event.type)) {
      const message = otherType
        ? "Type must not be blank"
        : "A type must be selected";
      setTypeErrorMessage(message);
      return false;
    }
    setTypeErrorMessage(null);
    return true;
  };

  const validateFields = () => {
    let isValid = true;
    isValid = validateTitle() && isValid;
    isValid = validateType() && isValid;
    isValid = validateDate() && isValid;
    return isValid;
  };

  const createEvent = async () => {
    if (!validateFields()) {
      return;
    }
    apiClient
      .createEvent(props.projectId, event)
      .then((response) => {
        props.onEventCreated({ ...event, id: response });
        props.setAlertDisplayed(true);
        props.openModal(false);
      })
      .catch((error) => {
        setErrorMessage("Request failed");
        setErrorAlert(true);
      });
  };

  return (
    <Card>
      <CardHeader title="Create Event" />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          id="title"
          label="Title"
          variant="standard"
          error={Boolean(titleErrorMessage)}
          helperText={titleErrorMessage}
          onChange={(e) =>
            setEvent((prev) => ({ ...prev, title: e.target.value }))
          }
          onBlur={validateTitle}
        />
        <Box>
          <Box display="flex" gap={1}>
            <Chip
              icon={<FlagIcon />}
              label="Milestone"
              color={event.type === "Milestone" ? "primary" : "default"}
              onClick={() => {
                setEvent({ ...event, type: "Milestone" });
                setOtherType(false);
                setTypeErrorMessage(null);
              }}
            />
            <Chip
              icon={<EmojiEventsIcon />}
              label="Success"
              color={event.type === "Success" ? "primary" : "default"}
              onClick={() => {
                setEvent({ ...event, type: "Success" });
                setOtherType(false);
                setTypeErrorMessage(null);
              }}
            />
            <Chip
              icon={<SchoolIcon />}
              label="Learning"
              color={event.type === "Learning" ? "primary" : "default"}
              onClick={() => {
                setEvent({ ...event, type: "Learning" });
                setOtherType(false);
                setTypeErrorMessage(null);
              }}
            />
            <Chip
              icon={<DoubleArrowIcon />}
              label="Go Live"
              color={event.type === "Go Live" ? "primary" : "default"}
              onClick={() => {
                setEvent({ ...event, type: "Go Live" });
                setOtherType(false);
                setTypeErrorMessage(null);
              }}
            />
            <Chip
              icon={<SecurityIcon />}
              label="Security Kick-off"
              color={event.type === "Security Kick-off" ? "primary" : "default"}
              onClick={() => {
                setEvent({ ...event, type: "Security Kick-off" });
                setOtherType(false);
                setTypeErrorMessage(null);
              }}
            />
            <Chip
              icon={<NoteIcon />}
              label="Other"
              color={otherType ? "primary" : "default"}
              onClick={() => {
                setEvent({ ...event, type: "" });
                setOtherType(true);
                setTypeErrorMessage(null);
              }}
            />
          </Box>
          {typeErrorMessage && !otherType && (
            <Typography variant="caption" color={red["500"]}>
              A type must be selected
            </Typography>
          )}
        </Box>
        {otherType && (
          <TextField
            id="other-type"
            label="Other Type"
            variant="standard"
            onChange={(e) => setEvent({ ...event, type: e.target.value })}
            error={Boolean(typeErrorMessage)}
            helperText={typeErrorMessage}
            onBlur={validateType}
          />
        )}
        <TextField
          id="date"
          label="Date"
          type="date"
          variant="standard"
          onChange={(e) => setEvent({ ...event, date: e.target.value })}
          value={event.date}
          InputLabelProps={{ shrink: true }}
          error={Boolean(dateErrorMessage)}
          helperText={dateErrorMessage}
          onBlur={validateDate}
        />
        <TextField
          id="description"
          multiline
          label="Description"
          variant="standard"
          onChange={(e) => setEvent({ ...event, description: e.target.value })}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={createEvent}
        >
          Save
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          onClick={() => props.openModal(false)}
        >
          Cancel
        </Button>
      </CardActions>

      {errorAlert && <Alert severity="error">{errorMessage}</Alert>}
    </Card>
  );
}
