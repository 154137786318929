import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
  Cell,
  Legend,
  PieLabel,
} from "recharts";
import { useMemo } from "react";
import Status from "../../../../shared/types/Status";

type Size = "SMALL" | "MEDIUM" | "LARGE";

interface StatusChartProps {
  title: string;
  statusData: Partial<Record<Status, number>>;
  size: Size;
  change?: number;
  showLegend?: boolean;
  showValues?: boolean;
}

const styles = {
  SMALL: {
    changeFontSize: "13px",
    changeFontWeight: 400,
    changeOffset: -13,
    titleFontSize: "12px",
    titleFontWeight: 400,
    titleOffset: 9,
  },
  MEDIUM: {
    changeFontSize: "16px",
    changeFontWeight: 400,
    changeOffset: -16,
    titleFontSize: "14px",
    titleFontWeight: 600,
    titleOffset: 12,
  },
  LARGE: {
    changeFontSize: "22px",
    changeFontWeight: 600,
    changeOffset: -22,
    titleFontSize: "18px",
    titleFontWeight: 600,
    titleOffset: 14,
  },
};
const getChangeDisplayValue = (value: number) =>
  value ? `${value}%` : "No change";
const mapStatusData = (data: Partial<Record<Status, number>>) => [
  { name: "Red", value: data.RED, color: "#FF5F57" },
  { name: "Amber", value: data.AMBER, color: "#FEBC2E" },
  { name: "Green", value: data.GREEN, color: "#2CB240" },
  { name: "N/A", value: data.NOTAPPLICABLE, color: "darkgrey" },
  { name: "Not submitted", value: data.UNKNOWN, color: "#EEF1F3" },
];

const StatusChart = ({
  title,
  statusData,
  size,
  change,
  showLegend,
  showValues,
}: StatusChartProps) => {
  const data = useMemo(() => mapStatusData(statusData), [statusData]);

  return (
    <ResponsiveContainer aspect={1.5}>
      <PieChart style={{ backgroundColor: "#003D4F", lineHeight: 1.5 }}>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          cy="80%"
          innerRadius="100%"
          outerRadius="130%"
          blendStroke
          isAnimationActive={false}
          labelLine={false}
          label={
            showValues && ({ fill: "white", offsetRadius: 10 } as PieLabel)
          }
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              style={{ fontSize: "18px" }}
            />
          ))}
          {change !== undefined ? (
            <Label
              value={getChangeDisplayValue(change)}
              position="center"
              fill={change < 0 ? "#FF5F57" : change > 0 ? "#2CB240" : "white"}
              dy={styles[size].changeOffset}
              style={{
                fontSize: styles[size].changeFontSize,
                fontWeight: styles[size].changeFontWeight,
              }}
            />
          ) : null}
          <Label
            value={title}
            position="center"
            fill="white"
            dy={styles[size].titleOffset}
            style={{
              fontSize: styles[size].titleFontSize,
              fontWeight: styles[size].titleFontWeight,
            }}
          />
        </Pie>
        {showLegend && (
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            iconType="circle"
            iconSize={16}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default StatusChart;
