import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Grid,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import EditMetrics from "./edit-metrics";
import { ApiClientContext } from "../../../../ApiClient/context";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { HashLink } from "react-router-hash-link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  metricsToAttributes,
  fromResponse,
} from "../../../../Components/DeliveryReport/WeeklyReportDialog/mapper";

const RagStatusLink = () => (
  <HashLink to="/help#RAG" target="_blank">
    Red Amber Green Definitions <OpenInNewIcon style={{ fontSize: 12 }} />
  </HashLink>
);

export default function EditableWeeklyReportDialog({
  open,
  onClose,
  onUpdate,
  onUpdateCurrentWeek,
  selectedReportDate,
  projectId,
  report,
  closeDeliveryReportDialog,
  projectDetails,
  lastReportWorkedOn,
  lastWeeksReport,
}) {
  const [metrics, setMetrics] = useState([]);
  const [valid, setValid] = useState(report.isSubmittable);
  const [draft, setDraft] = useState(false);
  const [disableCopyPreviousWeek, setDisableCopyPreviousWeek] = useState(false);
  const apiClient = useContext(ApiClientContext);
  const isValid = () =>
    metrics.every(
      (x) =>
        x.status !== "UNKNOWN" &&
        x.subcategories?.every((y) => y.status !== "UNKNOWN"),
    );

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!valid && !draft) {
      return;
    }

    if (lastReportWorkedOn && lastReportWorkedOn.draft === true) {
      apiClient.updateStatus(
        projectId,
        lastReportWorkedOn.weekEnding,
        metricsToAttributes(fromResponse(lastReportWorkedOn).metrics, false),
      );
    }
    apiClient
      .updateStatus(
        projectId,
        report.weekEnding,
        metricsToAttributes(metrics, draft),
      )
      .then(() => {
        onClose && onClose();
        onUpdate && onUpdate();
        if (
          report.weekEnding === selectedReportDate ||
          (projectDetails.projectEndDate &&
            Date.parse(report.weekEnding) >
              Date.parse(projectDetails.projectEndDate))
        )
          onUpdateCurrentWeek && onUpdateCurrentWeek();
        closeDeliveryReportDialog && closeDeliveryReportDialog();
      });
  };

  const isNotSubmitted =
    (report && !report?.isDraft && report?.source === "GENERATED") ||
    report?.isDraft;

  const reportExists = () => !!lastWeeksReport;

  const handleInputChange = (name, type) => (event) => {
    if (type === "subcategories") {
      const subcategories = metrics.find((metric) => metric.name === name)[
        type
      ];
      subcategories.find((item) => item.name === event.target.name).status =
        event.target.value;
    } else {
      metrics.find((metric) => metric.name === name)[type] = event.target.value;
    }

    setMetrics(metrics);
    setValid(isValid());
  };

  const fillReportWithLastWeeksData = () => {
    setDisableCopyPreviousWeek(true);
    const lastWeeksMetrics = fromResponse(lastWeeksReport).metrics;
    setMetrics(lastWeeksMetrics);
    setValid(true);
  };

  const closeAndResetValidState = () => {
    setDisableCopyPreviousWeek(false);
    setMetrics([]);
    setValid(false);
    onClose();
  };

  useEffect(() => {
    report && report.metrics && setMetrics(report.metrics);
    setValid(report.isSubmittable);
  }, [report]);

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={closeAndResetValidState}
      fullWidth
    >
      <DialogActions>
        <IconButton
          color="primary"
          aria-label="close-modal-button"
          onClick={closeAndResetValidState}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle disableTypography>
        <Typography variant="h6">Update report</Typography>
        <Typography variant="subtitle2">
          Period ending{" "}
          {report &&
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(report.weekEnding))}
        </Typography>
        <Typography variant="subtitle2" style={{ paddingTop: 5 }}>
          R = Red, A = Amber, G = Green, N = Not Applicable. See{" "}
          <RagStatusLink /> for descriptions of the statuses in each context.
        </Typography>

        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputLabel>Last edited by</InputLabel>
              <Typography variant="subtitle2">
                {report && report?.author ? report?.author : ""}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Project name</InputLabel>
              <Typography variant="subtitle2">
                {report && report?.projectName ? report?.projectName : ""}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Submission status</InputLabel>
              <Typography
                variant="subtitle2"
                style={{ color: isNotSubmitted && "red" }}
              >
                {isNotSubmitted ? "Not submitted" : "Submitted"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Client name</InputLabel>
              <Typography variant="subtitle2">
                {report && report?.clientName ? report?.clientName : ""}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={
                  reportExists() ? "" : "No data submitted for previous week"
                }
              >
                <span>
                  <Button
                    variant="contained"
                    startIcon={<FileCopyOutlinedIcon />}
                    disabled={!reportExists() || disableCopyPreviousWeek}
                    onClick={fillReportWithLastWeeksData}
                  >
                    Copy Previous Entry
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>

      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container alignItems="flex-start" spacing={3}>
            <EditMetrics onChange={handleInputChange}>{metrics}</EditMetrics>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!valid && (
            <Typography variant="subtitle2" style={{ paddingTop: 5 }}>
              Please enter a valid status for each metric
            </Typography>
          )}
          <Button
            variant="contained"
            onClick={closeAndResetValidState}
            startIcon={<ClearIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => setDraft(true)}
            startIcon={<SaveIcon />}
            color="primary"
          >
            Save as draft
          </Button>
          <Button
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            color="primary"
            disabled={!valid}
            onClick={() => setDraft(false)}
          >
            Save and Submit
          </Button>
          <Box marginBottom={8} />
        </DialogActions>
      </form>
    </Dialog>
  );
}
