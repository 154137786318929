import { createTheme as createThemeV4 } from "@material-ui/core/styles";
import { createTheme } from "@mui/material";
import "@fontsource/inter";

const defaultBackground = "hsl(12, 0%, 98%)";

export const themeV4 = createThemeV4({
  palette: {
    background: {
      default: defaultBackground,
    },
    primary: {
      main: "hsl(184, 91%, 17%)",
    },
    secondary: {
      main: "hsl(185, 57%, 50%)",
    },
    tertiary: {
      main: "hsl(360, 67%, 44%)",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Inter", "Open Sans", "Arial", "sans-serif"].join(","),
    lineHeight: "150%",
    h3: {
      fontSize: 18,
      fontWeight: 600,
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
    },
    h1: {
      fontSize: 26,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body3: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "10px",
      },
    },
    MuiAlert: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: defaultBackground,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: defaultBackground,
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: defaultBackground,
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: "bolder",
        lineHeight: 1.5,
      },
    },
  },
});

export const themeV5 = createTheme({
  palette: {
    background: {
      default: defaultBackground,
    },
    primary: {
      main: "hsl(184, 91%, 17%)",
    },
    secondary: {
      main: "hsl(185, 57%, 50%)",
    },
    tertiary: {
      main: "hsl(360, 67%, 44%)",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Inter", "Open Sans", "Arial", "sans-serif"].join(","),
    lineHeight: "150%",
    h1: {
      fontSize: 26,
      fontWeight: 600,
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
    },
    h4: {
      fontSize: 16,
      fontWeight: "bold",
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#003D4F",
        },
      },
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "10px",
      },
    },
    MuiAlert: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: defaultBackground,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: defaultBackground,
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: defaultBackground,
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: "bolder",
        lineHeight: 1.5,
      },
    },
  },
});
