import React from "react";
import * as PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import DeliveryReporterEditView from "../EditReporter";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    paddingTop: 5,
    paddingLeft: 5,
  },
  name: {
    paddingRight: "16px",
  },
});

DeliveryReporterView.propTypes = {
  isEditMode: PropTypes.bool,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  reporters: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default function DeliveryReporterView(props) {
  const classes = useStyles();
  const {
    isEditMode,
    handleSave,
    handleCancel,
    setReporterList,
    reporters = [],
  } = props;

  return (
    <Grid item xs={12}>
      <Grid container>
        {!isEditMode ? (
          <Grid item>
            {reporters.map((reporter, index) => (
              <Grid container spacing={2} key={index} className={classes.root}>
                <Typography className={classes.name}>
                  {reporter.name}
                </Typography>
                <Typography>{reporter.email.toLowerCase()}</Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          <DeliveryReporterEditView
            editMode={isEditMode}
            reporters={reporters}
            onSave={handleSave}
            cancelEdits={handleCancel}
            setReporterList={setReporterList}
          />
        )}
      </Grid>
    </Grid>
  );
}
