import React from "react";
import { Grid } from "@material-ui/core";
import ViewMetric from "./ViewMetric"

export default function Metrics({ children }) {
  return (
    <Grid container alignItems="flex-start" spacing={3}>
      {children &&
        children.map((metric, index) => {
          return(
            <React.Fragment key={index}>
            <Grid item xs={index === 0 ? 12 : 4}>
              <ViewMetric>{metric}</ViewMetric>
            </Grid>
            </React.Fragment>);
        })}
    </Grid>
  );
}
