import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Dialog,
  Fab,
  Grid,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ApiClientContext } from "../../ApiClient/context";
import CreateProject from "../../Components/CreateProject";
import Filter from "../../Components/Filter";
import DisplayMessage from "../../Components/DisplayMessage";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import RetrievalError from "../../Components/RetrievalError";
import { ProjectsPageContext } from "./context";
import { useRegionStorage } from "../../shared/hooks/useRegionStorage";
import DashboardFilter from "../../shared/constants/DashboardFilter";
import {useMarketStorage} from "../../shared/hooks/useMarketStorage";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      margin: "5em",
      position: "fixed",
      right: 0,
      bottom: 0,
    },
  },
  grey: {
    color: grey[500],
  },
}));

export default function ProjectsPage() {
  let { projectName, clientName } = useParams(); 
  const [projectId, setProjectId] = useState(null);
  const [projects, setProjects] = useState(null);
  const apiClient = useContext(ApiClientContext);
  const [connectionIssue, setConnectionIssue] = useState(false);
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);
  const [apiMessage, setApiMessage] = useState({});
  const [region] = useRegionStorage();
  const [market] = useMarketStorage();
  const storageFilter = localStorage.getItem("filter");
  const selectedFilter = storageFilter
      ? JSON.parse(storageFilter)
      : DashboardFilter.Region;
  const classes = useStyles();

  const AddButton = () => (
    <div className={classes.root}>
      <Fab
        color="primary"
        aria-label="Create Project"
        onClick={() => setOpenCreateProjectDialog(true)}
      >
        <AddIcon />
      </Fab>
    </div>
  );

  const fetchProjects = useCallback(async () => {
    if(selectedFilter === DashboardFilter.Region) {
      await apiClient
          .getAllProjectsForSelectedRegion()
          .then((data) => {
            setProjects(data);
            setCurrentProject();
          })
          .catch(() => {
            setConnectionIssue(true);
          });
    } else {
      await apiClient
          .getAllProjectsForSelectedMarket()
          .then((data) => {
            setProjects(data);
            setCurrentProject();
          })
          .catch(() => {
            setConnectionIssue(true);
          });
    }

    //Ignore dep for url to stop excess load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient]);

  const setCurrentProject = () => {
    const project =
      projects &&
      projects.find(
        (project) =>
          projectName &&
          clientName &&
          project.name === projectName &&
          project.clientName === clientName,
      );

    const currentProjectId = (project && project.id) || null;

    setProjectId(currentProjectId);
  };

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects, region?.id, market?.crmMarketReference]);

  useEffect(() => {
    setCurrentProject();
    //Ignore dep for setCurrentProject to prevent excessive rerender (could potentially be refactored to remove this ignore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, projectName, clientName]);

  const projectsContext = {
    onUpdate: () => {
      fetchProjects();
    },
  };

  return (
    <ProjectsPageContext.Provider value={projectsContext}>
      {connectionIssue ? (
        <RetrievalError
          message="Could not fetch projects"
          path="/projects"
          actionText="Try again"
        />
      ) : projects === null ? (
        <>
          <Box m={19} />
          <Grid item xs={12}>
            <Box mt={20}>
              <Typography variant="h4" align="center" className={classes.grey}>
                <CircularProgress />
                <Box m={1} />
                Loading
              </Typography>
            </Box>
          </Grid>
          <AddButton />
        </>
      ) : projects.length === 0 ? (
        <>
          <Box m={19} />
          <Grid item xs={12}>
            <Box mt={20}>
              <Typography variant="h4" align="center" className={classes.grey}>
                No projects available
              </Typography>
            </Box>
          </Grid>
          <AddButton />
        </>
      ) : (
        <>
          <Box m={19} />
          <Filter
            projectId={projectId}
            projects={Array.isArray(projects) ? projects : []}
          />
          <AddButton />
        </>
      )}
      <Dialog
        open={openCreateProjectDialog}
        onClose={() => setOpenCreateProjectDialog(false)}
        aria-labelledby="create-dialog-title"
      >
        <CreateProject
          onDialogClose={() => setOpenCreateProjectDialog(false)}
          onApiResponse={(message) => {
            setApiMessage(message);
          }}
          onSuccess={fetchProjects}
        />
      </Dialog>
      <DisplayMessage
        message={apiMessage}
        onClose={() => setApiMessage({})}
        duration={3000}
      />
    </ProjectsPageContext.Provider>
  );
}
