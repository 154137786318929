import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import riskRatingsData from "./RiskRating_metadata.json";

export default function RiskRatingTable() {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(riskRatingsData);
  }, []);
  const whiteSpace = { whiteSpace: "pre-line" };
  return (
    <Grid container style={{ paddingLeft: "2em", paddingRight: "2em" }}>
      <Grid item xs={12}>
        <Box mt={8}>
          <Box mb={3}>
            <Typography variant="h4" id="RISKRATING">
              Risk Rating Description
            </Typography>
          </Box>
          <TableContainer component={Paper} id="risk rating table">
            <Table sx={{ minWidth: 650 }} aria-label="risk rating table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Impact Rating / Impact Type
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Delivery Impact
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Commercial/Legal Impact
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Reputational Impact
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    People Impact
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    InfoSec/Data Impact
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {row.impact_rating}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.delivery_impact}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.commercial_legal_impact}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.reputational_impact}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.people_impact}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.infoSec_impact}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
