import { getAttributeListFromReport } from "../../../shared/constants/attributes";
// TODO: Unify the mapper with src/Components/Common/ViewReportNotesDialog/mapper.js
export const metricsToAttributes = (metrics, draft) => {
  const attributes = metrics?.reduce((acc, metric) => {
    acc[metric.name] = {
      notes: metric?.notes,
      status: metric?.status,
      subcategories: metric?.subcategories,
    };
    return acc;
  }, {});

  return {
    isDraft: draft,
    attributes,
  };
};

const mapToMetrics = (report) => {
  const categories = getAttributeListFromReport(report);
  const metrics = [];
  categories?.forEach((category) => {
    metrics.push({
      name: category.value,
      title: category.name,
      notes: report.attributes?.[category.value]?.notes ?? "",
      status: report.attributes?.[category.value]?.status ?? "UNKNOWN",
      subcategories: report.attributes?.[category.value]?.subcategories ?? [],
    });
  });
  return metrics;
};

export const fromResponse = (response) => {
  const metrics = mapToMetrics(response);
  const isSubmittable = metrics?.every((metric) => metric.status !== "UNKNOWN");
  return {
    // TODO: Move this function somewhere else.
    canUpdate: (currentUser, deliveryReporters) => {
      if (
        deliveryReporters
          ?.map((reporter) => reporter.email)
          .includes(currentUser) &&
        response.source !== "EXTERNAL"
      ) {
        return true;
      }
      return false;
    },
    weekEnding: response?.weekEnding,
    author: response?.author,
    source: response?.source,
    projectName: response?.projectName,
    clientName: response?.clientName,
    metrics: metrics,
    isDraft: response?.draft,
    isSubmittable: isSubmittable,
  };
};
