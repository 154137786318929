import React from "react";
import { Help, Lock, LockOpen } from "@material-ui/icons";
import { Typography, makeStyles, Container } from "@material-ui/core";
import { yellow, red, blue, grey } from "@material-ui/core/colors";

const useStyles = makeStyles((StyleTheme) => ({
  icon: {
    fontSize: "8em",
  },

  sensitiveIcon: {
    color: red[500],
  },
  unknownIcon: {
    color: blue[500],
  },
  publicDomainIcon: {
    color: yellow[500],
  },
  sensitivityTitle: {
    color: StyleTheme.palette.primary.main,
  },
  sensitivityText: {
    color: grey[800],
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Sensitive() {
  const classes = useStyles();
  return (
    <>
      <Lock
        className={`${classes.icon} ${classes.sensitiveIcon}`}
        aria-label="tw sensitive"
      />

      <Typography
        variant="h6"
        component="span"
        align="center"
        className={classes.sensitivityTitle}
      >
        TW Sensitive
      </Typography>

      <Typography
        variant="caption"
        paragraph
        align="center"
        className={classes.sensitivityText}
      >
        Information about this project is sensitive and must not be shared
        outside of ThoughtWorks.
      </Typography>
    </>
  );
}

function Unknown() {
  const classes = useStyles();
  return (
    <>
      <Help
        className={`${classes.icon} ${classes.unknownIcon}`}
        aria-label="unknown sensitivity"
      />
      <Typography
        variant="h6"
        component="span"
        align="center"
        className={classes.sensitivityTitle}
      >
        Unknown
      </Typography>
      <Typography
        variant="caption"
        paragraph
        align="center"
        className={classes.sensitivityText}
      >
        No sensitivity rating has been recorded for this project. It should be
        treated as secret and not shared with anyone.
      </Typography>
    </>
  );
}

function PublicDomain() {
  const classes = useStyles();
  return (
    <>
      <LockOpen
        className={`${classes.icon} ${classes.publicDomainIcon}`}
        aria-label="public domain"
      />
      <Typography
        variant="h6"
        component="span"
        align="center"
        className={classes.sensitivityTitle}
      >
        Public Domain
      </Typography>
      <Typography
        variant="caption"
        paragraph
        align="center"
        className={classes.sensitivityText}
      >
        Information about this project may be shared with the public after
        ThoughtWorks approval.
      </Typography>
    </>
  );
}

function getSensitivity(sensitivity) {
  switch (sensitivity) {
    case "TW_SENSITIVE":
      return <Sensitive />;
    case "PUBLIC_DOMAIN":
      return <PublicDomain />;
    default:
      return <Unknown />;
  }
}

export default function SensitivityIndicator({ sensitivity }) {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      {getSensitivity(sensitivity)}
    </Container>
  );
}
