import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Clear, Save } from "@material-ui/icons";
import ReporterForm from "./ReporterForm";
import {
  isEmailValid,
  isNameValid,
  areAllEmailsValid,
} from "../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    display: "unset",
  },
  editMode: {
    display: "none",
  },
  cancelButton: {
    marginLeft: "10px",
  },
  name: {
    paddingRight: "16px",
  },
});

const thoughtworksDomain = "@thoughtworks.com";

DeliveryReporterEditView.propTypes = {
  onSave: PropTypes.func,
  cancelEdits: PropTypes.func,
  reporters: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default function DeliveryReporterEditView(props) {
  const classes = useStyles();
  const { onSave, cancelEdits, setReporterList, reporters } = props;

  const [reporterList, updateReporterList] = useState(reporters);

  useEffect(() => {
    updateReporterList(reporters);
  }, [reporters]);

  const handleSaveReporters = () => {
    onSave(
      reporterList.map((list) => {
        if (!list.email.includes(thoughtworksDomain)) {
          return {
            ...list,
            email: list.email.toLowerCase().concat(thoughtworksDomain),
          };
        }
        return list;
      })
    );
  };

  const handleCancelReporters = () => {
    cancelEdits(reporters);
  };

  const handleReporterDeletion = (reporter) => () => {
    updateReporterList(reporterList.filter((l) => l !== reporter));
  };

  const handleReporterNameChange = (reporter) => (event) => {
    updateReporterList(
      reporterList.map((l) => {
        if (l === reporter) {
          return {
            ...l,
            name: event.target.value,
          };
        }
        return l;
      })
    );

    setReporterList(
      reporterList.map((l) => {
        if (l === reporter) {
          return {
            ...l,
            name: event.target.value,
          };
        }
        return l;
      })
    );
  };

  const handleReporterEmailChange = (reporter) => (event) => {
    updateReporterList(
      reporterList.map((l) => {
        if (l === reporter) {
          return {
            ...l,
            email: event.target.value,
          };
        }
        return l;
      })
    );

    setReporterList(
      reporterList.map((l) => {
        if (l === reporter) {
          return {
            ...l,
            email: event.target.value,
          };
        }
        return l;
      })
    );
  };

  const disableSave = Boolean(
    reporterList.find(
      (list) =>
        isNameValid(list.name).error ||
        isEmailValid(list.email, reporterList).error ||
        areAllEmailsValid(list.email, reporterList)
    )
  );

  return (
    <Grid item>
      <Grid item>
        {reporterList.map((reporter, index) => {
          let duplicateEmails = areAllEmailsValid(reporter.email, reporterList);
          return (
            <ReporterForm
              key={index}
              showDeleteButton={reporterList.length > 1}
              handleDelete={handleReporterDeletion(reporter)}
              onNameChange={handleReporterNameChange(reporter)}
              onEmailChange={handleReporterEmailChange(reporter)}
              name={reporter.name}
              email={reporter.email}
              reporters={reporters}
              emailError={duplicateEmails}
            />
          );
        })}
      </Grid>
      <Box paddingTop={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          disabled={disableSave}
          onClick={handleSaveReporters}
        >
          Save
        </Button>
        <Button
          variant="contained"
          startIcon={<Clear />}
          onClick={handleCancelReporters}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </Box>
    </Grid>
  );
}
