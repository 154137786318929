import React from "react";
import { Avatar, Grid, Typography, makeStyles } from "@material-ui/core";
import { red, amber, green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  ragDefinition: {
    margin: theme.spacing(1),
  },
  red: {
    backgroundColor: red[500],
  },
  amber: {
    backgroundColor: amber[500],
  },
  green: {
    backgroundColor: green[500],
  },
}));

export default function RagDefinitionPopover() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={classes.ragDefinition}
    >
      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          <Avatar className={classes.red}>R</Avatar>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            No agreed plan to resolve issues which are impacting successful
            delivery.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          <Avatar className={classes.amber}>A</Avatar>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Key risks and issues are being managed but require decisive actions
            to be identified and executed to bring project back on track for
            delivery.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          <Avatar className={classes.green}>G</Avatar>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            On track to deliver successfully and all key risks and issues are
            being managed successfully.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
