import React, { useContext, useState } from "react";
import Alert from "@mui/material/Alert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { ApiClientContext } from "../../../../ApiClient/context";
import { useRegionStorage } from "../../../../shared/hooks/useRegionStorage";

export default function CycleOpenEmailReminder({ isOpen, onClose }) {
  const apiClient = useContext(ApiClientContext);

  const [showSnackMessage, setShowSnackMessage] = useState(false);
  const [snackState, setSnackState] = useState("success");

  const [region] = useRegionStorage();

  const resetError = () => {
    onClose();
  };

  const handleSendEmail = async () => {
    try {
      await apiClient.sendDeliveryReportCycleOpenReminder(region.shortName);
      setShowSnackMessage(true);
      setSnackState("success");
    } catch (e) {
      console.log(e);
      setShowSnackMessage(true);
      setSnackState("error");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackMessage(false);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle id="email-reminder-dialog" onClose={onClose}>
        <Typography>Reminder Email</Typography>
        <IconButton
          className="close-btn-modal"
          color="primary"
          aria-label="close-modal-button"
          onClick={resetError}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Typography>
          {`Do you want to confirm sending cycle open email reminders to delivery reporters in ${region?.shortName}?`}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button 
          data-testid="send-email-button"
          variant="contained" color="primary" onClick={handleSendEmail}>
          Send Email
        </Button>
      </DialogActions>
      <Snackbar
        data-testid="email-snackbar"
        open={showSnackMessage}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          data-testid="snackbar-message"
          severity={snackState}
        >
          {snackState === 'success' ? "Email Sent!" : "Error: Something went wrong"}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
