import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import {
  Alert,
  Avatar as MuiAvatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";

import { ApiClientContext } from "../../../../../ApiClient/context";
import validateInput from "../../../../../shared/utils/inputValidator";

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary?.main,
}));

const PeopleOfInterest = (props) => {
  const { people, projectId } = props;

  const [name, setName] = useState({ value: "", hasError: false });
  const [role, setRole] = useState({ value: "", hasError: false });
  const [listOfPeople, setListOfPeople] = useState(people || []);
  const [response, setResponse] = useState({ message: "", severity: "" });
  const [showMessage, setShowMessage] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const apiClient = useContext(ApiClientContext);

  const isBlank = (parameter) => parameter.value === "";

  const addPerson = async (e) => {
    e.preventDefault();
    const isNameBlank = isBlank(name);
    const isRoleBlank = isBlank(role);
    isNameBlank && setName({ ...name, hasError: true });
    isRoleBlank && setRole({ ...role, hasError: true });

    if (!isNameBlank && !isRoleBlank) {
      const updatedListOfPeople = [
        ...listOfPeople,
        { name: name.value, role: role.value },
      ];

      apiClient
        .updatePeopleOfInterest(projectId, updatedListOfPeople)
        .then((response) => {
          setListOfPeople(updatedListOfPeople);
          setResponse({
            message: "Successfully added person of interest",
            severity: "success",
          });

          setName({ ...name, value: "" });
          setRole({ ...role, value: "" });
          setIsOtherSelected(false);
        })
        .catch((error) => {
          setResponse({
            message: "People of interest could not be updated",
            severity: "error",
          });
        })
        .finally(() => {
          setShowMessage(true);
        });
    }
  };

  const removePerson = async (index) => {
    let updatedListOfPeople = [...listOfPeople];
    updatedListOfPeople.splice(index, 1);

    apiClient
      .updatePeopleOfInterest(projectId, updatedListOfPeople)
      .then((response) => {
        setListOfPeople(updatedListOfPeople);
        setResponse({
          message: "Successfully removed person of interest",
          severity: "success",
        });
      })
      .catch((error) => {
        setResponse({
          message: "People of interest could not be updated",
          severity: "error",
        });
      })
      .finally(() => {
        setShowMessage(true);
      });
  };

  const displayPerson = (person, index) => {
    return (
      <ListItem aria-label={"people-of-interest-" + index} key={index}>
        <ListItemAvatar sx={{ paddingBottom: 1 }}>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={person.name} secondary={person.role} />
        <Button onClick={() => removePerson(index)}>
          <DeleteIcon />
        </Button>
      </ListItem>
    );
  };

  const selectRole = (event) => {
    if (event.target.value === "Other") {
      setIsOtherSelected(true);
      setRole({ value: "", hasError: false });
    } else {
      setRole({ value: event.target.value, hasError: false });
      setIsOtherSelected(false);
    }
  };

  return (
    <Card
      aria-label={"people-of-interest-section"}
      data-testid={"people-of-interest-" + projectId}
    >
      <CardActions>
        <form
          aria-label="add-person-of-interest"
          noValidate
          onSubmit={addPerson}
          style={{ width: "100%" }}
        >
          <Box display="flex" alignItems="start" mt={2} ml={1} gap={2}>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              size="small"
              required
              error={name.hasError}
              helperText={name.hasError && "*Required"}
              sx={{ minWidth: "250px" }}
              value={name.value}
              onChange={(event) => {
                if (validateInput(event.currentTarget.value, "-")) {
                  setName({ value: event.target.value, hasError: false });
                }
              }}
            />
            <TextField
              id="role"
              select
              label="Role"
              variant="outlined"
              size="small"
              required
              error={!isOtherSelected && role.hasError}
              helperText={!isOtherSelected && role.hasError && "*Required"}
              sx={{ minWidth: "250px" }}
              value={isOtherSelected ? "Other" : role.value}
              onChange={selectRole}
            >
              <MenuItem value="Delivery Principal">Delivery Principal</MenuItem>
              <MenuItem value="Client Principal">Client Principal</MenuItem>
              <MenuItem value="Tech Principal">Tech Principal</MenuItem>
              <MenuItem value="Project Manager">Project Manager</MenuItem>
              <MenuItem value="Advisory Principal">Advisory Principal</MenuItem>
              <MenuItem value="Product Manager">Product Manager</MenuItem>
              <MenuItem value="Tech Lead">Tech Lead</MenuItem>
              <MenuItem value="Developer">Developer</MenuItem>
              <MenuItem value="Quality Analyst">Quality Analyst</MenuItem>
              <MenuItem value="Business Analyst">Business Analyst</MenuItem>
              <MenuItem value="Security Champion">Security Champion</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            {isOtherSelected && (
              <Grid item xs>
                <TextField
                  id="other-role"
                  label="Other Role"
                  variant="outlined"
                  size="small"
                  value={role.value}
                  onChange={(event) =>
                    setRole({ value: event.target.value, hasError: false })
                  }
                  required
                  error={role.hasError}
                  helperText={role.hasError && "*Required"}
                  fullWidth
                />
              </Grid>
            )}
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              type="submit"
            >
              Add
            </Button>
          </Box>
        </form>
      </CardActions>
      <CardContent aria-label="list-of-people">
        {listOfPeople && listOfPeople.length > 0 ? (
          <List>{listOfPeople.map((p, i) => displayPerson(p, i))}</List>
        ) : (
          <Typography
            aria-label="no-people-of-interest"
            variant="overline"
            color={grey[600]}
            ml={1.5}
          >
            Currently no people of interest
          </Typography>
        )}
        {response && (
          <Snackbar
            open={showMessage}
            autoHideDuration={6000}
            onClose={() => setShowMessage(false)}
          >
            <Alert severity={response.severity || undefined}>
              {response.message}
            </Alert>
          </Snackbar>
        )}
      </CardContent>
    </Card>
  );
};

export default PeopleOfInterest;
