import { Box, Grid, InputLabel } from "@material-ui/core";
import DeliveryReporterView from "./ViewReporter";
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { Add as AddButton, Edit as EditButton } from "./Buttons";

Reporter.propTypes = {
  onSaveReporters: PropTypes.func,
  reporters: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
};

export default function Reporter(props) {
  // todo: temp - remove this state when save is done
  const [reporterList, setReporterList] = useState(props.reporters || []);
  const [isEditMode, setIsEditMode] = useState(false);
  const hasReporters = reporterList?.length > 0;

  const showAddButton = isEditMode || !hasReporters;
  const showEditButton = !isEditMode && hasReporters;

  useEffect(() => {
    if (!props.deliveryReportEnabled && isEditMode) {
      setIsEditMode(false);
    }
  }, [props.deliveryReportEnabled, isEditMode]);

  useEffect(() => {
    setReporterList(props.reporters || []);
  }, [props.reporters]);

  const handleEditReporter = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setReporterList(props.reporters);
  };

  const handleSaveReporters = (reporters) => {
    setIsEditMode(false);
    setReporterList(reporters); // todo: remove me when save works
    props.onSaveReporters(reporters);
  };

  const handleAddReporter = () => {
    setIsEditMode(true);
    setReporterList([...reporterList, { name: "", email: "" }]);
  };

  return (
    <Box paddingTop={3} paddingLeft={3} paddingBottom={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <InputLabel
              htmlFor={"project-delivery-reporter"}
              style={{
                fontWeight: "bolder",
                paddingRight: "10px",
                lineHeight: "40px",
                textAlign: "center",
              }}
            >
              Delivery Reporter
            </InputLabel>
            {showAddButton && props.deliveryReportEnabled && (
              <AddButton handleAdd={handleAddReporter} />
            )}
            {showEditButton && props.deliveryReportEnabled && (
              <EditButton handleEdit={handleEditReporter} />
            )}
          </Grid>
        </Grid>
        <DeliveryReporterView
          isEditMode={isEditMode}
          handleSave={handleSaveReporters}
          handleCancel={handleCancelEdit}
          reporters={reporterList}
          setReporterList={setReporterList}
        />
      </Grid>
    </Box>
  );
}
