import { useEffect } from "react";
import { Grid, Box, Typography, MenuItem, Select, Link } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { formatDate } from "../../../../shared/utils/dateUtils";
import useAvailableDeliveryReportDates from "../../../../shared/hooks/useAvailableDeliveryReportDates";
import useDeliveryReportsSummary from "../../../../shared/hooks/useDeliveryReportsSummary";

interface WeeklySelectionHeaderCountProps {
  count?: number;
  label: string;
}

function WeeklySelectionHeaderCount({
  count,
  label,
}: WeeklySelectionHeaderCountProps) {
  return (
    <Box
      style={{
        display: "flex",
        gap: 4,
        color: "white",
        alignItems: "baseline",
      }}
    >
      <Typography variant="body1">
        <span
          style={{
            fontSize: "36px",
            fontWeight: 700,
          }}
        >
          {count ?? "-"}
        </span>{" "}
        {label}
      </Typography>
    </Box>
  );
}

interface WeekSelectionHeaderProps {
  weekEnding: string;
  setWeekEnding: (weekEnding: string) => void;
}

export default function WeekSelectionHeader({
    weekEnding,
    setWeekEnding,
                                            }: WeekSelectionHeaderProps) {
  const { data: availableReportDates } = useAvailableDeliveryReportDates();
  const deliveryReportsSummary = useDeliveryReportsSummary(weekEnding);

  useEffect(() => {
    availableReportDates && setWeekEnding(availableReportDates[0] ?? "");
  }, [availableReportDates, setWeekEnding]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#003D4F",
        borderRadius: "12px",
        padding: "12px",
        paddingRight: "24px",
        gap: "8px",
      }}
      justifyContent="space-between"
      alignItems="center"
      data-testid="week-selection-header"
    >
      <Grid
        item
        xs={4}
        sx={{
          backgroundColor: "white",
          color: "#003D4F",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "12px",
          padding: "16px",
          minWidth: "475px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <DateRangeIcon />
          <Typography variant="h2" id="week-ending-label">
            Week Ending
          </Typography>
        </Box>
        <Box>
          <Select
            labelId="week-ending-label"
            value={weekEnding}
            onChange={(event) => setWeekEnding(event.target.value)}
            variant="standard"
            IconComponent={() => (
              <ArrowForwardIcon
                sx={{ color: "#003D4F", width: 18, height: 18 }}
              />
            )}
            disableUnderline
            sx={(theme) => ({
              color: "#003D4F",
              "& .MuiSelect-select:focus": {
                backgroundColor: "white",
              },
              "& .MuiSelect-select": {
                padding: "0",
                paddingRight: "16px !important",
              },
              fontSize: theme.typography.h2.fontSize,
              fontWeight: theme.typography.h2.fontWeight,
            })}
          >
            {availableReportDates?.map((date: string) => (
              <MenuItem key={date} value={date}>
                {formatDate(new Date(date))}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
      <Grid item>
        <WeeklySelectionHeaderCount
          count={deliveryReportsSummary.data?.activeClientProjects}
          label="Active projects"
        />
      </Grid>
      <Grid item>
        <WeeklySelectionHeaderCount
          count={deliveryReportsSummary.data?.submittedDeliveryReports}
          label="Delivery reports submitted"
        />
      </Grid>
      <Grid item>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Box style={{ display: "flex", gap: "4px" }}>
            <InfoOutlinedIcon sx={{ color: "white" }} />
            <Typography
              variant="body1"
              style={{ color: "white", alignSelf: "center" }}
            >
              Totals only include client projects
            </Typography>
          </Box>
          <Link
            href="/help#RAG"
            underline="always"
            target="_blank"
            color="#ffffff"
            variant="body1"
          >
            View RAG definitions
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}
