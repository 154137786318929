import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography, FormHelperText,
} from "@material-ui/core";
import useRegions from "../../shared/hooks/useRegions";

export default function Regions({
  onChange,
  defaultValue,
  styling,
  error = false,
}) {
  const [value, setValue] = useState(defaultValue ? defaultValue : -1);
  const regions = useRegions();

  return (
    <>
      {regions.isError && (
        <Typography>
          There was an issue fetching region data please reload the browser or try
          again in a bit.
        </Typography>
      )}
      {regions.data && regions.data.length > 0 && (
        <FormControl variant={styling && "outlined"} error={error} fullWidth>
          {!styling && (<InputLabel id="region-label">Region *</InputLabel>)}
          <Select
            id="regions"
            labelId="region-label"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChange && onChange(e.target.value);
            }}
            fullWidth
          >
            <MenuItem selected disabled value={-1}>
              <em>Choose a region</em>
            </MenuItem>
            {regions.data.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.shortName}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>Region required</FormHelperText>}
        </FormControl>
      )}
    </>
  );
}
