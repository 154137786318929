import { Project, Report } from "../hooks/useProject";

const attributes: { [key: string]: { label: string, order: number } } = {
    "business_value": { label: "Business Value", order: 21 },
    "commercial": { label: "Commercial", order: 9 },
    "commercials": { label: "Commercials", order: 8 },
    "commercials_and_legal": { label: "Commercials & Legal", order: 11 },
    "continuous_delivery": { label: "Continuous Delivery", order: 12 },
    "customer_relations": { label: "Customer Relations", order: 17 },
    "customer_relationship": { label: "Customer Relationship", order: 16 },
    "delivery": { label: "Delivery", order: 24 },
    "dependencies": { label: "Dependencies", order: 6 },
    "future_business": { label: "Future Business", order: 19 },
    "governance": { label: "Governance", order: 22 },
    "info_sec": { label: "InfoSec", order: 13 },
    "info_sec_tier_one": { label: "InfoSec Tier One", order: 14 },
    "info_sec_tier_two": { label: "InfoSec Tier Two", order: 15 },
    "legal": { label: "Legal", order: 10 },
    "overall": { label: "Overall Status", order: 1 },
    "plan": { label: "Plan", order: 2 },
    "risk_exposure": { label: "Risk Exposure", order: 20 },
    "staffing": { label: "Staffing", order: 18 },
    "team": { label: "Team", order: 5 },
    "team_mix": { label: "Team Mix", order: 3 },
    "team_morale": { label: "Team Morale", order: 4 },
    "technology": { label: "Technology", order: 7 },
    "ways_of_working": { label: "Ways of working", order: 23 },
}

const attributeToLabel = (attribute: string): string => {
    return attributes[attribute]?.label || attribute;
}

const getAttributeOrder = (attribute: string): number => {
    return attributes[attribute]?.order || 999999;
}

const getUniqueAttributesFromProjects = (projects: Project[]) => {
    return Array.from(new Set(
        projects
            .map((project) => Object.keys(project?.report?.attributes || {}))
            .flat())
    );
}

export const getAttributeListFromProjects = (projects: Project[]): { name: string, value: string }[] => {
    return getUniqueAttributesFromProjects(projects)
        .map((attribute) => ({
            name: attributeToLabel(attribute), value: attribute
        }))
        .toSorted(({ value: valueA }, { value: valueB }) => getAttributeOrder(valueA) - getAttributeOrder(valueB))
}

export const getAttributeListFromReport = (report: Report): { name: string, value: string }[] => {
    return Object.keys(report.attributes || {}).map((attribute) => ({
        name: attributeToLabel(attribute), value: attribute
    }))
    .toSorted(({ value: valueA }, { value: valueB }) => getAttributeOrder(valueA) - getAttributeOrder(valueB));
}

export const isCompulsory = (category: string): boolean => category === 'info_sec_tier_one';
