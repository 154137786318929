import { useContext } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { useQuery } from "@tanstack/react-query";

const useIsDeliveryDirector = () => {
  const apiClient = useContext(ApiClientContext);

  return useQuery<boolean>({
    queryKey: ["isDeliveryDirector"],
    queryFn: () => apiClient.isDeliveryDirector(),
  });
};



export default useIsDeliveryDirector;
