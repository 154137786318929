import { useState, useContext, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { ApiClientContext } from "../../../ApiClient/context";
import { fromResponse } from "./mapper";

import Metrics from "./Metrics";
import NotesDialogTitle from "./NotesDialogTitle";

export default function ViewReportNotesDialog({
  projectId,
  data,
  alert = false,
  open,
  onClose,
}) {
  const [report, setReport] = useState(undefined);
  const apiClient = useContext(ApiClientContext);

  const fetch = useCallback(async () => {
    await apiClient
      .getStatus(projectId, data.weekEnding)
      .then((response) => setReport(fromResponse(response)));
  }, [apiClient, projectId, data.weekEnding]);

  useEffect(() => {
    data && open && fetch();
  }, [data, fetch, open]);

  return (
    <>
      {report && (
        <Dialog
          maxWidth="xl"
          open={open}
          onClose={onClose}
          data-testid={"testingPopUp"}
        >
          <DialogActions>
            <IconButton
              color="primary"
              aria-label="close-modal-button"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <NotesDialogTitle report={report} alert={alert} />
          <DialogContent>
            <Metrics>{report?.metrics}</Metrics>
            <Grid container>
              <Grid item md={12}></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {report && (
              <>
                <Button
                  variant="contained"
                  startIcon={<CloseIcon />}
                  onClick={onClose}
                >
                  Close
                </Button>
              </>
            )}
            <Box marginBottom={8} />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
