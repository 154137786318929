import { useState, useContext, useEffect, useCallback } from "react";
import { ApiClientContext } from "../../../ApiClient/context";

import EditableWeeklyReportDialog from "./EditableWeeklyReportDialog";
import ViewReportNotesDialog from "../../../Components/Common/ViewReportNotesDialog";
import { fromResponse } from "../../../Components/DeliveryReport/WeeklyReportDialog/mapper";

export default function WeeklyReportDialog({
  open,
  onClose,
  onUpdate,
  onUpdateCurrentWeek,
  selectedReportDate,
  projectId,
  isLocked,
  data,
  lastReportWorkedOn,
  lastWeeksReport,
  projectDetails,
}) {
  const apiClient = useContext(ApiClientContext);
  const [report, setReport] = useState(data);
  const fetch = useCallback(async () => {
    await apiClient.getStatus(projectId, data.weekEnding).then((response) => {
      setReport(fromResponse(response));
    });
  }, [apiClient, projectId, data]);

  useEffect(() => {
    data && open && fetch();
  }, [data, fetch, open]);

  return (
    <>
      {!isLocked ? (
        <EditableWeeklyReportDialog
          open={open}
          onClose={onClose}
          onUpdate={onUpdate}
          onUpdateCurrentWeek={onUpdateCurrentWeek}
          selectedReportDate={selectedReportDate}
          projectId={projectId}
          projectDetails={projectDetails}
          report={report}
          closeDeliveryReportDialog={onClose}
          lastReportWorkedOn={lastReportWorkedOn}
          lastWeeksReport={lastWeeksReport}
        />
      ) : (
        <ViewReportNotesDialog
          projectId={projectId}
          data={data}
          open={open}
          onClose={onClose}
          alert={true}
        />
      )}
    </>
  );
}
