import { useContext } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { useQuery } from "@tanstack/react-query";
import {
  PortfolioDeliveryReport,
  StatusReport,
  TotalsItemSummary,
  ReportPortfolio,
} from "../types/Interfaces";
import Status from "../types/Status";
import config from "../../config";
import DashboardFilter from "../constants/DashboardFilter";
import ApiClient from "../../ApiClient";

interface ReportsAndTotals {
  total?: Record<string, TotalsItemSummary>;
  portfolios?: ReportPortfolio[];
  reports?: Report[];
}
interface Report {
  portfolio: string;
  clientName: string;
  domain: string;
  engagement: string;
  location: string;
  projectId: number;
  projectName: string;
  report: {
    attributes: {
      overall: {
        changeStatus: string;
        notes: string;
        previousStatus: string;
        status: string;
      };
    };
  };
}
interface DeliveryReportSummary {
  totalsAndProjects: ReportsAndTotals;
  activeClientProjects: number;
  submittedDeliveryReports: number;
  overall: StatusReport;
  portfolios: PortfolioDeliveryReport[];
  riskCriteria: Record<string, StatusReport>;
}

function countGreenProjects(rag: Partial<Record<Status, number>>) {
  return Object.entries(rag)
    .filter(([key]) => key === "GREEN")
    .reduce((acc, [, value]) => acc + value, 0);
}

function countSubmittedProjects(rag: Partial<Record<Status, number>>) {
  return Object.entries(rag)
    .filter(([key]) => key !== "UNKNOWN")
    .reduce((acc, [, value]) => acc + value, 0);
}

function countTotalProjects(rag: Partial<Record<Status, number>>) {
  return Object.entries(rag).reduce((acc, [, value]) => acc + value, 0);
}

function getTotalSubmittedProjects(overall: TotalsItemSummary = {}) {
  return countSubmittedProjects(overall.rag ?? {});
}

function getTotalActiveProjects(overall: TotalsItemSummary = {}) {
  return Object.values(overall.rag ?? {}).reduce(
    (acc, value) => acc + value,
    0
  );
}

function calculateGreenPercentage(rag: Partial<Record<Status, number>>) {
  const submittedProjects = countTotalProjects(rag);
  const greenProjects = countGreenProjects(rag);

  return submittedProjects === 0
    ? 0
    : (greenProjects * 100) / submittedProjects;
}

function calculateChange(
  rag: Partial<Record<Status, number>> = {},
  previousRag: Partial<Record<Status, number>> = {}
) {
  return Math.round(
    calculateGreenPercentage(rag) - calculateGreenPercentage(previousRag)
  );
}

function getStatusReport(totalsItem: TotalsItemSummary = {}): StatusReport {
  return {
    rag: totalsItem.rag ?? {},
    change: calculateChange(totalsItem.rag, totalsItem.previousRag),
    projectsCount: getTotalActiveProjects(totalsItem ?? {}),
  };
}

function getPortfolios(
  portfolios: ReportPortfolio[] = []
): PortfolioDeliveryReport[] {
  return portfolios.map((portfolio) => ({
    id: portfolio.portfolioId,
    name: portfolio.portfolioName,
    overall: getStatusReport(portfolio.total?.overall),
  }));
}

function getRiskCriteria(
  total: Record<string, TotalsItemSummary> = {}
): Record<string, StatusReport> {
  return Object.entries(total)
    .filter(([key]) => key !== "overall")
    .reduce<Record<string, StatusReport>>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: getStatusReport(value),
      }),
      {}
    );
}

async function getReportsAndTotalsForFilterSelectedInLocalStorage(apiClient: ApiClient, weekEnding: string) {
  // TODO: can this be abstracted away somehow? throws exception when blank
  let selectedFilter = localStorage.getItem("filter");
  selectedFilter = selectedFilter ? JSON.parse(selectedFilter) : selectedFilter;

  if (selectedFilter === DashboardFilter.Market) {
    return await apiClient.getMarketReportsAndTotals(weekEnding);
  } else {
    return await apiClient.getReportsAndTotals(weekEnding);
  }
}

const useDeliveryReportsSummary = (weekEnding: string) => {
  const apiClient = useContext(ApiClientContext);

  return useQuery<DeliveryReportSummary>({
    queryKey: ["deliveryReportsSummary", weekEnding],
    queryFn: async () => {
      let data: ReportsAndTotals;

      if(config.featureToggles.enableMarketDashboard) {
        data = weekEnding
          ? await getReportsAndTotalsForFilterSelectedInLocalStorage(apiClient, weekEnding)
          : {};
      }
      else {
        data = weekEnding
          ? await apiClient.getReportsAndTotals(weekEnding)
          : {};
      }

      return {
        totalsAndProjects: data ?? {},
        activeClientProjects: getTotalActiveProjects(data?.total?.overall),
        submittedDeliveryReports: getTotalSubmittedProjects(
          data?.total?.overall
        ),
        overall: getStatusReport(data?.total?.overall),
        portfolios: getPortfolios(data?.portfolios),
        riskCriteria: getRiskCriteria(data?.total),
      };
    },
    refetchOnWindowFocus: false,
  });
};

export default useDeliveryReportsSummary;
