import React from "react";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import RotatedTableHeading from "./RotatedTableHeading";
import StatusCell from "./StatusCell";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { HashLink } from "react-router-hash-link";
import { getAttributeListFromProjects } from "../../shared/constants/attributes";
import ViewReportNotesDialog from "../../Components/Common/ViewReportNotesDialog";
import { Info } from "@mui/icons-material";

const useStyles = makeStyles({
  abbreviations: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  projectStatusTable: {
    "& th": {
      verticalAlign: "bottom",
      backgroundColor: "white",
    },
    "& td": {
      padding: "8px 12px",
    },
  },
  marginSimilarToTable: {
    margin: "16px",
  },
  stickyTable: {
    position: "sticky",
    top: "0px",
    height: "140px",
  },
  stickyFooter: {
    position: "sticky",
    bottom: "0px",
    backgroundColor: "white",
  },
  emptyTableNotice: {
    backgroundColor: "#fafafa",
  },
  viewNotesAlignText: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  cursorPointer: {
    cursor: "pointer",
  },
});

const statusWord = (changeStatus) => {
  switch (changeStatus) {
    case "IMPROVED":
      return "Better";
    case "DETERIORATED":
      return "Worse";
    case "STATIONARY":
      return "Same";
    default:
      return "Unknown";
  }
};

const RagStatusLink = () => (
  <HashLink to="/help#RAG" target="_blank">
    Red Amber Green Definitions <OpenInNewIcon style={{ fontSize: 14 }} />
  </HashLink>
);

const TableContent = ({ projects, setOpenProjectId, categories }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [notesProjectId, setNotesProjectId] = React.useState(undefined);

  let sortedProjects = [...projects];
  sortedProjects.sort((a, b) =>
    a.clientName === b.clientName
      ? a.projectName.localeCompare(b.projectName)
      : a.clientName.localeCompare(b.clientName),
  );
  const tableContent = sortedProjects.map((project) => (
    <TableRow key={project.projectId}>
      <TableCell
        style={{ maxWidth: "125px" }}
        className={classes.abbreviations}
        data-testid="project-status-table-client-name-value"
      >
        <Typography>
          <span title={project.clientName}>{project.clientName}</span>
        </Typography>
      </TableCell>
      <TableCell
        style={{ maxWidth: "300px" }}
        className={classes.abbreviations}
        data-testid="project-status-table-project-name-value"
      >
        <span title={project.projectName}>
          <Link
            onClick={() => {
              setOpenProjectId(project.projectId);
            }}
            to={`delivery-report/project/${project.projectId}`}
            component={RouterLink}
          >
            <Typography variant="body1">{project.projectName}</Typography>
          </Link>
        </span>
      </TableCell>
      <TableCell
        style={{ maxWidth: "300px" }}
        className={classes.abbreviations}
      >
        <Typography variant="body1">
          {project.reportLastSubmitted
            ? new Date(project.reportLastSubmitted).toLocaleDateString(
                "en-gb",
                {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                },
              )
            : "N/A"}
        </Typography>
      </TableCell>
      {categories?.map((category) => (
        <StatusCell
          key={category.value}
          color={
            project.report?.draft
              ? null
              : project.report?.attributes[
                  category.value
                ]?.status?.toLowerCase()
          }
          notes={
            project.report?.draft
              ? null
              : project.report?.attributes[category.value]?.notes
          }
          title={category.name}
        />
      ))}
      <TableCell
        align={"center"}
        style={{ maxWidth: "125px", textAlign: "center" }}
        className={classes.abbreviations}
      >
        <Typography>
          {project.report?.draft
            ? "Unknown"
            : statusWord(project.report?.attributes.overall.changeStatus)}
        </Typography>
      </TableCell>
      <TableCell
        className={classes.cursorPointer}
        style={{ textAlign: "center" }}
        onClick={() => {
          setOpen(true);
          setNotesProjectId(project.projectId);
        }}
      >
        <IconButton
          color="secondary"
          aria-label="View notes"
          title="View notes"
          style={{
            padding: "0",
          }}
        >
          <Info className={classes.viewNotesAlignText} fontSize="medium" />
        </IconButton>
      </TableCell>
      {open && notesProjectId === project.projectId && (
        <ViewReportNotesDialog
          projectId={notesProjectId}
          data={project.report}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </TableRow>
  ));
  return <>{tableContent}</>;
};

export default function ProjectStatusTable({
  projects,
  filterRagStatus,
  title,
  setOpenProjectId,
}) {
  const classes = useStyles();
  const filterProjects = function () {
    if (projects && projects.length) {
      const filterBy = filterRagStatus;
      return projects.filter((project) => {
        if (["RED", "AMBER", "GREEN", "UNKNOWN"].includes(filterBy)) {
          if (project.report?.draft) {
            return filterBy === "UNKNOWN";
          }
          return project.report?.attributes?.overall.status === filterBy;
        }
        return true;
      });
    } else return projects;
  };

  const filteredProjects = React.useMemo(filterProjects, [
    projects,
    filterRagStatus,
  ]);

  const tableHasContent =
    filteredProjects &&
    Array.isArray(filteredProjects) &&
    filteredProjects.length > 0;

  const categories = getAttributeListFromProjects(filteredProjects);

  return (
    <Paper data-testid="project-status-table">
      {title && (
        <Typography
          variant="h5"
          className={classes.marginSimilarToTable}
          data-testid="project-status-table-title"
          style={{ paddingTop: "16px" }}
        >
          {title}
        </Typography>
      )}
      <TableContainer>
        {!tableHasContent && (
          <Alert severity="info" className={classes.marginSimilarToTable}>
            <Typography> No projects to display</Typography>
          </Alert>
        )}
        {tableHasContent && (
          <Table
            className={classes.projectStatusTable}
            aria-label="Project status table"
          >
            <caption className={classes.stickyFooter}>
              R = Red, A = Amber, G = Green, N = Not Applicable. See{" "}
              <RagStatusLink /> for descriptions of the statuses in each
              context.
            </caption>
            <TableHead>
              <TableRow className={classes.stickyTable}>
                <TableCell style={{ width: "200px" }}>
                  <Typography variant="h3">Client Name</Typography>
                </TableCell>
                <TableCell style={{ width: "400px" }}>
                  <Typography variant="h3">Project Name</Typography>
                </TableCell>
                <TableCell style={{ width: "200px" }}>
                  <Typography variant="h3">Last submitted</Typography>
                </TableCell>
                {categories?.map((category) => (
                  <RotatedTableHeading
                    key={category.value}
                    text={category.name}
                  />
                ))}
                <TableCell align={"center"}>
                  <Typography variant="h3">Status change</Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography variant="h3">View notes</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableContent
                projects={filteredProjects}
                setOpenProjectId={setOpenProjectId}
                categories={categories}
              />
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Paper>
  );
}
