import React from "react";
import { Typography, Grid, InputLabel } from "@material-ui/core";

export default function ReportSubtitle(props) {
  const { label, subtitle } = props;
  return (
    <Grid item xs={6}>
      <InputLabel>{label}</InputLabel>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Grid>
  );
}
