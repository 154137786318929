import React, { useEffect, useContext } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress, SelectChangeEvent, Box
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ApiClientContext } from "../../../ApiClient/context";
import { useRegionStorage } from "../../hooks/useRegionStorage";
import useRegions from "../../hooks/useRegions";

interface RegionSelectorProps {
  showLabel?: boolean;
  showFullName?: boolean;
}

function RegionSelector(props: RegionSelectorProps) {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();
  const [selectedRegion, setSelectedRegion] = useRegionStorage();
  const { data: regions } = useRegions();

  const { showLabel = false, showFullName = false } = props;


  useEffect(() => {
    if(!selectedRegion && regions) {
      setSelectedRegion(regions.find((r) => r.shortName === "UK"));
    }
  }, [regions, selectedRegion, setSelectedRegion])

  async function handleRegionChange(event: SelectChangeEvent<number>) {
    event.preventDefault();
    apiClient.clearPortfoliosCache();
    setSelectedRegion(regions?.find((r) => r.id === Number(event.target.value)));
    await queryClient.invalidateQueries();
  }

  if (!regions || !selectedRegion) {
    return (
      <Box display="flex" justifyContent="center" data-testid="regionSelector">
        <CircularProgress role="progress-icon" />
      </Box>
    );
  }

  return (
    <FormControl
      variant={showLabel ? "outlined" : "standard"}
      fullWidth={showLabel}
      style={{
        display: "flex",
        marginTop: "auto",
        marginBottom: "auto"
      }}
      data-testid="regionSelector"
    >
      {showLabel && <InputLabel htmlFor="region-select">Region</InputLabel>}
      <Select
        id="region-select"
        label="Region"
        value={selectedRegion.id}
        onChange={handleRegionChange}
        displayEmpty
        inputProps={{ "aria-label": "select region" }}
      >
        {regions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {showFullName ? item.longName : item.shortName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RegionSelector;
