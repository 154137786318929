import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  statusCell: {
    border: "1px solid rgb(250, 250, 250)",
    textAlign: "center",
  },

  red: {
    backgroundColor: "lightcoral",
  },

  amber: {
    backgroundColor: "rgb(252, 199, 101)",
  },

  green: {
    backgroundColor: "lightgreen",
  },

  notapplicable: {
    backgroundColor: "darkgrey",
  },

  noStatus: {
    backgroundColor: "#f5f5f5",
  },

  notes: {
    padding: "0px",
  },

  typography: {
    padding: theme.spacing(2),
  },

  styleNotes: {
    borderBottomRightRadius: "50%",
    cursor: "pointer",
  },

  noteText: {
    whiteSpace: "pre-line",
    textAlign: "justify",
  },
}));

export default function StatusCell({ color, notes, title }) {
  const classes = useStyles();

  const status = {};
  switch (color) {
    case "red":
      status.colorClass = classes.red;
      status.text = "R";
      break;
    case "amber":
      status.colorClass = classes.amber;
      status.text = "A";
      break;
    case "green":
      status.colorClass = classes.green;
      status.text = "G";
      break;
    case "notapplicable":
      status.colorClass = classes.notapplicable;
      status.text = "N";
      break;
    default:
      status.colorClass = classes.noStatus;
      break;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasNotes = notes && notes.trim() !== "";
  const open = Boolean(hasNotes && anchorEl);

  // const setPathColor = () => {
  //   console.log(color);
  //   if (
  //     color === "notapplicable" ||
  //     color === "unknown" ||
  //     color === null ||
  //     !color
  //   ) {
  //     if (rowNumber % 2 === 0) {
  //       return "unsubmitted";
  //     }
  //     return "white";
  //   }
  //   return color;
  // };
  // const pathColor = setPathColor(color);
  return (
    <React.Fragment>
      <TableCell
        className={
          classes.statusCell +
          " " +
          status.colorClass +
          " " +
          (hasNotes && classes.styleNotes)
        }
        data-testid={color}
        onClick={handleClick}
      >
        <div>{status.text}</div>
      </TableCell>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="create-dialog-title">{`Notes for ${title}`}</DialogTitle>
        <Divider variant="middle" />
        <Box margin={1} />
        <DialogContent>
          <Typography className={classes.noteText}>{notes}</Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
