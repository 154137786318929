import {
  Box,
  Container,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import UsersTable from "./UsersTable";
import UserSummary from "./UsersSummary";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  weekEndingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  weekEnding: {
    backgroundColor: "#003D4F",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  weekEndingText: {
    fontFamily: "Bitter",
  },
});
export default function UsersPage() {
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const apiClient = useContext(ApiClientContext);

  const fetchUsers = useCallback(
    async () =>
      await apiClient.getUsers().then((data) => {
        setUsers(data.users);
      }),
    [apiClient],
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Grid
      style={{ marginTop: "20px" }}
      container
      spacing={2}
      direction="row"
      alignItems="stretch"
    >
      <Box m={3} />
      <Grid item xs={12}>
        <Container maxWidth="lg">
          <Grid item xs={12}>
            <Box padding={2} className={classes.weekEnding}>
              <div className={classes.weekEndingContainer}>
                <Typography variant="h5" className={classes.weekEndingText}>
                  Users
                </Typography>
              </div>
            </Box>
          </Grid>

          {users && users.length > 0 ? (
            <>
              <UserSummary users={users} />
              <UsersTable users={users} />
            </>
          ) : (
            <Box mt={20}>
              <Typography variant="h4" align="center">
                <CircularProgress />
                <Box m={1} />
                Loading
              </Typography>
            </Box>
          )}
        </Container>
      </Grid>
    </Grid>
  );
}
