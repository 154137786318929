import { Typography } from "@mui/material";
import { formatBarChartDate } from "../../../../../shared/utils/dateUtils";

interface BarChartTrendHeaderProps {
    count: number;
    label: string;
    date: string;
  }

export default function BarChartTrendHeader({ count, label, date }: BarChartTrendHeaderProps) {

  return (
      <Typography variant="body2" sx={{ color: "#003D4F" }}>
        {label} | {count} projects{" "}
        <span
          style={{
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          on {formatBarChartDate(date)}
        </span>
      </Typography>
    );
  }
