import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AddAlert } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DownloadIcon from "@mui/icons-material/Download";
import { ApiClientContext } from "../../ApiClient/context";
import ProjectStatusTable from "../../Components/ProjectStatusTable";
import ReportSummary from "../../Components/ReportSummary";
import DisplayMessage from "../../Components/DisplayMessage";
import ProjectDetailView from "../../Components/ProjectDetailView";
import CopyPendingReportEmailsButton from "../../Components/CopyPendingReportEmailsButton";
import { useNavigate } from "react-router-dom";
import RetrievalError from "../../Components/RetrievalError";
import { grey } from "@material-ui/core/colors";
import config from "../../config";
import EmailReminder from "./components/EmailReminderModal";
import { useRegionStorage } from "../../shared/hooks/useRegionStorage";
import { formatDate } from "../../shared/utils/dateUtils";
import useIsDeliveryDirector from "../../shared/hooks/useIsDeliveryDirector";
import usePortfolios from "../../shared/hooks/usePortfolios";
import useAvailableDeliveryReportDates from "../../shared/hooks/useAvailableDeliveryReportDates";
import useDeliveryReportsSummary from "../../shared/hooks/useDeliveryReportsSummary";
import { isEmptyObject } from "../../shared/utils/object";
import CycleOpenEmailReminder from "./components/CycleOpenEmailReminderModal";
import DashboardFilter from "../../shared/constants/DashboardFilter";

const useStyles = makeStyles((theme) => ({
  sendReminderButton: {
    color: theme.palette.getContrastText(theme.palette.success.main),
    background: theme.palette.success.main,
  },
  downloadReportsButton: {
    color: theme.palette.getContrastText(theme.palette.info.light),
    background: theme.palette.info.light,
  },
  downloadRisksButton: {
    color: theme.palette.getContrastText(theme.palette.info.light),
    background: theme.palette.info.light,
  },
  clipboardButton: {
    color: theme.palette.getContrastText(theme.palette.info.light),
    background: theme.palette.info.light,
  },
  weekEndingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  weekEnding: {
    backgroundColor: "#003D4F",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  weekEndingText: {
    fontFamily: "Bitter",
  },
  calendarIcon: {
    margin: "0 10px 6px 0",
  },
  selectDropdown: {
    color: "white",
    backgroundColor: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      border: "0 0 2px 0",
    },
  },
  test: {
    backgroundColor: "#EDF1F3",
    padding: "2rem",
  },
}));

export default function DeliveryReportPage() {
  let { type, id } = useParams(); 
  const [selectedReportDate, setSelectedReportDate] = useState("");
  const [filterRagStatus, setFilterRagStatus] = useState("ALL");
  const [openEmailReminderModal, setOpenEmailReminderModal] = useState(false);
  const [openCycleOpenEmailReminderModal, setOpenCycleOpenEmailReminderModal] =
    useState(false);
  const [apiMessage, setApiMessage] = useState({});

  const apiClient = useContext(ApiClientContext);
  const [totalsAndProjects, setTotalsAndProjects] = useState({});
  const [nonInternalTotalsAndProjects, setNonInternalTotalsAndProjects] =
    useState({});
  const [nonInternalDeliveredProjects, setNonInternalDeliveredProjects] =
    useState({});
  const [isApiError, setIsApiError] = useState(false);
  const [openProjectId, setOpenProjectId] = useState(undefined);

  const navigate = useNavigate();
  const [region] = useRegionStorage();
  const isDeliveryDirector = useIsDeliveryDirector();
  const portfolios = usePortfolios();
  const availableReportDates = useAvailableDeliveryReportDates();
  const deliveryReportsSummary = useDeliveryReportsSummary(selectedReportDate);

  const storageFilter = localStorage.getItem("filter");
  const selectedFilter = storageFilter
      ? JSON.parse(storageFilter): DashboardFilter.Region;
  const fetchReportsAndTotals = useCallback(async () => {
    const data = deliveryReportsSummary?.data?.totalsAndProjects;
    if (selectedReportDate) {
      try {
        const reportData = data.reports.filter(
          (report) => report.portfolio !== "TW Internal Projects",
        );
        setTotalsAndProjects(data);
        setNonInternalTotalsAndProjects(reportData);
        const nonInternalDeliveredFilteredData = data.reports.filter(
          (report) =>
            report.report?.attributes.overall?.status !== "UNKNOWN" &&
            report.portfolio !== "TW Internal Projects",
        );
        setNonInternalDeliveredProjects(nonInternalDeliveredFilteredData);
        setApiMessage({ type: "success", text: "Report loaded" });
      } catch {
        setIsApiError(true);
      } finally {
        setIsApiError(false);
      }
    }
  }, [deliveryReportsSummary.data?.totalsAndProjects, selectedReportDate]);

  useEffect(() => {
    fetchReportsAndTotals();
  }, [
    deliveryReportsSummary.data?.totalsAndProjects,
    fetchReportsAndTotals,
    selectedReportDate,
  ]);

  useEffect(() => {
    availableReportDates.data &&
      setSelectedReportDate(availableReportDates.data[0] ?? "");
  }, [availableReportDates.data]);

  useEffect(() => {
    const projectId =
      (type === "project" && parseInt(id)) ||
      undefined;
    setOpenProjectId(projectId);
  }, [type, id]);

  const isError =
    isDeliveryDirector.isError ||
    portfolios.isError ||
    availableReportDates.isError;
  const loaded =
    availableReportDates.data?.length > 0 &&
    portfolios.data?.length > 0 &&
    !isEmptyObject(totalsAndProjects) &&
    !deliveryReportsSummary.isFetching;

  function fixStatus() {
    const newOverall = totalsAndProjects.reports.reduce(
      (acc, report) => {
        const status = report.report.attributes.overall.status;
        acc.rag[status === "UNKNOWN" ? "UNKNOWN" : status]++;
        return acc;
      },
      {
        change: { DETERIORATED: 0, STATIONARY: 0, IMPROVED: 0, UNKNOWN: 0 },
        rag: { RED: 0, AMBER: 0, GREEN: 0, UNKNOWN: 0 },
      },
    );

    newOverall.change = totalsAndProjects.total.overall.change;
    return newOverall;
  }

  const classes = useStyles();

  return (
    <>
      <ProjectDetailView
        id={openProjectId}
        selectedReportDate={selectedReportDate}
        open={openProjectId !== undefined}
        onClose={() => {
          navigate("/delivery-report");
        }}
        onUpdate={fetchReportsAndTotals}
      />
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Box m={3} />
        {isError || isApiError ? (
          <RetrievalError
            message="Could not fetch delivery report"
            path="/delivery-report"
            actionText="Try again"
          />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginLeft: "3rem", marginTop: "1.5rem" }}
              >
                <Grid item>
                  <Grid container spacing={2}>
                    {config.featureToggles.reminderEmail &&
                      isDeliveryDirector.data && (
                        <Grid item>
                          <Button
                            className={classes.sendReminderButton}
                            color="primary"
                            onClick={() => setOpenEmailReminderModal(true)}
                            size={"large"}
                            startIcon={<AddAlert />}
                          >
                            Send Reminder
                          </Button>
                        </Grid>
                      )}
                    {config.featureToggles.reminderEmail &&
                      isDeliveryDirector.data &&
                      region.shortName === "SEAANZ" && (
                        <Grid item>
                          <Button
                            className={classes.sendReminderButton}
                            color="primary"
                            onClick={() =>
                              setOpenCycleOpenEmailReminderModal(true)
                            }
                            size={"large"}
                            startIcon={<AddAlert />}
                          >
                            Send Cycle Open Reminder
                          </Button>
                        </Grid>
                      )}
                    <Grid item>
                      {isDeliveryDirector.data && (
                        <Button
                          className={classes.downloadReportsButton}
                          onClick={() => {
                            apiClient.downloadAllDeliveryReport();
                          }}
                          size={"large"}
                          startIcon={<DownloadIcon />}
                        >
                          Download All Reports
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {isDeliveryDirector.data && (
                        <Button
                          className={classes.downloadRisksButton}
                          onClick={() => {
                            apiClient.downloadRegionRisksReport();
                          }}
                          size={"large"}
                          startIcon={<DownloadIcon />}
                        >
                          Download All Risks
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {isDeliveryDirector.data && (
                        <CopyPendingReportEmailsButton
                          classes={classes.clipboardButton}
                          onSuccess={() => {
                            setApiMessage({
                              type: "success",
                              text: "Copied to Clipboard",
                            });
                          }}
                          onError={() => {
                            setApiMessage({
                              type: "error",
                              text: "Something went wrong.",
                            });
                          }}
                          size={"large"}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.test} elevation={0} square>
                <Container maxWidth="lg">
                  <Grid item xs={12}>
                    {!loaded && (
                      <Typography
                        variant="h4"
                        align="center"
                        style={{ color: grey[500] }}
                      >
                        <CircularProgress role="progress-icon" />
                        <Box m={1} />
                        Loading
                      </Typography>
                    )}
                    {Array.isArray(availableReportDates.data) &&
                      availableReportDates.data.length > 0 &&
                      loaded && (
                        <Paper style={{ backgroundColor: "white" }}>
                          <Box padding={2} className={classes.weekEnding}>
                            <div className={classes.weekEndingContainer}>
                              <DateRangeIcon className={classes.calendarIcon} />
                              <Typography
                                variant="h5"
                                className={classes.weekEndingText}
                              >
                                Period Ending
                              </Typography>
                            </div>
                            <Select
                              value={selectedReportDate}
                              variant="standard"
                              onChange={(e) =>
                                setSelectedReportDate(e.target.value)
                              }
                              className={classes.selectDropdown}
                              IconComponent={() => (
                                <img
                                  src="/icons/expandDropdown.svg"
                                  alt="Period Ending date dropdown"
                                />
                              )}
                              disableUnderline
                              style={{ fontSize: "1.5rem" }}
                            >
                              {availableReportDates.data.map((report) => (
                                <MenuItem key={report} value={report}>
                                  {formatDate(report)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Paper>
                      )}
                  </Grid>
                  {totalsAndProjects &&
                    totalsAndProjects.total &&
                    totalsAndProjects.total.overall &&
                    loaded && (
                      <ReportSummary
                        ragFilter={filterRagStatus}
                        setRagFilter={setFilterRagStatus}
                        overallStatus={fixStatus()}
                        nonInternalActiveProjects={
                          nonInternalTotalsAndProjects.length
                        }
                        nonInternalDeliveredProjects={
                          nonInternalDeliveredProjects.length
                        }
                        region={region.shortName}
                      />
                    )}
                </Container>
              </Paper>
            </Grid>
            {totalsAndProjects && totalsAndProjects.reports && loaded && (
              <Container maxWidth="lg">
                {selectedFilter === DashboardFilter.Market &&
                    <ProjectStatusTable
                    filterRagStatus={filterRagStatus}
                    projects={totalsAndProjects.reports}
                    setOpenProjectId={setOpenProjectId}
                    title= 'All Projects'/>
                }
                {selectedFilter === DashboardFilter.Region && portfolios?.data &&
                  portfolios.data.map((portfolio) => (
                    <Grid item key={portfolio.name}>
                      <ProjectStatusTable
                        filterRagStatus={filterRagStatus}
                        projects={totalsAndProjects.reports.filter(
                          (projectReport) =>
                            projectReport.portfolio === portfolio.name,
                        )}
                        setOpenProjectId={setOpenProjectId}
                        title={portfolio.name}
                      />
                    </Grid>
                  ))}
                {selectedFilter === DashboardFilter.Region &&
                <Grid item xs={12}>
                  <ProjectStatusTable
                    filterRagStatus={filterRagStatus}
                    projects={totalsAndProjects.reports.filter(
                      (projectReport) =>
                        !portfolios.data.some(
                          (p) => p.name === projectReport.portfolio,
                        ),
                    )}
                    setOpenProjectId={setOpenProjectId}
                    title="No Portfolio"
                  />
                </Grid> }
              </Container>
            )}
          </>
        )}
      </Grid>
      <DisplayMessage message={apiMessage} onClose={() => setApiMessage({})} />
      <EmailReminder
        isOpen={openEmailReminderModal}
        onClose={() => setOpenEmailReminderModal(false)}
      />
      <CycleOpenEmailReminder
        isOpen={openCycleOpenEmailReminderModal}
        onClose={() => setOpenCycleOpenEmailReminderModal(false)}
      />
    </>
  );
}
