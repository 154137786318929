import { useContext } from "react";
import { ApiClientContext } from "../../ApiClient/context";
import { useQuery } from "@tanstack/react-query";
import { formatBarChartDate } from "../utils/dateUtils";
import Status from "../types/Status";
import {
  BarChartData,
  ReportPortfolio,
  TotalsItem,
} from "../../shared/types/Interfaces";

interface AllReports {
  weekEnding: string;
  deliveryTrend: DeliveryTrend;
}
interface DeliveryTrend {
  portfolios: ReportPortfolio[];
  total: Record<string, TotalsItem>;
}

interface AvailableDeliveryReports {
  allProjects: BarChartData[];
  allPortfolios: BarChartData[][];
  allRiskCriteria: Record<string, BarChartData>[];
  allPortfolioRiskCriteria: Record<string, BarChartData>[];
}

function getAllProjects(allReports: AllReports[]) {
  return allReports.reverse().map(({ weekEnding, deliveryTrend }) => {
    const rag = deliveryTrend.total?.overall.rag ?? {};
    return createChartData(rag, weekEnding);
  });
}

function getAllPortfolios(allReports: AllReports[]) {
  return allReports.map(({ weekEnding, deliveryTrend }) => {
    const portfolios = deliveryTrend.portfolios;
    return portfolios?.map((portfolio) => {
      const rag = portfolio.total.overall.rag;
      return createChartData(rag, weekEnding, portfolio.portfolioId);
    });
  });
}

function getPortfolioRiskCriteria(allReports: AllReports[]) {
  return allReports.map(({ weekEnding, deliveryTrend }) => {
    const portfolios = deliveryTrend.portfolios ?? [];
    return portfolios?.map((portfolio) => {
      const portfolioTotals = portfolio.total;
      return createRiskCriteria(
        portfolioTotals,
        weekEnding,
        portfolio.portfolioId
      );
    });
  });
}

function getAllRiskCriteria(allReports: AllReports[]) {
  return allReports.reverse().map(({ weekEnding, deliveryTrend }) => {
    const total = deliveryTrend.total ?? {};
    return createRiskCriteria(total, weekEnding);
  });
}

function createRiskCriteria(
  total: Record<string, TotalsItem>,
  weekEnding: string,
  portfolioId?: number
) {
  return Object.entries(total)
    .filter(([key]) => key !== "overall")
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: createChartData(value.rag, weekEnding, portfolioId),
      }),
      {}
    );
}

function createChartData(
  rag: Record<Status, number>,
  weekEnding: string,
  portfolioId?: number
) {
  return {
    portfolioId,
    date: formatBarChartDate(weekEnding),
    red: calculateRagPercentage(rag, rag.RED),
    green: calculateRagPercentage(rag, rag.GREEN),
    amber: calculateRagPercentage(rag, rag.AMBER),
    notApplicable: calculateRagPercentage(rag, rag.NOTAPPLICABLE),
    unknown: calculateRagPercentage(rag, rag.UNKNOWN),
  };
}

function countTotalProjects(rag: Record<Status, number>) {
  return Object.entries(rag).reduce((acc, [, value]) => acc + value, 0);
}

function calculateRagPercentage(rag: Record<Status, number>, ragCount = 0) {
  const totalProjects = countTotalProjects(rag);
  const ragPercentage =
    totalProjects === 0 ? 0 : (ragCount / totalProjects) * 100;
  return Math.floor(ragPercentage);
}

const useDeliveryTrendsReports = () => {
  const apiClient = useContext(ApiClientContext);

  return useQuery<AvailableDeliveryReports>({
    queryKey: ["availableDeliveryReports"],
    queryFn: async () => {
      const data = await apiClient.getDeliveryTrendsReports();

      return {
        allProjects: data ? getAllProjects(data) : [],
        allPortfolios: data ? getAllPortfolios(data) : [],
        allRiskCriteria: data ? getAllRiskCriteria(data) : [],
        allPortfolioRiskCriteria: data
          ? getPortfolioRiskCriteria(data).flat()
          : []
      };
    },
  });
};

export default useDeliveryTrendsReports;
