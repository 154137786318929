import React from "react";
import {Typography, Tooltip} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import TrafficIcon from '@mui/icons-material/Traffic';

const useStyles = makeStyles({
  red: {
    color: "lightcoral",
  },
  amber: {
    color: "rgb(252, 199, 101)",
  },
  green: {
    color: "lightgreen",
  },
  unknown: {
    color: "grey",
  },
});

export default function ViewMetric({ children }) {
  const classes = useStyles();

  const getTrafficLightClass = (metric) => {
    return metric?.status === "RED"
      ? classes.red
      : metric?.status === "AMBER"
      ? classes.amber
      : metric?.status === "GREEN"
      ? classes.green
      : classes.unknown;
  };

  return (
    <>
      <Typography variant="h6">{children?.title}</Typography>
      <Tooltip title={children?.status?.toLowerCase() ?? "unknown"}>
        <TrafficIcon data-testid="traffic" className={getTrafficLightClass(children)}/>
      </Tooltip>
      {children?.notes &&
        children?.notes.split(/\r?\n/).map((paragraphy, i) => (
          <Typography
            style={{ wordWrap: "break-word" }}
            key={i}
            variant="body1"
          >
            {paragraphy}
          </Typography>
        ))}
    </>
  );
}
