const emailNameRegex = /^[-a-zA-Z0-9.]+$/i;
const isEmailNameLegal = (emailName) => emailNameRegex.test(emailName);

const isEmailValid = (email, reporterList) => {
  if (email === "") {
    return {
      error: true,
      errorMsg: "*Required",
    };
  }

  const emailNameRegex = /^[-a-zA-Z0-9.@]+$/i;
  const isEmailNameLegal = emailNameRegex.test(email);

  if (!isEmailNameLegal) {
    return {
      error: true,
      errorMsg: "*Illegal Character",
    };
  }

  return { error: false, errorMsg: "" };
};

const areAllEmailsValid = (email, reporterList) => {
  const thoughtworksDomain = "@thoughtworks.com";
  const emailCount = reporterList?.filter(
    (reporter) => reporter.email.replace(thoughtworksDomain, "") === email
  )?.length;
  return emailCount > 1;
};

const isNameValid = (name) => {
  if (name === "") {
    return {
      error: true,
      errorMsg: "*Required",
    };
  }

  const nameRegex = /^[a-zA-Z.,\-' ]+$/i;
  const isNameLegal = nameRegex.test(name);

  if (!isNameLegal) {
    return {
      error: true,
      errorMsg: "*Illegal Character",
    };
  }

  return { error: false, errorMsg: "" };
};

export {
  emailNameRegex,
  isEmailNameLegal,
  isNameValid,
  isEmailValid,
  areAllEmailsValid,
};
