import React from "react";
import { Chip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

const Artefact = ({ artefact, onDelete }) => {
  return (
    <>
      {artefact.id && (
        <Chip
          style={{ margin: "5px" }}
          label={
            <a
              href={artefact.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "inherit",
                textDecoration: "none",
                padding: "5px",
              }}
            >
              {artefact.label}
            </a>
          }
          aria-label="artefact-chip"
          deleteIcon={
            <Cancel aria-label={`Delete ${artefact.label} Technology Chip`} />
          }
          onDelete={() => onDelete(parseInt(artefact.id))}
        />
      )}
    </>
  );
};

export default Artefact;
