import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {BarChartData} from "../../../../shared/types/Interfaces";

interface StatusBarChartProps {
  angle?: boolean;
  barChartData?: BarChartData[];
}

const StatusBarChart = ({ angle, barChartData }: StatusBarChartProps) => {
  return (
    <ResponsiveContainer aspect={1.5} width={"100%"} maxHeight={320}>
      <BarChart
        data={barChartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        barSize={60}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          interval={0}
          style={{ fontSize: angle ? "10px" : "14px" }}
          angle={angle ? -35 : 0}
          dx={-5}
          dy={5}
        />
        <YAxis
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          label={{
            value: "Percent of projects",
            angle: -90,
            position: "insideLeft",
            style: { textAnchor: "middle" },
            fill: "black",
          }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
        />
        <Legend iconType="circle" iconSize={22} />

        <Bar name="N/A" dataKey="notApplicable" stackId="a" fill="darkgrey" />
        <Bar
          name="Not submitted"
          dataKey="unknown"
          stackId="a"
          fill="#686c6f"
        />
        <Bar name="Green" dataKey="green" stackId="a" fill="#2CB240" />
        <Bar name="Amber" dataKey="amber" stackId="a" fill="#FEBC2E" />
        <Bar name="Red" dataKey="red" stackId="a" fill="#FF5F57" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StatusBarChart;
