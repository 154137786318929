import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import StatusBarChart from "../../StatusBarChart/StatusBarChart";
import BarChartTrendHeader from "./BarChartTrendHeader";
import { useRegionStorage } from "../../../../../shared/hooks/useRegionStorage";
import { regions } from "../../../../../shared/constants";
import {
  BarChartData,
  PortfolioDeliveryReport,
} from "../../../../../shared/types/Interfaces";
import RiskCriteriaFilter from "./RiskCriteriaFilter";

interface RiskCriteriaTrendsProps {
  riskCriteria?: Record<string, BarChartData>[];
  weekEnding: string;
  activeClientProjects: number | undefined;
  portfoliosRiskCriteria: Record<string, BarChartData>[] | undefined;
  portfolioDeliveryReport?: PortfolioDeliveryReport[];
}

export default function RiskCriteriaTrends({
  riskCriteria,
  weekEnding,
  activeClientProjects,
  portfoliosRiskCriteria,
  portfolioDeliveryReport,
}: RiskCriteriaTrendsProps) {
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(0);
  const [region] = useRegionStorage();

  const handlePortfolioSelections = (portfolioId: number) => {
    setSelectedPortfolioId(portfolioId);
  };

  const portfolio = portfolioDeliveryReport?.find(
    (portfolio) => portfolio.id === selectedPortfolioId
  );

  const portfolioCount = portfolio
                    ? portfolio.overall.projectsCount
                    : activeClientProjects;
  return (
    <Grid container item xs={12} spacing="16px">
      <Grid item xs={12} lg={12}>
        <Box>
          <Typography variant="h2" sx={{ color: "#003D4F" }}>
            Risk criteria
          </Typography>
        </Box>
        <RiskCriteriaFilter
          setSelectedPortfolioId={(selectedPortfolioId) =>
            handlePortfolioSelections(selectedPortfolioId)
          }
          portfolioDeliveryReport={portfolioDeliveryReport}

        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <Box>
          <Grid container>
            {region &&
              regions[region.shortName]?.categories
                ?.filter((category) => category.value !== "overall")
                .map((category) => {
                  const riskCategory = riskCriteria?.map(
                    (criteria) => criteria[category.value]
                  );
                  const selectedPortfolio = portfoliosRiskCriteria
                    ?.map((criteria) => criteria[category.value])
                    .filter((el) => el?.portfolioId === selectedPortfolioId);
                  return (
                    riskCategory && (
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        key={category.value}
                        sx={{
                          backgroundColor: "#EEF1F3",
                          borderRadius: "12px",
                          padding: "24px",
                        }}
                      >
                        {
                          <BarChartTrendHeader
                            label={category.name}
                            count={portfolioCount ?? 0}
                            date={weekEnding}
                          />
                        }
                        <Grid item xs={12}>
                          <Grid
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "12px",
                              padding: "24px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <StatusBarChart
                              barChartData={
                                selectedPortfolioId !== 0
                                  ? selectedPortfolio?.reverse()
                                  : riskCategory.reverse()
                              }
                              angle={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  );
                })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
