import React from "react";
import { Grid, InputLabel, Typography } from "@material-ui/core";

export default function ProjectAttributes(props) {
  return (
    <Grid item md={props.gridValue}>
      <InputLabel
        htmlFor={props.htmlFor}
        style={{ fontWeight: "bolder", lineHeight: 1.5 }}
      >
        {props.title}
      </InputLabel>
      {props.value ? (
        props.isMultiline ? (
          props.value
            .split(/\r?\n/)
            .map((paragraphy, i) => printPara(paragraphy, i))
        ) : (
          <Typography>{props.value}</Typography>
        )
      ) : (
        <></>
      )}
    </Grid>
  );

  function printPara(paragraphy, i) {
    if (paragraphy === "") {
      return <br />;
    }
    return <Typography key={i}>{paragraphy}</Typography>;
  }
}
