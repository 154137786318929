import React from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useRegionStorage } from "../../../shared/hooks/useRegionStorage";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const UK = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Overall Status",
    "On track to deliver successfully and all key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to be identified and executed to bring project back on track for delivery.",
    "No agreed plan to resolve issues which are impacting successful delivery.",
  ),
  createData(
    "Plan",
    "Project is on track.",
    "Project is behind schedule and/or target end date is at risk.  Actions in place to bring project back on track.",
    "Project is behind schedule and/or target end date is now not achievable. ",
  ),
  createData(
    "Team Mix",
    "All core team roles are staffed appropriately and the profile of the rest of the team is correctly balanced.",
    "All core team roles are staffed appropriately. The profile of the rest of the team is out of balance likely to lead to delivery issues.",
    "Some or all of the core team roles are not staffed appropriately and/or the rest of the team is out of balance leading to delivery issues.",
  ),
  createData(
    "Team Morale",
    `Overall team morale is good.
    Development plans for each person are in place.
    Rotation plans are in place and agreed with our people, the client and Staffing team.`,
    `Team morale could be better. Some individuals are not happy.
    Plan in place to address morale.
    Development plans are WIP.
    Rotation plans for some members are in place and agreed with the Staffing team.`,
    `Team morale is impacting delivery and the value we deliver to the client or we have a risk of attrition. or
    Development plans not agreed. or
    Rotation plans not in place and/or not agreed with the Staffing team.`,
  ),
  createData(
    "Dependencies",
    "External dependencies are known and plans in place and being managed.",
    "Likely that key dependencies will negatively impact the delivery plan.  Actions in place to mitigate this.",
    "Key dependencies are unknown and/or key dependencies will not be delivered on time impacting the delivery plan.",
  ),
  createData(
    "Technology",
    "No significant issues with architecture, security, infrastructure, deployment pipeline, CI or other technology being used.",
    "One or more issues with architecture, security, infrastructure, deployment pipeline, CI or other technology.  Action plans being actively managed.",
    "One or more significant issues with the architecture, security, infrastructure, deployment pipeline, CI or other technology which is having a negative impact on the Project outcomes.",
  ),
  createData(
    "Commercial",
    "Actual margin matches planned margin (defined during Deal Review process).  No outstanding invoices.  Actual costs are on track with planned costs",
    "Actual margin is + or - 2.5% of the planned margin.  Outstanding invoices of a value of less than £50,000.  Actual costs are up to 5% greater than planned",
    "Actual margin is greater than + or - 2.5% of the planned margin. Outstanding invoices of a value > £50,000.  Actual costs are >5% greater than planned",
  ),
  createData(
    "Legal",
    "Mutually Signed Contract",
    "Existing client, contract reviewed and approved in deal review, but work started without a signed contract. ",
    "Work started and no signed contract and either:  i) new client; or  ii) no paperwork deal reviewed (regardless of whether a new or existing client); or iii) paperwork reviewed and have been working for more than 4 weeks (regardless of whether a new or existing client)",
  ),
  createData(
    "Continuous Delivery",
    "Environment creation is automated. Full control of necessary team deployment environments. Code deployable from Dev into live/prod, through full test suite on request by/with the business. Ability to do releases on demand.",
    "Environment creation is automated. Code deployable from Dev into live/prod, through full test suite on request by/with the business. Last release within the past month. Able to deploy at least weekly.",
    "Environment creation is not automated. Code not yet deployed into live/prod. More than one month since last release to live/prod.",
    "Not a delivery project. If in doubt please check with Portfolio Director",
  ),
  createData(
    "InfoSec",
    `All of the following are true:

    -Advisory-only type of engagement OR project is less than 2 weeks old
    - No access to Client sensitive information, tools or systems
    -Team is aware of, and fulfills, all Client security expectations with no open risks or issues.

    OR
    All of the following are true:

    - There is an Incident Response Plan for each stream of work
    - Security is part of the on/off boarding checklist (using the InfoSec template), and implemented
    - No known security risks or issues (or they've been accepted by the client, or TW)
    - Have done threat modeling in the past 4 months
    - The team has read through, and follows, relevant client security policies
    -The team has read through, and follows, the relevant TW Project Protection guidance
    https://docs.google.com/document/d/1ZpsYz7jguLhSRaZzeckT3vvmyV8YS-F2qk0qCxix4vg/edit`,
    `One or more of the following are true:

    - There is an Incident Response Plan, but most people aren't aware of it
    - There is an on/offboarding checklist, but security is not an aspect
    -Have done threat modeling more than 4 months ago
    - Medium (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Client security policies and expectations are available, but the team is not familiar, or cannot follow some of them
    -Team is not aware, or cannot align with several, of the relevant TW Project Protection guidance
    - No security champion in some teams`,
    `One or more of the following are true:

    - We have no Incident Response Plan
    - We have no on/offboarding checklist
    - We have no past threat modeling, or plans for one in the near future
    - High (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    -Team is not aware, or cannot align with most, of the relevant TW Project Protection guidance
    -Team is not aware, or cannot align with several, of the Client security policies and expectations
    -No security champion in the account`,
    `Infosec is done from another market due to 100% distributed delivery`,
  ),
];
const SEAANZ = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Overall Status",
    "On track to meet the engagement's desired outcomes successfully and all key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to be identified and executed to bring project back on track.",
    "No agreed plan to resolve issues which are impacting the team's ability to meet the engagement's desired outcomes.",
    "The engagement is less than two weeks old.",
  ),
  createData(
    "Plan",
    "The team is on track to meet the engagement's desired outcomes as per the current plan.",
    "The project is behind schedule and/or target end-date is at risk. Actions are in place to bring the project back on track.",
    "The project is in delivery and there is no delivery plan OR the project is behind schedule and/or target end-date is now not achievable.",
    "The engagement is less than two weeks old.",
  ),
  createData(
    "Risk Exposure",
    "Key risks that has potential impact on the project are identified and managed successfully.",
    "Key risks are being managed but require clear owners or decisive actions to be identified and executed to bring project back on track for delivery.",
    "There is no mitigation plan to effectively manage the risks which are impacting successful delivery.",
    "The engagement is less than two weeks old.",
  ),
  createData(
    "Technology",
    `There are no significant issues with architecture, security, infrastructure, deployment pipeline, CI or other technology (the project technical vision).

        We are generally following TW engineering sensible defaults.`,
    `There are one or more minor issues with architecture, security, infrastructure, deployment pipeline, CI and/or other technology, but action plans are being actively managed.

        OR we are only able to follow roughly 50% of TW engineering sensible defaults.`,
    `There are one or more significant issues with the architecture, security, infrastructure, deployment pipeline, CI and/or other technology which is having a negative impact on the project's outcomes.

        OR we are receiving signfiicant push back on following TW engineering sensible defaults and need help influencing stakeholders.`,
    `The engagement is less than two weeks old, OR this is a Discover / Assessment / etc type of engagement without a technical component.`,
  ),
  createData(
    "Commercial & Legal",
    "We have signed paperwork (e.g. SOW) and invoices are produced and paid on time. Compliance with relevant laws and regulations is maintained, with no anticipated legal disputes.",
    `We do not have a signed SOW, but have a signed "Confirmed Work, Contract Pending" (CWCP, or what was previously called "Working At Risk") document. Invoices are being paid on time. There are minor compliance issues or emerging legal considerations but effectively monitored to prevent any impact on the project.`,
    "We do not have signed paperwork (i.e. SOW or CWCP) or invoices are not being paid on time. There are major compliance issues / legal challenges that are having a negative impact on the project. ",
  ),
  createData(
    "Customer Relationship",
    "We are able to have open and transparent conversations with with all key stakeholders and our opinion is actively sought when making new decisions. We are able to have difficult conversations without generating childish or rejecting behaviours. There are no major blockers or active detractors.",
    "We are not able to have open and transparent conversations with all key stakeholders. We hear about new decisions affecting the project after they have been made. Some relationships are fragile. We think there may be potential for blockers in our stakeholder group.",
    "We are not seen as partners, or trusted advisors. Difficult conversations are messy or, more likely, avoided. Client folk are active detractors.",
  ),
  createData(
    "Team Morale",
    "Team exhibits a strong sense of collaboration and ownership, creating a valued and continuously learning environment.",
    "Slight decrease in productivity due to low motivation or similar morale factors, OR lacking proactive communication, OR lacking colloboration and ownership in solving issues.",
    "Significant decrease in productivity due to low motivation or similar morale factors. The team is stressed, frustrated, and showing disengagement through more absences and unexpected rotation requests.",
  ),
  createData(
    "Team Mix",
    "All core team roles are staffed appropriately and the profile of the rest of the team is correctly balanced. ",
    "All core team roles are staffed appropriately, BUT the profile of the rest of the team is NOT correctly balanced (e.g. missing supporting roles, OR the leverage is not correct).",
    "Some or all of the core team roles are not staffed appropriately and/or the rest of the team is out of balance, leading to delivery issues.",
  ),
  createData(
    "InfoSec Tier One - Onboarding/IRP",
    `All of the following are true:

    - There is a Thoughtworks Security Incident Response Plan
    - There are on-boarding and off-boarding checklists covering security and accurate records of TWers having completed the items on the checklists
    - Client-specific or project-specific information security policies and handling requirements are well defined and are understood by the whole team`,
    `Any of the following are true:

    - There is a ThoughtWorks Security Incident Response Plan, most team members aren't aware of it
    - There are on-boarding and off-boarding checklists but security is not an aspect
    - Client-specific or project-specific information security policies and handling requirements are partially defined or are defined but not well understood`,
    `Any of the following are true:

    - There is no Security Incident Response Plan
    - There are no on-boarding or off-boarding checklists
    - Client-specific or project-specific information security policies and handling requirements are not defined`,
  ),
  createData(
    "InfoSec Tier Two - Risk Assessment/Project Security Risks",
    `All of the following are true:

    - The team is assessing the project against a baseline of security controls appropriate to the project's risk position, e.g. OWASP ASVS or the TW Secure Delivery Checklist
    - The project has undertaken some activities to identify security threats and vulnerabilities in the past 6 months, e.g. threat modelling, pen testing, security risk assessments, or other security testing (such as DAST, SAST, or exploratory testing)
    - All identified security risks and issues have mitigating actions (or they've been accepted by the client) - Work to address security risks is prioritised and completed effectively`,
    `Any of the following is true:

    - Security risks and issues are being managed sporadically or are out-of-date
    - Security risks and issues are being managed to the client's satisfaction, but not to ours
    - Some security risk or issues are missing mitigating actions (or they have not been accepted by the client)`,
    `Either of the following is true:

    - We have not assessed the security risks and issues for this project
    - There are known security risks or issues with no mitigating actions or agreed plan for resolution`,
    `The engagement is less than four weeks old, OR this is a Discover / Assessment / etc type of engagement without a technical component.`,
  ),
];
const NL = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Overall Status",
    "On track to deliver successfully and key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to bring project back to success path.",
    "No agreed plan to resolve issues that are preventing the project to succeed.",
  ),
  createData(
    "Plan",
    "Project is on track and progress meets expectations. Dependencies and risks are known and there are plans in place on how to manage. Team has a clear direction. ",
    "Project is behind schedule / target end date is at risk / team lacks clear direction. Action plan in place to bring project back on track or negotiate with client to adjust the expectations / clarify the direction.",
    "Project is behind schedule / target end date is not achievable / team lacks direction.",
  ),
  createData(
    "Staffing",
    "All key roles are staffed and the profile of the team is balanced.",
    "All key roles are staffed. The profile of the team is out of balance and might lead to delivery issues.",
    "Some of the key roles are unstaffed / profile of the team is out of balance leading to delivery issues.",
  ),
  createData(
    "Team Morale",
    "The morale of the team is good, majority of team members are happy.",
    "Some team members are unhappy / team dynamics is out of balance. Action plan in place but might lead to delivery issues.",
    "Several team members are unhappy / team dynamics is out of balance and it has negative impact on delivery.",
  ),
  createData(
    "Customer Relations",
    "Open and trusting relationship with key client stakeholders. Client is happy with the team and delivery.",
    "Limited access to / lack of transparency or trust with some of the key client stakeholders / client not fully satisfied with the team or delivery. Action plan in place to improve the client relationships.",
    "Lack of trust with many of the key client stakeholders / Some client stakeholders are active detractors.",
  ),
  createData(
    "Commercials & Legal",
    "Signed contract and contractual obligations in line with delivery plan. Actual costs are on track with planned costs.",
    "Work started without a signed contract / Contractual obligations are unclear or not fully inline with delivery plan / Actual costs are slightly greater than planned.",
    "Work has been on for some time already without a signed contract / Contractual obligations clearly not inline with delivery plan or severe violations to obligations / High commercial or brand image risk / Actual costs asignificantly greater than planned.",
  ),
  createData(
    "Technology",
    "No significant issues with the quality of our product/deliverables, architecture, infrastructure, deployment pipeline, CI or other technology being used.",
    "One or more issues with quality of our product / deliverables, architecture, security, infrastructure, deployment pipeline, CI or other technology.  Action plans being actively managed.",
    "One or more significant issues with the quality of our product/deliverables, architecture, security, infrastructure, deployment pipeline, CI or other technology which is having a negative impact on the project outcomes.",
  ),
  createData(
    "InfoSec",
    `If Low Bar Applies, All of the following are true:
    - There is a Thoughtworks Security Incident Response Plan
    - There are on-boarding and off-boarding checklists covering security and accurate records of TWers having completed the items on the checklists
    - Client-specific or project-specific information security policies and handling requirements are well defined and are understood by the whole team

    OR If Second Tier Applies, All of the following are true:
    - The team is assessing the project against a baseline of security controls appropriate to the project's risk position, e.g. OWASP ASVS or the TW Secure Delivery Checklist, including Data Protection
    - The project has undertaken some activities to identify security threats and vulnerabilities in the past 6 months, e.g. threat modelling, pen testing, security risk assessments, or other security testing (such as DAST, SAST, or exploratory testing)
    - All identified security risks and issues have mitigating actions (or they've been accepted by the client)
    - Work to address security risks is prioritised and completed effectively
    https://docs.google.com/document/d/1ZpsYz7jguLhSRaZzeckT3vvmyV8YS-F2qk0qCxix4vg/edit`,
    `If Low Bar Applies, Any of the following are true:
    - There is a ThoughtWorks Security Incident Response Plan, most team members aren't aware of it
    - There are on-boarding and off-boarding checklists but security is not an aspect
    - Client-specific or project-specific information security policies and handling requirements are partially defined or are defined but not well understood

    OR If Second Tier Applies, One or more of the following are true:
    - There is an Incident Response Plan, but most people aren't aware of it
    - There is an on/offboarding checklist, but security is not an aspect
    - Have done threat modeling more than 4 months ago
    - Medium (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Client security policies and expectations are available, but the team is not familiar, or cannot follow some of them
    - Team is not aware, or cannot align with several, of the relevant TW Project Protection guidance
    - No security champion in some teams
    `,
    `If Low Bar Applies, Any of the following are true:
    - There is no Security Incident Response Plan
    - There are no on-boarding or off-boarding checklists
    - Client-specific or project-specific information security policies and handling requirements are not defined

    OR If Second Tier Applies, One or more of the following are true:
    - We have no Incident Response Plan
    - We have no on/offboarding checklist
    - We have no past threat modeling, or plans for one in the near future
    - High (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Team is not aware, or cannot align with most, of the relevant TW Project Protection guidance
    - Team is not aware, or cannot align with several, of the Client security policies and expectations
    - No security champion in the account`,
  ),
  createData(
    "Future Business",
    "Talking about concrete additional or follow-up business with the client. Most likely our team will grow over the time.",
    "No additional or follow-up opportunities visible now, team most likely will shrink slightly.",
    "Project will end or team will shrink significantly in the next future. No additional opportunities visible or no client budget available.",
  ),
];

const DE = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Delivery Confidence",
    "On track to deliver successfully and all key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to be identified and executed to bring project back on track for delivery.",
    "No agreed plan to resolve issues which are impacting successful delivery.",
  ),
  createData(
    "Risk Exposure",
    "Risks are regularly being monitored, mitigations and contingencies identified, and shared with the client. There are no unmitigated 'High' risks and no 'Extreme' risks.",
    "Risks are regularly being monitored, mitigations and contingencies where possible identified, and shared with the client. There are unmitigated 'High' risks and/or mitigated 'Extreme' risks.",
    "Risks are not regularly being monitored, mitigations and contingencies are not or only partially identified, and not regularly shared with the client. There are unmitigated 'High' risks and / or 'Extreme' risks.",
  ),
  createData(
    "Plan",
    "Project is on track.",
    "Project is behind schedule and/or target end date is at risk. Actions in place to bring project back on track.",
    "Project is behind schedule and/or target end date is now not achievable.",
  ),
  createData(
    "Staffing",
    "All core team roles are staffed appropriately and the profile of the rest of the team is correctly balanced.",
    "All core team roles are staffed appropriately. The profile of the rest of the team is out of balance likely to lead to delivery issues.",
    "Some or all of the core team roles are not staffed appropriately and/or the rest of the team is out of balance leading to delivery issues.",
  ),
  createData(
    "Team Morale",
    "Overall team morale is good. Development plans for each person are in place. Rotation plans are in place and agreed with our people, the client and Staffing team.",
    "Team morale could be better. Some individuals are not happy. Plan in place to address morale. Development plans are WIP. Rotation plans for some members are in place and agreed with the Staffing team.",
    "Team morale is impacting delivery and the value we deliver to the client or we have a risk of attrition. or Development plans not agreed. or Rotation plans not in place and/or not agreed with the Staffing team.",
  ),
  createData(
    "Customer Relations",
    "Stakeholder management in place, trusted relationships to management level in the client organisation. Client is happy with ThoughtWorks project/delivery.",
    "No stakeholder management established yet. Limited access or no trustfull relationship to main stakeholders of our engagement. Customer not fully satiesfied (with or without ThoughtWorks being responsible) ThoughtWorks delivery.",
    "Influencial stakeholder acts as opponent to ThoughtWorks project. Client is seriously dissatisfied (with or without ThoughtWorks being responsible) with ThoughtWorks delivery causing the risk that our engagement can be stopped/reduced. No relationship in place to mitigate this risk.",
  ),
  createData(
    "Commercials",
    "Actual margin matches planned margin (defined during Deal Review process). No outstanding invoices. Actual costs are on track with planned costs.",
    "Actual margin is + or - 2.5% of the planned margin. Outstanding invoices of a value of less than £50,000. Actual costs are up to 5% greater than planned.",
    "Actual margin is greater than + or - 2.5% of the planned margin. Outstanding invoices of a value > £50,000. Actual costs are >5% greater than planned.",
  ),
  createData(
    "Legal",
    "Mutually Signed Contract.",
    "Existing client, contract reviewed and approved in deal review, but work started without a signed contract.",
    "Work started and no signed contract and either: i) new client; or ii) no paperwork deal reviewed (regardless of whether a new or existing client); or iii) paperwork reviewed and have been working for more than 4 weeks (regardless of whether a new or existing client).",
  ),
  createData(
    "Future Business",
    "Talking about concrete additional or follow-up business with the client. Most likely our team will grow over the time.",
    "No additional or follow-up opportunities visible now, team most likely will shrink slightly.",
    "Project will end or team will shrink significantly in the next future. No additional opportunities visible or no client budget available.",
  ),
  createData(
    "InfoSec",
    `All of the following are true:
    - Advisory-only type of engagement OR project is less than 2 weeks old
    - No access to Client sensitive information, tools or systems
    - Team is aware of, and fulfills, all Client security expectations with no open risks or issues

    OR All of the following are true:
    - There is an Incident Response Plan for each stream of work
    - Security is part of the on/off boarding checklist (using the InfoSec template), and implemented
    - No known security risks or issues (or they've been accepted by the client, or TW)
    - Have done threat modeling in the past 4 months
    - The team has read through, and follows, relevant client security policies
    - The team has read through, and follows, the relevant TW Project Protection guidance https://docs.google.com/document/d/1ZpsYz7jguLhSRaZzeckT3vvmyV8YS-F2qk0qCxix4vg/edit`,
    `One or more of the following are true:
    - There is an Incident Response Plan, but most people aren't aware of it
    - There is an on/offboarding checklist, but security is not an aspect
    - Have done threat modeling more than 4 months ago
    - Medium (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Client security policies and expectations are available, but the team is not familiar, or cannot follow some of them
    - Team is not aware, or cannot align with several, of the relevant TW Project Protection guidance
    - No security champion in some teams
    `,
    `One or more of the following are true:
    - We have no Incident Response Plan
    - We have no on/offboarding checklist
    - We have no past threat modeling, or plans for one in the near future
    - High (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Team is not aware, or cannot align with most, of the relevant TW Project Protection guidance
    - Team is not aware, or cannot align with several, of the Client security policies and expectations
    - No security champion in the account`,
    "Infosec is done from another market due to 100% distributed delivery",
  ),
  createData(
    "Business Value (being delivered)",
    "Outcome-oriented goals/objectives have been defined, communicated and agreed with all TWers (on the account / program) and the client. Team(s) (people working on the product, which could be TWers only or a mix of TWers and client personnel) have a clear definition of the organizational value they are working towards. I.e. a metrics tree/loop/funnel/node model exists and cost benefit analysis (CBA) is understood. Tracking and reporting of value delivered is continuous and periodic value reviews are in place with the client e.g. quarterly business review (QBR).",
    "Outcome-oriented goals/objectives have been defined and communicated, but they are not widely understood or agreed. Limited tracking is in place and ad-hoc value discussions take place with the client e.g. manual ad-hoc reporting and value discussions during other meetings/ceremonies.",
    "No clear business impact or product/project outcome has been defined and shared. No tracking or measurement of value is in place and no discussion about value with the client.",
    "Discovery or Assessment stage of an engagement, value is being co-defined with the client.",
  ),
];

const BR = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Overall Status",
    "On track to deliver successfully and all key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to be identified and executed to bring project back on track for delivery.",
    "No agreed plan to resolve issues which are impacting successful delivery.",
  ),
  createData(
    "Ways of working",
    "Project is on track. Run all the standard practices consistently.",
    "Most practices are implemented, there is a plan of action.",
    "The minimum of practices are implemented, there is no action plan.",
  ),
  createData(
    "Delivery",
    "Project is on track. External dependencies are known, project plans in place and being managed.",
    "Project is behind schedule, target end date is at risk or key dependencies will negatively impact the delivery plan.  Actions in place to mitigate and bring project back on track.",
    "Project is behind schedule, target end date is now not achievable, or key dependencies are unknown / will not be delivered on time, impacting the delivery plan.",
  ),
  createData(
    "Governance",
    "Project is on track. Progress meets expectation. External dependencies are known and plans in place and being managed. Delivery Sensible Defaults are applied. Quality of the solution (Product and Tech) is NOT being compromised.Team has a clear direction based pn Product vision/Roadmap and/or Delivery plan.",
    "Project is behind schedule and/or target end date is at risk. Likely that key dependencies will negatively impact the delivery plan. Actions in place to bring project back on track. Delivery risks are mitigated by reviewing and applying Delivery Sensible Defaults. Quality trade offs are likely to be made.",
    "Project is behind schedule and/or target end date is now not achievable. Key dependencies are unknown and/or key dependencies will not be delivered on time impacting the delivery plan. Delivery Sensible Defaults are NOT applied. Quality is being compramised.client.",
  ),
  createData(
    "Technology",
    "No significant issues with architecture, security, infrastructure, deployment pipeline, CI or other technology being used.",
    "One or more issues with architecture, security, infrastructure, deployment pipeline, CI or other technology.  Action plans being actively managed.",
    "One or more significant issues with the architecture, security, infrastructure, deployment pipeline, CI or other technology which is having a negative impact on the Project outcomes.",
  ),
];
const FIN = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Overall Status",
    "On track to deliver successfully and key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to bring project back to success path.",
    "No agreed plan to resolve issues that are preventing the project to succeed.",
  ),
  createData(
    "Plan",
    "Project is on track and progress meets expectations. Dependencies and risks are known and there are plans in place on how to manage. Team has a clear direction. ",
    "Project is behind schedule / target end date is at risk / team lacks clear direction. Action plan in place to bring project back on track or negotiate with client to adjust the expectations / clarify the direction.",
    "Project is behind schedule / target end date is not achievable / team lacks direction.",
  ),
  createData(
    "Staffing",
    "All key roles are staffed and the profile of the team is balanced.",
    "All key roles are staffed. The profile of the team is out of balance and might lead to delivery issues.",
    "Some of the key roles are unstaffed / profile of the team is out of balance leading to delivery issues.",
  ),
  createData(
    "Team Morale",
    "The morale of the team is good, majority of team members are happy.",
    "Some team members are unhappy / team dynamics is out of balance. Action plan in place but might lead to delivery issues.",
    "Several team members are unhappy / team dynamics is out of balance and it has negative impact on delivery.",
  ),
  createData(
    "Customer Relations",
    "Open and trusting relationship with key client stakeholders. Client is happy with the team and delivery.",
    "Limited access to / lack of transparency or trust with some of the key client stakeholders / client not fully satisfied with the team or delivery. Action plan in place to improve the client relationships.",
    "Lack of trust with many of the key client stakeholders / Some client stakeholders are active detractors.",
  ),
  createData(
    "Commercials & Legal",
    "Signed contract and contractual obligations in line with delivery plan. Actual costs are on track with planned costs.",
    "Work started without a signed contract / Contractual obligations are unclear or not fully inline with delivery plan / Actual costs are slightly greater than planned.",
    "Work has been on for some time already without a signed contract / Contractual obligations clearly not inline with delivery plan or severe violations to obligations / High commercial or brand image risk / Actual costs asignificantly greater than planned.",
  ),
  createData(
    "Technology",
    "No significant issues with the quality of deliverables, architecture, infrastructure, deployment pipeline, CI or other technology being used.",
    "One or more issues with quality of our product / deliverables, architecture, security, infrastructure, deployment pipeline, CI or other technology. Action plans being actively managed.",
    "One or more significant issues with the quality of our product/deliverables, architecture, security, infrastructure, deployment pipeline, CI or other technology - having a negative impact on the project outcomes.",
  ),
  createData(
    "InfoSec",
    `If Low Bar Applies, All of the following are true:
    - There is a Thoughtworks Security Incident Response Plan
    - There are on-boarding and off-boarding checklists covering security and accurate records of TWers having completed the items on the checklists
    - Client-specific or project-specific information security policies and handling requirements are well defined and are understood by the whole team

    OR If Second Tier Applies, All of the following are true:
    - The team is assessing the project against a baseline of security controls appropriate to the project's risk position, e.g. OWASP ASVS or the TW Secure Delivery Checklist, including Data Protection
    - The project has undertaken some activities to identify security threats and vulnerabilities in the past 6 months, e.g. threat modelling, pen testing, security risk assessments, or other security testing (such as DAST, SAST, or exploratory testing)
    - All identified security risks and issues have mitigating actions (or they've been accepted by the client)
    - Work to address security risks is prioritised and completed effectively
    https://docs.google.com/document/d/1ZpsYz7jguLhSRaZzeckT3vvmyV8YS-F2qk0qCxix4vg/edit`,
    `If Low Bar Applies, Any of the following are true:
    - There is a ThoughtWorks Security Incident Response Plan, most team members aren't aware of it
    - There are on-boarding and off-boarding checklists but security is not an aspect
    - Client-specific or project-specific information security policies and handling requirements are partially defined or are defined but not well understood

    OR If Second Tier Applies, One or more of the following are true:
    - There is an Incident Response Plan, but most people aren't aware of it
    - There is an on/offboarding checklist, but security is not an aspect
    - Have done threat modeling more than 4 months ago
    - Medium (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Client security policies and expectations are available, but the team is not familiar, or cannot follow some of them
    - Team is not aware, or cannot align with several, of the relevant TW Project Protection guidance
    - No security champion in some teams
    `,
    `If Low Bar Applies, Any of the following are true:
    - There is no Security Incident Response Plan
    - There are no on-boarding or off-boarding checklists
    - Client-specific or project-specific information security policies and handling requirements are not defined

    OR If Second Tier Applies, One or more of the following are true:
    - We have no Incident Response Plan
    - We have no on/offboarding checklist
    - We have no past threat modeling, or plans for one in the near future
    - High (or equivalent) security risks/issues are not being managed to the client's, or TW, satisfaction
    - Team is not aware, or cannot align with most, of the relevant TW Project Protection guidance
    - Team is not aware, or cannot align with several, of the Client security policies and expectations
    - No security champion in the account`,
  ),
  createData(
    "Future Business",
    "Talking about concrete additional or follow-up business with the client. Most likely our team will grow over the time.",
    "No additional or follow-up opportunities visible now, team most likely will shrink slightly.",
    "Project will end or team will shrink significantly in the next future. No additional opportunities visible or no client budget available.",
  ),
];
const CHNROW = [
  createData("Ranking", 1, 2, 3),
  createData(
    "Overall Status",
    "On track to meet the engagement's desired outcomes successfully and all key risks & issues are being managed successfully.",
    "Key risks & issues are being managed but require decisive actions to be identified and executed to bring project back on track.",
    "No agreed plan to resolve issues which are impacting the team's ability to meet the engagement's desired outcomes.",
  ),
  createData(
    "Commercials & Legal",
    "Commercial and legal aspects are well-defined, aligned, and effectively managed, minimizing risks and ensuring compliance with contractual obligations. Robust processes and practices support project viability and success, fostering confidence in financial and legal aspects.",
    "Some commercial and legal aspects require attention and improvement to mitigate risks and ensure compliance with contractual obligations. While efforts are underway to address identified issues, further actions are needed to strengthen processes and enhance alignment, safeguarding project interests.",
    "Commercial and legal aspects lack clarity or alignment, posing significant risks to project finances and contractual obligations. Immediate action is necessary to address unresolved issues and mitigate potential legal or financial implications, ensuring project viability and success.",
  ),
  createData(
    "Commercials & Legal - Contract are signed",
    "All essential contracts are signed, and legal matters are effectively managed, providing a solid foundation for project execution and ensuring compliance with legal requirements.",
    "While contracts are signed, unresolved legal matters or discrepancies exist, posing potential risks to project delivery. Urgent attention is required to address these issues and ensure legal compliance to safeguard project interests.",
    "Critical contracts remain unsigned, exposing the project to significant legal risks and threatening its continuity. The absence of formal agreements hampers progress and jeopardizes stakeholder confidence. Immediate action is imperative to secure necessary contracts and mitigate legal vulnerabilities.",
  ),
  createData(
    "Commercials & Legal - Actual margin matches planned margin (during DR phase)",
    "Actual margins closely align with committed margins, reflecting sound financial management practices and ensuring project profitability. Effective monitoring and control mechanisms contribute to financial stability and stakeholder confidence.",
    "Margins deviate from commitments, indicating potential financial risks that require proactive management and mitigation strategies. While efforts are underway to address these discrepancies, further actions are needed to ensure alignment with committed margins and preserve project profitability.",
    "Substantial discrepancies between actual and committed margins threaten project profitability and financial viability. Failure to align with committed margins poses significant risks to project success and necessitates immediate corrective action to mitigate financial losses.",
  ),
  createData(
    "Commercials & Legal - Invoices are sent to client on time",
    "Invoices are consistently issued to clients on time, facilitating smooth cash flow management and fostering positive client relationships. Robust invoicing processes contribute to financial transparency and operational efficiency, ensuring project success and stakeholder satisfaction.",
    "Some invoices are delayed, impacting cash flow and client relationships. While efforts are underway to address these delays, proactive measures are necessary to streamline invoicing processes and ensure timely payments, thereby enhancing financial predictability and stakeholder trust.",
    "Delays in issuing invoices to clients disrupt cash flow and hinder financial stability, posing risks to project continuity. The inability to issue invoices on time reflects inefficiencies in financial management and demands immediate resolution to mitigate financial strain and maintain client satisfaction.",
  ),
  createData(
    "Commercials & Legal - Invoices are paid on time",
    "Invoices are consistently paid by clients on time, ensuring smooth cash flow management and financial stability. Efficient payment processes contribute to financial transparency and operational excellence, supporting project success and stakeholder satisfaction.",
    "Some client payments are delayed, impacting cash flow and financial predictability. While efforts are underway to address these delays, proactive measures are required to expedite payment processes and ensure timely receipts, thereby enhancing financial resilience and stakeholder confidence.",
    "Persistent delays in client payments disrupt cash flow, compromise financial stability, and jeopardize project continuity. Failure to receive payments on time reflects inefficiencies in financial management and necessitates immediate action to address payment delays and mitigate financial risks.",
  ),
  createData(
    "Commercials & Legal - Revenue leakage",
    "Effective measures are in place to identify and mitigate revenue leakage, ensuring project profitability and financial sustainability. Robust strategies and practices are employed to proactively address potential sources of loss, bolstering project success. The implementation of efficient leave management practices and proactive monitoring further enhances revenue protection, contributing to project stability and success.",
    "Potential revenue losses due to unidentified or unaddressed leakages raise concerns about project profitability and financial sustainability. While measures are in place to mitigate revenue leakage, further actions are needed to enhance effectiveness and ensure proactive identification and resolution of potential sources of loss. Addressing issues such as 'Unstaffed ATL' and improving leave management practices can mitigate risks and safeguard project revenue.",
    "Substantial revenue losses stemming from unidentified or unaddressed leakages imperil project profitability and financial stability. The lack of effective measures to mitigate revenue leakage presents a critical threat to project success, demanding urgent intervention to pinpoint and rectify sources of loss. Notably, revenue leakage is frequently attributed to 'Unstaffed ATL' and inadequate leave management practices, wherein project team members take unplanned leaves, resulting in billable hours that fail to generate anticipated revenue.",
  ),
  createData(
    "Commercials & Legal - SLA breach (DAMO)",
    "SLAs are consistently met or exceeded, demonstrating high service performance and ensuring client satisfaction. Effective service delivery processes and monitoring mechanisms contribute to SLA compliance and stakeholder confidence, supporting project success and long-term partnerships.",
    "SLA breaches occur intermittently, indicating potential service delivery challenges that require proactive management and resolution. While efforts are underway to address these breaches, further actions are needed to enhance service performance and meet client expectations consistently.",
    "Persistent breaches of service level agreements (SLAs) disrupt client expectations, erode trust, and jeopardize project success. Failure to meet SLA commitments reflects deficiencies in service delivery and demands immediate action to restore client confidence and mitigate reputational risks.",
  ),
  createData(
    "InfoSec",
    "Information security measures are robust, well-implemented, and compliant with standards and regulations, minimizing risks of data breaches and ensuring protection of project data and assets. Effective security practices support project integrity and success, instilling confidence in information security.",
    "Some aspects of information security require attention and improvement to enhance protection against potential threats and vulnerabilities. While efforts are underway to address identified gaps, further actions are needed to strengthen security measures and ensure compliance with standards and regulations, safeguarding project data and assets.",
    "Information security measures are inadequate or misaligned, exposing the project to significant risks of data breaches or non-compliance. Immediate action is required to strengthen security measures, mitigate vulnerabilities, and ensure compliance with security standards and regulations.",
  ),
  createData(
    "InfoSec - InfoSec Tier One - Onboarding/IRP",
    `All of the followings are true:

    - There is a Thoughtworks Security Incident Response Plan
    - There are on-boarding and off-boarding checklists covering security and accurate records of TWers having completed the items on the checklists
    - Client-specific or project-specific information security policies and handling requirements are well defined and are understood by the whole team
    `,
    `Any of the following is true:

    - There is a ThoughtWorks Security Incident Response Plan, most team members aren't aware of it
    - There are on-boarding and off-boarding checklists but security is not an aspect
    - Client-specific or project-specific information security policies and handling requirements are partially defined or are defined but not well understood
    `,
    `Any of the following is true:

    - There is no Security Incident Response Plan
    - There are no on-boarding or off-boarding checklists
    - Client-specific or project-specific information security policies and handling requirements are not defined`,
  ),
  createData(
    "InfoSec - InfoSec Tier Two - Risk Assessment/Project Security Risks",
    `All of the followings are true:

    - The team is assessing the project against a baseline of security controls appropriate to the project's risk position, e.g. OWASP ASVS or the TW Secure Delivery Checklist
    - The project has undertaken some activities to identify security threats and vulnerabilities in the past 6 months, e.g. threat modelling, pen testing, security risk assessments, or other security testing (such as DAST, SAST, or exploratory testing)
    - All identified security risks and issues have mitigating actions (or they've been accepted by the client) - Work to address security risks is prioritised and completed effectively
    `,
    `Any of the following is true:

    - Security risks and issues are being managed sporadically or are out-of-date
    - Security risks and issues are being managed to the client's satisfaction, but not to ours
    - Some security risk or issues are missing mitigating actions (or they have not been accepted by the client)
    `,
    `Either of the following is true:

    - We have not assessed the security risks and issues for this project
    - There are known security risks or issues with no mitigating actions or agreed plan for resolution
    `,
  ),
  createData(
    "Ways of Working",
    "Processes and practices for project execution are efficient, effective, and conducive to collaboration, driving productivity and delivery outcomes. Well-established ways of working support project performance and success, fostering a culture of continuous improvement and innovation.",
    "Some aspects of project execution processes require refinement to improve efficiency and effectiveness. While efforts are underway to address identified inefficiencies, further actions are needed to optimize processes and enhance collaboration, supporting project delivery and success.",
    "Processes and practices for project execution are inefficient or ineffective, hindering productivity, collaboration, and delivery outcomes. Immediate action is necessary to streamline processes, address bottlenecks, and improve collaboration to enhance project performance and success.",
  ),
  createData(
    "Ways of Working - Backlog Grooming",
    "Backlog grooming is conducted systematically and effectively, ensuring clear priorities, scope definition, and alignment with project objectives. Robust grooming practices contribute to project agility and stakeholder satisfaction, supporting successful project outcomes and long-term partnerships.",
    "Backlog grooming practices lack depth or consistency, resulting in ambiguities and inefficiencies in sprint planning. While efforts are underway to refine backlogs, further actions are needed to enhance grooming practices and ensure clear priorities and scope definition, thereby improving project agility and stakeholder satisfaction.",
    "Inadequate backlog grooming practices lead to unclear priorities, scope creep, and inefficiencies in project planning. The absence of systematic backlog refinement hampers project agility and jeopardizes stakeholder satisfaction. Immediate action is essential to establish robust backlog grooming processes and ensure alignment with project objectives.",
  ),
  createData(
    "Ways of Working - Sprint/Iteration Planning",
    "Sprint planning is conducted rigorously and effectively, ensuring clear goals, resource allocation, and alignment with project objectives. Robust planning practices contribute to project predictability and stakeholder satisfaction, supporting successful project outcomes and long-term partnerships.",
    "Sprint planning lacks detail or consistency, leading to ambiguities and inefficiencies in resource allocation and goal setting. While efforts are underway to improve sprint planning, further actions are needed to enhance planning processes and ensure clear goals and resource alignment, thereby improving project predictability and stakeholder satisfaction.",
    "Ineffective sprint planning practices result in poorly defined goals, inadequate resource allocation, and disruptions in project execution. The absence of structured planning processes hampers project predictability and jeopardizes stakeholder satisfaction. Immediate action is essential to establish rigorous sprint planning practices and ensure alignment with project objectives.",
  ),
  createData(
    "Ways of Working - DoD & DoR",
    "Definitions of ready and done are clear, well-defined, and consistently applied across project activities. Robust criteria contribute to project quality and stakeholder satisfaction, supporting successful project outcomes and long-term partnerships.",
    "Definitions of ready and done lack detail or consistency, resulting in ambiguities and inefficiencies in project execution. While efforts are underway to clarify definitions, further actions are needed to ensure consistent application and alignment with project objectives, thereby improving project quality and stakeholder satisfaction.",
    "Unclear or inconsistently applied definitions of ready and done lead to misunderstandings, scope creep, and inefficiencies in project delivery. The absence of well-defined criteria undermines project quality and jeopardizes stakeholder satisfaction. Immediate action is essential to establish clear definitions of ready and done and ensure consistent application across project activities.",
  ),
  createData(
    "Ways of Working - Daily Standup - Progress & Blockers",
    "Daily standup meetings are conducted effectively, ensuring focused discussions, active participation, and accountability. Structured meeting protocols contribute to project alignment and stakeholder satisfaction, supporting successful project outcomes and long-term partnerships.",
    "Daily standup meetings lack focus or participation, leading to inefficiencies in team coordination and communication. While efforts are underway to improve meeting practices, further actions are needed to enhance meeting effectiveness and ensure alignment with project objectives, thereby improving team coordination and stakeholder satisfaction.",
    "Ineffective daily standup meetings lack focus, participation, and accountability, resulting in miscommunication and inefficiencies in team coordination. The absence of structured meeting practices undermines project alignment and jeopardizes stakeholder satisfaction. Immediate action is essential to establish effective standup meeting protocols and ensure active participation and accountability.",
  ),
  createData(
    "Ways of Working - Card Kickoffs & Desk-checks",
    "Card kick-off and desk check practices are conducted systematically and effectively, ensuring task clarity and completeness. Robust validation processes contribute to project quality and stakeholder satisfaction, supporting successful project outcomes and long-term partnerships.",
    "Card kick-off and desk check practices lack consistency or depth, leading to ambiguities and inefficiencies in task validation. While efforts are underway to improve validation practices, further actions are needed to enhance consistency and ensure thorough task validation, thereby improving project quality and stakeholder satisfaction.",
    "Inadequate card kick-off and desk check practices result in incomplete or unclear tasks, leading to inefficiencies and delays in project delivery. The absence of structured task validation processes undermines project quality and jeopardizes stakeholder satisfaction. Immediate action is essential to establish robust validation practices and ensure task clarity and completeness.",
  ),
  createData(
    "Ways of Working - Pair Programming",
    "Pair programming practices are embraced and effectively adopted, promoting knowledge sharing and collaboration. Collaborative development processes contribute to team cohesion and project success, supporting successful project outcomes and long-term partnerships.",
    "Pair programming practices are inconsistently adopted or lack effectiveness, leading to inefficiencies in collaborative development. While efforts are underway to promote pair programming, further actions are needed to enhance adoption and ensure effective collaboration, thereby improving team cohesion and project success.",
    "Failure to adopt pair programming practices leads to isolated work, knowledge silos, and inefficiencies in project delivery. The absence of collaborative development processes undermines team cohesion and jeopardizes project success. Immediate action is essential to promote pair programming and foster knowledge sharing and collaboration.",
  ),
  createData(
    "Ways of Working - Showcase/Demos driving outcomes",
    "Showcase or demo practices are conducted effectively, engaging stakeholders, eliciting feedback, and driving project outcomes. Structured presentation formats contribute to stakeholder visibility and project alignment, supporting successful project outcomes and long-term partnerships.",
    "Showcase or demo practices lack structure or fail to engage stakeholders effectively, resulting in limited feedback and alignment with project objectives. While efforts are underway to improve showcase practices, further actions are needed to enhance engagement and ensure alignment with project goals, thereby improving stakeholder satisfaction and project success.",
    "Ineffective showcase or demo practices fail to engage stakeholders, elicit feedback, and drive project outcomes. The absence of structured presentation formats undermines stakeholder visibility and jeopardizes project success. Immediate action is essential to establish effective showcase or demo protocols and ensure stakeholder engagement and feedback.",
  ),
  createData(
    "Ways of Working - Retrospectives",
    "Retrospective practices are conducted effectively, driving meaningful insights, actions, and continuous improvement. Structured reflection processes contribute to team learning and growth, supporting successful project outcomes and long-term partnerships.",
    "Retrospective practices lack structure or fail to drive meaningful insights and actions, resulting in limited improvements and team growth. While efforts are underway to enhance retrospective practices, further actions are needed to promote structured reflection and ensure actionable outcomes, thereby fostering continuous improvement and project success.",
    "Ineffective retrospective practices fail to identify lessons learned, drive continuous improvement, and foster team growth. The absence of structured reflection processes undermines project learning and jeopardizes future performance. Immediate action is essential to establish effective retrospective protocols and ensure lessons learned are captured and acted upon.",
  ),

  createData(
    "Customer Relations",
    "Customer relationships are strong, well-managed, and aligned with project objectives, fostering satisfaction and collaboration. Effective communication and value articulation support customer engagement and project success, driving long-term partnerships and mutual success.",
    "Some aspects of customer relationships require attention and improvement to enhance satisfaction and alignment with project objectives. While efforts are underway to address identified issues, further actions are needed to strengthen relationships and ensure proactive communication and value articulation, fostering trust and collaboration.",
    "Customer relationships are strained or poorly managed, resulting in dissatisfaction and potential risks to project continuity and success. Immediate action is required to address customer concerns, strengthen relationships, and restore confidence in project delivery and outcomes.",
  ),
  createData(
    "Customer Relations - C-level executive connection",
    "Strong connections with C-level executives foster strategic alignment, stakeholder engagement, and sustained support. Meaningful executive involvement ensures project success and stakeholder satisfaction, supporting long-term partnerships and strategic objectives.",
    "Inconsistent or limited engagement with C-level executives raises concerns about strategic alignment and stakeholder support. While efforts are underway to strengthen connections, further actions are needed to deepen engagement and secure sustained executive involvement, thereby enhancing project alignment and stakeholder satisfaction.",
    "Disconnect with C-level executives threatens project alignment, stakeholder engagement, and strategic support. Lack of executive involvement undermines project direction and jeopardizes stakeholder satisfaction. Immediate action is necessary to establish meaningful connections and secure executive buy-in to ensure project success.",
  ),
  createData(
    "Customer Relations - Mid-senior level stakeholder satisfaction (NPS)",
    "High satisfaction among mid-senior level stakeholders reflects strong communication, expectation management, and deliverable quality. Positive stakeholder relationships support project credibility and success, fostering long-term partnerships and stakeholder trust.",
    "Inconsistencies or concerns in mid-senior level stakeholder satisfaction signal potential issues in communication, expectations management, or deliverable quality. While efforts are underway to address stakeholder concerns, further actions are needed to strengthen relationships and improve satisfaction, thereby enhancing project credibility and stakeholder support.",
    "Dissatisfaction among mid-senior level stakeholders indicates significant gaps in communication, expectations, or deliverable quality. Lack of stakeholder satisfaction undermines project credibility and jeopardizes stakeholder support. Immediate action is essential to identify and address root causes of dissatisfaction to restore stakeholder confidence and ensure project success.",
  ),
  createData(
    "Customer Relations - Working level stakeholder/Point of contact satisfaction (NPS)",
    "High satisfaction among working-level stakeholders or points of contact reflects strong communication, responsiveness, and issue resolution. Positive stakeholder relationships support project collaboration and success, fostering long-term partnerships and stakeholder trust.",
    "Inconsistencies or concerns in working-level stakeholder or point of contact satisfaction signal potential issues in communication, responsiveness, or issue resolution. While efforts are underway to address stakeholder concerns, further actions are needed to strengthen relationships and improve satisfaction, thereby enhancing project collaboration and stakeholder support.",
    "Dissatisfaction among working-level stakeholders or points of contact indicates significant gaps in communication, responsiveness, or issue resolution. Lack of stakeholder satisfaction undermines project collaboration and jeopardizes stakeholder support. Immediate action is essential to identify and address root causes of dissatisfaction to restore stakeholder confidence and ensure project success.",
  ),
  createData(
    "Customer Relations - Value articulation/ROI quantification",
    "Clear value articulation and ROI quantification demonstrate project benefits, stakeholder understanding, and project viability. Effective communication of value proposition and ROI fosters stakeholder buy-in and project success, supporting long-term partnerships and strategic objectives.",
    "Inconsistencies or deficiencies in value articulation or ROI quantification raise concerns about stakeholder understanding, support, or project viability. While efforts are underway to clarify value proposition and ROI, further actions are needed to refine articulation and quantification to enhance stakeholder buy-in and project success.",
    "Failure to articulate value or quantify ROI undermines stakeholder understanding, support, and project viability. Lack of clarity in value proposition and ROI impacts stakeholder buy-in and jeopardizes project success. Immediate action is essential to refine value articulation and ROI quantification to ensure alignment with stakeholder expectations and project objectives.",
  ),
  createData(
    "Delivery",
    "Delivery processes are well-structured, managed, and aligned with project objectives, driving efficiency, predictability, and quality. Effective delivery practices support successful project outcomes, fostering confidence in project delivery and stakeholder satisfaction.",
    "Some aspects of delivery processes require attention and improvement to enhance efficiency, predictability, and quality. While efforts are underway to address identified challenges, further actions are needed to streamline delivery, mitigate risks, and ensure adherence to project objectives and timelines.",
    "Delivery processes are chaotic or mismanaged, resulting in project delays, budget overruns, or quality issues. Immediate action is necessary to stabilize delivery, address critical issues, and realign project activities to ensure successful outcomes.",
  ),
  createData(
    "Delivery - Project Canvas (vision, goals, MoS, key milestones, budget...)",
    "Clear definition and alignment of project vision, goals, milestones, and budget ensure project clarity, alignment, and control. Effective project canvas supports robust planning and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in project canvas elements raise concerns about project clarity, alignment, or control. While efforts are underway to refine project parameters, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing project planning and stakeholder satisfaction.",
    "Inadequate definition or alignment of project vision, goals, milestones, or budget undermines project clarity, direction, and control. Lack of a comprehensive project canvas hampers project planning and jeopardizes stakeholder satisfaction. Immediate action is essential to establish clear project parameters and alignment to ensure project success.",
  ),
  createData(
    "Delivery - Delivery Roadmap",
    "Clear definition and alignment of delivery roadmap elements ensure project planning, predictability, and control. Effective delivery roadmap supports project progress and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in delivery roadmap elements raise concerns about project planning, predictability, or control. While efforts are underway to refine the delivery roadmap, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing project progress and stakeholder satisfaction.",
    "Lack of a structured delivery roadmap or inadequate alignment with project objectives undermines project planning, predictability, and control. Absence of clear milestones and dependencies hampers project progress and jeopardizes stakeholder satisfaction. Immediate action is essential to establish a robust delivery roadmap and ensure alignment with project objectives to drive successful project outcomes.",
  ),
  createData(
    "Delivery - Release Plan",
    "Clear definition and alignment of release plan elements ensure project delivery, predictability, and stakeholder satisfaction. Effective release plan supports project progress and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in release plan elements raise concerns about project delivery, predictability, or stakeholder satisfaction. While efforts are underway to refine the release plan, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing project progress and stakeholder satisfaction.",
    "Lack of a structured release plan or inadequate alignment with project objectives undermines project delivery, predictability, and stakeholder satisfaction. Absence of clear release milestones and dependencies hampers project progress and jeopardizes project success. Immediate action is essential to establish a robust release plan and ensure alignment with project objectives to drive successful project outcomes.",
  ),
  createData(
    "Delivery - Tracking Delivery: Project/Release Burn-up Chart",
    "Effective tracking of project or release progress ensures project visibility, predictability, and stakeholder satisfaction. Robust tracking mechanisms support project control and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in project or release tracking raise concerns about project visibility, predictability, or stakeholder satisfaction. While efforts are underway to improve tracking mechanisms, further actions are needed to ensure accurate and timely monitoring, thereby enhancing project control and stakeholder satisfaction.",
    "Ineffective tracking of project or release progress undermines project visibility, predictability, and stakeholder satisfaction. Lack of accurate and timely tracking hampers project control and jeopardizes project success. Immediate action is essential to establish robust tracking mechanisms and ensure accurate monitoring to drive successful project outcomes.",
  ),
  createData(
    "Delivery - Tracking Cost: Budget tracker or man-day burn-up Chart",
    "Effective tracking of project costs ensures financial visibility, control, and stakeholder satisfaction. Robust cost tracking mechanisms support financial management and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in project cost tracking raise concerns about financial visibility, control, or stakeholder satisfaction. While efforts are underway to improve cost tracking mechanisms, further actions are needed to ensure accurate and timely monitoring, thereby enhancing financial management and stakeholder satisfaction.",
    "Inadequate tracking of project costs undermines financial visibility, control, and stakeholder satisfaction. Lack of accurate and timely cost tracking hampers financial management and jeopardizes project success. Immediate action is essential to establish robust cost tracking mechanisms and ensure accurate monitoring to drive successful project outcomes.",
  ),
  createData(
    "Delivery - Tracking Quality: Quality Metrics",
    "Effective tracking of project quality ensures product reliability, stakeholder satisfaction, and project success. Robust quality tracking mechanisms support quality management and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in project quality tracking raise concerns about product reliability, stakeholder satisfaction, or project success. While efforts are underway to improve quality tracking mechanisms, further actions are needed to ensure comprehensive measurement and adherence to quality standards, thereby enhancing product quality and stakeholder satisfaction.",
    "Inadequate tracking of project quality undermines product reliability, stakeholder satisfaction, and project success. Lack of comprehensive quality metrics hampers quality management and jeopardizes project outcomes. Immediate action is essential to establish robust quality tracking mechanisms and ensure adherence to quality standards to drive successful project outcomes.",
  ),
  createData(
    "Delivery - RAIDs & Decision Log",
    "Effective management of project risks, assumptions, issues, dependencies, and decisions ensures project resilience, predictability, and stakeholder satisfaction. Robust management mechanisms support proactive identification and mitigation of project uncertainties, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in project risk, assumption, issue, dependency, or decision management raise concerns about project resilience, predictability, or stakeholder satisfaction. While efforts are underway to improve management mechanisms, further actions are needed to ensure comprehensive identification and mitigation of project uncertainties, thereby enhancing project control and stakeholder satisfaction.",
    "Ineffective management of project risks, assumptions, issues, dependencies, or decisions undermines project resilience, predictability, and stakeholder satisfaction. Lack of comprehensive risk management hampers project control and jeopardizes project success. Immediate action is essential to establish robust management mechanisms and ensure proactive identification and mitigation of project uncertainties to drive successful project outcomes.",
  ),
  createData(
    "Delivery - Quarterly Impact Map (value delivered)",
    "Clear definition and alignment of quarterly impact mapping ensure stakeholder understanding, engagement, and project success. Effective impact mapping practices support project planning and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in quarterly impact mapping raise concerns about stakeholder understanding, engagement, or project success. While efforts are underway to improve impact mapping practices, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing project planning and stakeholder satisfaction.",
    "Lack of clarity or alignment in quarterly impact mapping undermines stakeholder understanding, engagement, and project success. Absence of comprehensive impact mapping hampers project planning and jeopardizes stakeholder satisfaction. Immediate action is essential to establish robust impact mapping practices and ensure alignment with project objectives to drive successful project outcomes.",
  ),
  createData(
    "Delivery - CAB Mechanism and Change Management practices",
    "Effective change management practices ensure project stability, predictability, and stakeholder satisfaction. Robust change management mechanisms support proactive identification and mitigation of project changes, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in change management practices raise concerns about project stability, predictability, or stakeholder satisfaction. While efforts are underway to improve change management mechanisms, further actions are needed to ensure comprehensive identification and mitigation of project changes, thereby enhancing project control and stakeholder satisfaction.",
    "Ineffective change management practices undermine project stability, predictability, and stakeholder satisfaction. Lack of a structured CAB mechanism hampers change control and jeopardizes project success. Immediate action is essential to establish robust change management practices and ensure proactive identification and mitigation of project changes to drive successful project outcomes.",
  ),

  createData(
    "Governance",
    "Governance mechanisms are well-defined, effective, and aligned with project objectives and stakeholder expectations, driving oversight, accountability, and decision-making. Robust governance practices support project success, fostering transparency, trust, and confidence in project governance.",
    "Some aspects of governance require attention and improvement to enhance oversight, accountability, and decision-making. While efforts are underway to address identified gaps, further actions are needed to strengthen governance mechanisms and ensure alignment with project objectives and stakeholder expectations.",
    "Governance mechanisms are ineffective or non-existent, resulting in poor oversight, accountability, and decision-making. Immediate action is required to establish robust governance structures, clarify roles and responsibilities, and enhance transparency and accountability to ensure project success.",
  ),
  createData(
    "Governance - Account CLT governance model",
    "Clear definition and alignment of account governance model ensure project oversight, alignment, and stakeholder satisfaction. Effective governance mechanisms support proactive oversight and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in account governance model raise concerns about project oversight, alignment, or stakeholder satisfaction. While efforts are underway to improve governance practices, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing project control and stakeholder satisfaction.",
    "Account governance model has not been defined. Lack of clarity or structure in governance practices hampers project control and jeopardizes project success. Immediate action is essential to establish robust governance mechanisms and ensure proactive oversight to drive successful project outcomes.",
  ),
  createData(
    "Governance - Account Strategy/TW Why",
    "Clear definition and alignment of account strategy and value proposition ensure client understanding, engagement, and project success. Effective strategy and value proposition support client satisfaction and project success, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in account strategy and value proposition raise concerns about client understanding, engagement, or project success. While efforts are underway to improve strategy and value proposition, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing client satisfaction and project success.",
    "CLT has not led the account strategy development practice/activities. Absence of a compelling value proposition hampers client satisfaction and jeopardizes project outcomes. Immediate action is essential to establish robust account strategy and value proposition to drive successful project outcomes.",
  ),
  createData(
    "Governance - Stakeholders Map/Management",
    "Clear definition and alignment of stakeholder map and management ensure project communication, alignment, and stakeholder satisfaction. Effective management practices support proactive engagement and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in stakeholder map and management raise concerns about project communication, alignment, or stakeholder satisfaction. While efforts are underway to improve management practices, further actions are needed to ensure comprehensive mapping and engagement, thereby enhancing project outcomes and stakeholder satisfaction.",
    "Ineffective stakeholder management erodes project communication, alignment, and stakeholder satisfaction, compounded by the absence or obsolescence of a structured stakeholder map. The lack of a clear stakeholder map obstructs project engagement, placing project outcomes in jeopardy. Urgent intervention is imperative to institute robust stakeholder management practices, rectify the absence or obsolescence of the stakeholder map, and foster proactive engagement, thereby steering the project towards successful outcomes.",
  ),
  createData(
    "Governance - Account Governance meeting/QBR",
    "Clear definition and alignment of account governance meetings or quarterly business reviews ensure project oversight, alignment, and stakeholder satisfaction. Effective meeting or review processes support proactive oversight and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in account governance meetings or quarterly business reviews raise concerns about project oversight, alignment, or stakeholder satisfaction. While efforts are underway to improve meeting or review processes, further actions are needed to ensure comprehensive definition and alignment, thereby enhancing project control and stakeholder satisfaction.",
    "The lack of effective account governance meetings or quarterly business reviews diminishes project oversight, alignment, and stakeholder satisfaction. Without structured or clear review processes, project control is hindered, posing a risk to project success. Immediate action is necessary to establish robust meeting or review protocols and ensure proactive oversight, especially in the absence of any existing mechanisms for account governance meetings or quarterly business reviews, to drive successful project outcomes.",
  ),
  createData(
    "Team",
    "Team dynamics are positive, collaborative, and conducive to productivity and success. Effective leadership and support mechanisms foster high morale, engagement, and performance, driving project success and team satisfaction.",
    "Some aspects of team dynamics require attention and improvement to enhance morale, productivity, and collaboration. While efforts are underway to address identified challenges, further actions are needed to strengthen team dynamics and support team members in achieving their full potential, driving project success.",
    "Team dynamics are dysfunctional or poorly managed, resulting in low morale, productivity, and retention. Immediate action is necessary to address team issues, enhance communication, and foster a positive and collaborative team environment to ensure project success.",
  ),
  createData(
    "Team - Supply & Staffing",
    "Adequate resource supply and staffing ensure team capacity, productivity, and project success. Effective resource management supports project delivery and stakeholder satisfaction, fostering successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in resource supply or staffing raise concerns about team capacity, productivity, or project success. While efforts are underway to address resource challenges, further actions are needed to ensure comprehensive staffing and resource allocation, thereby enhancing project delivery and stakeholder satisfaction.",
    "Inadequate resource supply or staffing undermines team capacity, productivity, and project success. Lack of skilled resources or staffing shortages hampers project delivery and jeopardizes project outcomes. Immediate action is essential to address resource gaps and ensure adequate staffing to drive successful project outcomes.",
  ),
  createData(
    "Team - Team Mix",
    "All core team roles are staffed appropriately and the profile of the rest of the team is correctly balanced.",
    "All core team roles are staffed appropriately, BUT the profile of the rest of the team is NOT correctly balanced (e.g. missing supporting roles, OR the leverage is not correct).",
    "Some or all of the core team roles are not staffed appropriately and/or the rest of the team is out of balance, leading to delivery issues.",
  ),
  createData(
    "Team - Team Morale",
    "Team exhibits a strong sense of collaboration and ownership, creating a valued and continuously learning environment.",
    "Slight decrease in productivity due to low motivation or similar morale factors, OR lacking proactive communication, OR lacking colloboration and ownership in solving issues.",
    "Significant decrease in productivity due to low motivation or similar morale factors. The team is stressed, frustrated, and showing disengagement through more absences and unexpected rotation requests.",
  ),
  createData(
    "Team - Rotation/Succession Plan",
    "Structured resource rotation and succession planning support team development and continuity, fostering project resilience and success. Effective planning processes ensure knowledge transfer and retention, supporting successful project outcomes and long-term partnerships.",
    "Inconsistencies or deficiencies in resource rotation or succession planning raise concerns about team development or continuity. While efforts are underway to improve planning processes, further actions are needed to ensure comprehensive rotation and succession strategies, thereby enhancing team development and project success.",
    "Lack of resource rotation or succession planning undermines team development and continuity, jeopardizing project resilience and success. Absence of structured rotation or succession processes hampers knowledge transfer and retention. Immediate action is essential to establish robust rotation and succession plans to ensure team development and continuity for successful project outcomes.",
  ),
];

const ROWS_IN_REGIONS = {
  UK,
  SEAANZ,
  NL,
  DE,
  BR,
  FIN,
  CHNROW,
};

export default function RAGTable() {
  const [region] = useRegionStorage();
  if (!(region?.shortName && ROWS_IN_REGIONS[region.shortName])) {
    return <p>region not exist</p>;
  }
  const rows = ROWS_IN_REGIONS[region.shortName];

  const whiteSpace = { whiteSpace: "pre-line" };
  return (
    <>
      <Grid container style={{ paddingLeft: "2em", paddingRight: "2em" }}>
        <Grid item xs={12}>
          <Box mt={8}>
            <Box mb={3}>
              <Typography variant="h4" id="RAG">
                RAG Definition
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>COMPONENT / STATUS</TableCell>
                    <TableCell align="center" style={{ color: "green" }}>
                      GREEN
                    </TableCell>
                    <TableCell align="center" style={{ color: "orange" }}>
                      AMBER
                    </TableCell>
                    <TableCell align="center" style={{ color: "red" }}>
                      RED
                    </TableCell>
                    <TableCell align="center">N/A</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left" style={whiteSpace}>
                        {row.calories}
                      </TableCell>
                      <TableCell align="left" style={whiteSpace}>
                        {row.fat}
                      </TableCell>
                      <TableCell align="left" style={whiteSpace}>
                        {row.carbs}
                      </TableCell>
                      <TableCell align="left" style={whiteSpace}>
                        {row.protein}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
