import { Grid, Typography, Box } from "@mui/material";
import StatusBarChart from "../../StatusBarChart/StatusBarChart";
import BarChartTrendHeader from "./BarChartTrendHeader";
import {BarChartData} from "../../../../../shared/types/Interfaces";

interface AllProjectsTrendsProps {
  activeClientProjects: number | undefined;
  allProjectsData: BarChartData[] | undefined;
  weekEnding: string;
}

export default function AllProjectsTrends({
  activeClientProjects,
  allProjectsData,
  weekEnding,
}: AllProjectsTrendsProps) {
  return (
    <Grid container item xs={12} spacing="16px">
      <Grid item xs={12} lg={4}>
        <Box>
          <Typography variant="h2" sx={{ color: "#003D4F" }}>
            All projects
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: "#EEF1F3",
          borderRadius: "12px",
          padding: "24px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        {
          <BarChartTrendHeader
            label="Overall Status"
            count={activeClientProjects ?? 0}
            date={weekEnding}
          />
        }
        <Grid
          container
          item
          xs={12}
          spacing="16px"
          sx={{ marginLeft: "30px", marginTop: "15px", marginRight: "30px" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "12px",
                padding: "24px",
                marginBottom: "10px",
              }}
            >
              <StatusBarChart barChartData={allProjectsData} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
