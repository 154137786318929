const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.REACT_APP_ISSUER;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REMINDER_EMAIL = process.env.REACT_APP_reminderEmail;
const DISABLE_LOCKING_DELIVERY_REPORT_EDITING_FOR_REGIONS =
  process.env.REACT_APP_disableLockingDeliveryReportEditingForRegions;
const ENABLE_MARKET_DASHBOARD = process.env.REACT_APP_enableMarketDashboard;
const ENABLE_MARKET_PROJECTS = process.env.REACT_APP_enableMarketProjects;

// dot-env doesnt support natively boolean values.
const stringToBoolean = (t) => t === "true";

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri:
      window.location.protocol +
      "//" +
      window.location.host +
      "/implicit/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
    tokenManager: {
      autoRenew: true,
    },
  },
  api: {
    baseURL: API_BASE_URL,
  },
  featureToggles: {
    disableLockingDeliveryReportEditingForRegions:
      DISABLE_LOCKING_DELIVERY_REPORT_EDITING_FOR_REGIONS.split(","),
    reminderEmail: stringToBoolean(REMINDER_EMAIL),
    enableMarketDashboard: stringToBoolean(ENABLE_MARKET_DASHBOARD),
    enableMarketProjects: stringToBoolean(ENABLE_MARKET_PROJECTS),
  },
};

export default config;
