import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import ProjectAttributes from "./ProjectAttributes";
import SensitivityIndicator from "../../Sensitivity/SensitivityIndicator";
import { CheckCircle, Cancel, Edit } from "@material-ui/icons";
import { accountabilityLevelsMap } from "../../../shared/constants";
import useIsDeliveryDirector from "../../../shared/hooks/useIsDeliveryDirector";

const useStyles = makeStyles((StyleTheme) => ({
  projectName: {
    color: StyleTheme.palette.primary.main,
  },
}));
const displayDeliveryReportEnable = (deliveryReport) => {
  if (deliveryReport) {
    return (
      <Grid container spacing={1} direction="row" alignItems="center">
        <CheckCircle style={{ color: "green", margin: "2px" }} /> Delivery
        Reporting Enabled
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} direction="row" alignItems="center">
      <Cancel color="error" style={{ margin: "2px" }} /> Delivery Reporting
      Disabled
    </Grid>
  );
};

export default function ViewProjectDetails({ project, toggleEditing }) {
  const isDeliveryDirector = useIsDeliveryDirector();
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.projectName}>
              <Typography variant="h3">{project.name}</Typography>
              {project.projectEndDate &&
                new Date(project.projectEndDate).setHours(23, 59, 59, 999) <
                  Date.now() && (
                  <Typography style={{ color: "red" }} variant="subtitle2">
                    No longer active. As of{" "}
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }).format(new Date(project.projectEndDate))}
                  </Typography>
                )}
            </Grid>
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-client-name"
              title="Client Name"
              value={project.clientName}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-location"
              title="Client Location"
              value={project.location}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-domain"
              title="Domain"
              value={project.domain}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-portfolio"
              title="Portfolio"
              value={project.portfolio && project.portfolio.name}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-market"
              title="Market"
              value={project.market && project.market.name}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-accountability-level"
              title="Accountability Level"
              value={accountabilityLevelsMap[project.accountabilityLevel]}
              isMultiline={true}
            />
            <ProjectAttributes
              gridValue={12}
              htmlFor="project-description"
              title="Description / Link to Engagement Report(s)"
              value={project.description}
              isMultiline={true}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-start-date"
              title="Start Date"
              value={project.projectStartDate}
              isMultiline={true}
            />
            <ProjectAttributes
              gridValue={6}
              htmlFor="project-end-date"
              title="End Date"
              value={project.projectEndDate}
              isMultiline={true}
            />
            <ProjectAttributes
              gridValue={12}
              htmlFor="crm-account-reference"
              title="CRM Account Reference"
              value={project.crmAccountReference}
            />
            <ProjectAttributes
              gridValue={12}
              htmlFor="delivery-report-enabled"
              title={displayDeliveryReportEnable(project.deliveryReportEnabled)}
              isMultiline={true}
            />
            {isDeliveryDirector.data && (
              <ProjectAttributes
                gridValue={12}
                htmlFor="delivery-directors-notes"
                title="Delivery Directors Notes"
                value={project.deliveryDirectorsNotes}
                isMultiline={true}
              />
            )}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid container direction="column" alignItems="center">
            <SensitivityIndicator
              sensitivity={project.sensitivity || "UNKNOWN"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box margin={3} />
      <Grid container spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={toggleEditing}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
