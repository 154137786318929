import React, { useEffect, useContext, useCallback, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WarningIcon from "@material-ui/icons/Warning";
import { HashLink } from "react-router-hash-link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { ApiClientContext } from "../../ApiClient/context";

const probabilitySet = [
  { key: 1, title: "1 - Rare" },
  { key: 2, title: "2 - Unlikely" },
  { key: 3, title: "3 - Moderate" },
  { key: 4, title: "4 - Likely" },
  { key: 5, title: "5 - Almost Certain" },
];

const impactSet = [
  { key: 1, title: "1 - Insignificant" },
  { key: 2, title: "2 - Minor" },
  { key: 3, title: "3 - Significant" },
  { key: 4, title: "4 - Major" },
  { key: 5, title: "5 - Severe" },
];

const useStyles = makeStyles((theme) => ({
  emptyRisksMessage: {
    color: grey["600"],
    marginLeft: theme.spacing(1.5),
  },
  gridContainer: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  subtitle: {
    color: grey[500],
  },
}));

export default function Risks(props) {
  const { projectId } = props;
  const [risks, setRisks] = useState(props.risks || []);
  const classes = useStyles();
  const apiClient = useContext(ApiClientContext);
  const [riskMetadata, setRiskMetadata] = useState();
  const [editRiskOrginalState, setEditRiskOrginalState] = useState({});
  const [id, setId] = useState({ value: "" });
  const [type, setType] = useState({ value: "", hasError: false });
  const [name, setName] = useState({ value: "", hasError: false });
  const [probability, setProbability] = useState({
    value: "",
    hasError: false,
  });
  const [impact, setImpact] = useState({ value: "", hasError: false });
  const [response, setResponse] = useState({ message: "", severity: "" });
  const [showMessage, setShowMessage] = useState(false);

  const isBlank = (parameter) => parameter.value === "";

  const fetchRiskMetedata = useCallback(async () => {
    await apiClient
      .getRiskMetadata()
      .then((response) => {
        setRiskMetadata(response);
      })
      .catch((error) => {
        console.log(error);
        setResponse({
          message: "Failed to load risk metadata.",
          severity: "error",
        });
        setShowMessage(true);
      });
  }, [apiClient, setRiskMetadata]);

  const RiskRatingLink = () => (
    <HashLink to="/help#RISKRATING" target="_blank">
      Description of Probability and Impact ratings
      <OpenInNewIcon style={{ fontSize: 12 }} />
    </HashLink>
  );

  const RiskDescLink = () => (
    <HashLink to="/help#RISKS" target="_blank">
      Description of Risks
      <OpenInNewIcon style={{ fontSize: 12 }} />
    </HashLink>
  );

  useEffect(() => {
    fetchRiskMetedata();
  }, [fetchRiskMetedata]);

  const getRiskTitle = (key) =>
    riskMetadata.flatMap((e) => e.risks).find((r) => r.key === key).title;

  const isRiskActive = (key) =>
    riskMetadata.flatMap((e) => e.risks).find((r) => r.key === key).active;

  const getDefaultProbability = (key) =>
    riskMetadata.flatMap((e) => e.risks).find((r) => r.key === key)
      .defaultProbability;

  const getDefaultImpact = (key) =>
    riskMetadata.flatMap((e) => e.risks).find((r) => r.key === key)
      .defaultImpact;

  const editRisk = (risk) => {
    if (editRiskOrginalState.id !== undefined) {
      setRisks((oldRisks) => [...oldRisks, { ...editRiskOrginalState }]);
    }
    setEditRiskOrginalState(risk);
    setRisks((oldRisks) => [...oldRisks.filter((r) => r.id !== risk.id)]);
    setId({ ...id, value: risk.id });
    setType({ ...type, value: risk.types[0] });
    setName({ ...name, value: risk.name });
    setProbability({ ...probability, value: risk.probability });
    setImpact({ ...impact, value: risk.impact });
  };

  const clearFormData = (data) => {
    setRisks((oldRisks) => [...oldRisks, { ...data }]);
    setEditRiskOrginalState({});
    setId({ ...id, value: "" });
    setType({ ...type, value: "" });
    setName({ ...name, value: "" });
    setProbability({ ...probability, value: "" });
    setImpact({ ...impact, value: "" });
  };

  const upsertRisk = async (e) => {
    e.preventDefault();
    const isNameBlank = isBlank(name);
    const isProbabilityBlank = isBlank(probability);
    const isImpactBlank = isBlank(impact);
    isNameBlank && setName({ ...name, hasError: true });
    isProbabilityBlank && setProbability({ ...probability, hasError: true });
    isImpactBlank && setImpact({ ...probability, hasError: true });

    if (!isNameBlank && !isProbabilityBlank && !isImpactBlank) {
      const risk = {
        id: id?.value === "" ? null : id.value,
        name: name.value,
        probability: probability.value,
        impact: impact.value,
      };
      if (id?.value === "") {
        apiClient
          .createRisk(projectId, risk)
          .then((response) => {
            clearFormData(response);
            setResponse({
              message: "Risk added successfully",
              severity: "success",
            });
          })
          .catch((error) => {
            setResponse({
              message: "Risk can not be added",
              severity: "error",
            });
          })
          .finally(() => {
            setShowMessage(true);
          });
      } else {
        apiClient
          .updateRisk(projectId, risk)
          .then((response) => {
            clearFormData(response);
            setResponse({
              message: "Risk updated successfully",
              severity: "success",
            });
          })
          .catch((error) => {
            setResponse({
              message: ("Risk can not be updated [error: %s]", error),
              severity: "error",
            });
          })
          .finally(() => {
            setShowMessage(true);
          });
      }
    }
  };

  const removeRisk = async (riskId) => {
    apiClient
      .deleteRisk(projectId, riskId)
      .then((response) => {
        setResponse({
          message: "Risk removed successfully",
          severity: "success",
        });
        setRisks((oldRisks) => [...oldRisks.filter((r) => r.id !== riskId)]);
      })
      .catch((error) => {
        setResponse({
          message: "Risk could not be removed",
          severity: "error",
        });
      })
      .finally(() => {
        setShowMessage(true);
      });
  };

  const displayRisk = (risk, index) => {
    const renderPrimary = () => (
      <>
        <Typography variant="h6">
          {getRiskTitle(risk.name)}
          <span style={{ padding: "10px" }}>
            {risk.types.map((t, i) => (
              <Chip
                key={i}
                label={t}
                aria-label={"risk-type-" + i + "-" + index}
              />
            ))}
          </span>
        </Typography>
      </>
    );

    const renderSecondary = () => (
      <>
        <Typography variant="subtitle2" className={classes.subtitle}>
          Probability:
          <span style={{ padding: "5px", fontWeight: "bold" }}>
            {probabilitySet.find((p) => p.key === risk.probability).title}
          </span>
          Impact:
          <span style={{ padding: "5px", fontWeight: "bold" }}>
            {impactSet.find((i) => i.key === risk.impact).title}
          </span>
        </Typography>
      </>
    );
    return (
      <ListItem aria-label={"risk-" + index} key={index}>
        <ListItemIcon>
          <WarningIcon />
        </ListItemIcon>
        <ListItemText
          disableTypography={true}
          primary={renderPrimary()}
          secondary={renderSecondary()}
        />
        {isRiskActive(risk.name) && (
          <Button data-testid="edit" onClick={() => editRisk(risk)}>
            <EditIcon />
          </Button>
        )}
        <Button data-testid="remove" onClick={() => removeRisk(risk.id)}>
          <DeleteIcon />
        </Button>
      </ListItem>
    );
  };

  if (!riskMetadata) {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <CircularProgress role="progress-icon" />
      </div>
    );
  }

  const handlePresetValue = (event) => {
    const riskName = event.target.value;
    setName({ value: riskName, hasError: false });
    const impact = getDefaultImpact(riskName);
    const probability = getDefaultProbability(riskName);
    setImpact({ value: impact, hasError: false });
    setProbability({ value: probability, hasError: false });
  };

  return (
    <Card aria-label={"risk-section"} data-testid={"risk-" + projectId}>
      <CardContent style={{ position: "relative" }}>
        <Typography variant="body1" style={{ padding: 5 }}>
          Risk Analysis and Management is a key project management practice to
          ensure that the least number of surprises occur while your project is
          underway. The purpose of this section is to help us understand which
          of the many risks we face are worth our attention, help us decide how
          to focus our limited energy and time, and help us think about how to
          prevent or reduce the chance of a risk being realised.
        </Typography>
        <Typography variant="body1" style={{ padding: 5 }}>
          {" "}
          Description of Probability and Impact ratings: <RiskRatingLink />
        </Typography>
        <Typography variant="body1" style={{ padding: 5 }}>
          Description of individual Risks, Desired States, and potential
          Mitigations: <RiskDescLink />{" "}
        </Typography>
        <Typography variant="body1" style={{ padding: 5 }}>
          When choosing a risk the Probability and Impact rating will
          auto-populate - you are strongly encouraged to keep the default value
          unless there is a clear reason not to. Please adjust ratings after
          mitigations (e.g. detection or prevention controls) are put in place.
        </Typography>
      </CardContent>

      <CardActions>
        <form
          aria-label="add-risk"
          noValidate
          onSubmit={upsertRisk}
          style={{ width: "100%" }}
        >
          <Grid
            container
            alignItems="center"
            spacing={3}
            className={classes.gridContainer}
          >
            <TextField id="id" data-testid="id" value={name.id} type="hidden" />
            <Grid item xs>
              <TextField
                id="type"
                select
                label="Risk Type"
                variant="outlined"
                value={type.value}
                onChange={(event) => {
                  setType({ value: event.target.value, hasError: false });
                  setName({ value: "", hasError: false });
                }}
                required
                fullWidth
              >
                {riskMetadata
                  .map((e) => e.type)
                  .map((t, i) => (
                    <MenuItem key={i} value={t}>
                      {t}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                id="name"
                select
                label="Risk Name"
                variant="outlined"
                value={name.value}
                onChange={(event) => handlePresetValue(event)}
                required
                error={name.hasError}
                helperText={name.hasError && "*Required"}
                fullWidth
              >
                {riskMetadata
                  .filter((e) => e.type === type.value)
                  .flatMap((e) => e.risks)
                  .filter((r) => r.active)
                  .map((r, i) => (
                    <MenuItem key={i} value={r.key}>
                      {r.title}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                id="probability"
                select
                label="Probability"
                variant="outlined"
                value={probability.value}
                onChange={(event) =>
                  setProbability({ value: event.target.value, hasError: false })
                }
                required
                error={probability.hasError}
                helperText={probability.hasError && "*Required"}
                fullWidth
              >
                {probabilitySet.map((p, i) => (
                  <MenuItem key={i} value={p.key}>
                    {p.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                id="impact"
                select
                label="Impact"
                variant="outlined"
                value={impact.value}
                onChange={(event) =>
                  setImpact({ value: event.target.value, hasError: false })
                }
                required
                error={impact.hasError}
                helperText={impact.hasError && "*Required"}
                fullWidth
              >
                {impactSet.map((p, i) => (
                  <MenuItem key={i} value={p.key}>
                    {p.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                type="submit"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardActions>
      <CardContent aria-label="project-risks">
        {risks && risks.length > 0 ? (
          <List>
            {risks.sort((a, b) => a.id - b.id).map((r, i) => displayRisk(r, i))}
          </List>
        ) : (
          <Typography
            aria-label="no-risk"
            variant="overline"
            className={classes.emptyRisksMessage}
          >
            Currently no risk
          </Typography>
        )}
        {response && (
          <Snackbar
            open={showMessage}
            autoHideDuration={6000}
            onClose={() => setShowMessage(false)}
          >
            <Alert severity={response.severity}>{response.message}</Alert>
          </Snackbar>
        )}
      </CardContent>
    </Card>
  );
}
