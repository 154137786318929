import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "@fontsource/inter";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 100,
    width: 100,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontFamily: "bitter",
  },
  RED: {
    backgroundColor: "#F2617A",
  },

  AMBER: {
    backgroundColor: "#CC850A",
  },

  GREEN: {
    backgroundColor: "#6B9E78",
  },

  UNKNOWN: {
    backgroundColor: "white",
  },

  ragFilterButton: {
    backgroundColor: "#003D4F",
    border: "none",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.3)",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    marginTop: "1.3rem",
    fontFamily: "bitter",
    fontWeight: "bold",
    fontSize: 14,
  },
  statusNumber: {
    fontSize: "2.4rem",
    fontWeight: "bold",
    fontFamily: "bitter",
  },
  clearRagFilter: {
    marginTop: "0.65rem",
    color: "#003D4F",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.3)",
    },
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  header: {
    textAlign: "center",
  },
  arrangement: {
    padding: "0.5em 0",
  },
  filterAlignment: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  overallStatus: {
    backgroundColor: "#003D4F",
    display: "flex",
    justifyContent: "space-evenly",
    padding: "2.4rem 0 2.12rem",
    marginTop: "5px",
  },
  placeholder: {
    height: "2.4rem",
  },
  ragLink: {
    textDecoration: "none",
    color: "#003D4F",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
});

export default function OverallStatus(props) {
  const classes = useStyles();

  const capitaliseFirstLetter = function camalize(str) {
    return str[0].toUpperCase() + str.substring(1).toLowerCase();
  };

  const ragFilterToColor = function (ragFilter) {
    switch (ragFilter) {
      case "RED":
        return "#F2617A";
      case "AMBER":
        return "#CC850A";
      case "GREEN":
        return "#6B9E78";
      case "UNKNOWN":
        return "white";
      default:
        return "inherit";
    }
  };

  return (
    <div className={classes.arrangement}>
      <Grid className={classes.headerContainer} container>
        <Grid item>
          <Typography
            style={{ color: "#003d4f", fontFamily: "bitter" }}
            variant="h4"
            className={classes.header}
          >
            {props.region && props.region === "DE"
              ? "Delivery Confidence"
              : "Overall Status"}
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid item>
        <Grid container className={classes.overallStatus}>
          <Grid item>
            {props.overallStatus.rag && (
              <button
                className={classes.ragFilterButton}
                onClick={() => props.setRagFilter("RED")}
              >
                <Paper className={`${classes.paper} ${classes.RED}`}>
                  <Typography
                    variant="h5"
                    className={classes.statusNumber}
                    style={{
                      color: "white",
                    }}
                  >
                    {props.getStatus(props.overallStatus.rag.RED)}
                  </Typography>
                </Paper>
                <Typography
                  style={{ color: "#F2617A" }}
                  variant="h6"
                  className={classes.statusTitle}
                >
                  Red
                </Typography>
              </button>
            )}
          </Grid>
          <Grid item>
            {props.overallStatus.rag && (
              <button
                className={classes.ragFilterButton}
                onClick={() => props.setRagFilter("AMBER")}
              >
                <Paper className={`${classes.paper} ${classes.AMBER}`}>
                  <Typography
                    variant="h5"
                    className={classes.statusNumber}
                    style={{
                      color: "white",
                    }}
                  >
                    {props.getStatus(props.overallStatus.rag.AMBER)}
                  </Typography>
                </Paper>
                <Typography
                  style={{ color: "#CC850A" }}
                  variant="h6"
                  className={classes.statusTitle}
                >
                  Amber
                </Typography>
              </button>
            )}
          </Grid>
          <Grid item>
            {props.overallStatus.rag && (
              <button
                className={classes.ragFilterButton}
                onClick={() => props.setRagFilter("GREEN")}
              >
                <Paper className={`${classes.paper} ${classes.GREEN}`}>
                  <Typography
                    variant="h5"
                    className={classes.statusNumber}
                    style={{
                      color: "white",
                    }}
                  >
                    {props.getStatus(props.overallStatus.rag.GREEN)}
                  </Typography>
                </Paper>
                <Typography
                  style={{ color: "#6B9E78" }}
                  variant="h6"
                  className={classes.statusTitle}
                >
                  Green
                </Typography>
              </button>
            )}
          </Grid>
          <Grid item>
            {props.overallStatus.rag && (
              <Grid
                className={classes.ragFilterButton}
                onClick={() => props.setRagFilter("UNKNOWN")}
              >
                <Paper
                  style={{ justifyContent: "center" }}
                  className={`${classes.paper} ${classes.UNKNOWN}`}
                >
                  <Typography
                    variant="h5"
                    className={classes.statusNumber}
                    style={{
                      color: "#003D4F",
                    }}
                  >
                    {props.getStatus(props.overallStatus.rag.UNKNOWN)}
                  </Typography>
                </Paper>
                <Typography
                  style={{ color: "white" }}
                  variant="h6"
                  className={classes.statusTitle}
                >
                  Not submitted
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {(props.ragFilter &&
        ["RED", "GREEN", "AMBER", "UNKNOWN"].includes(props.ragFilter) && (
          <Grid>
            <Grid
              className={classes.clearRagFilter}
              onClick={() => props.setRagFilter("ALL")}
            >
              <span
                style={{
                  color: props.ragFilter === "UNKNOWN" ? "#003D4F" : "white",
                  backgroundColor: ragFilterToColor(props.ragFilter),
                  padding: " 2px 5px",
                  fontFamily: "inter",
                }}
              >
                {capitaliseFirstLetter(props.ragFilter) + " projects selected"}
              </span>
              <Grid style={{ fontFamily: "inter" }}>
                <img
                  src="./icons/clearFilter.svg"
                  style={{ marginRight: "5px" }}
                  alt="Clear Filter"
                />
                Clear filter
              </Grid>
            </Grid>
          </Grid>
        )) || <div className={classes.placeholder}></div>}
      <Typography style={{ color: "#003d4f", fontFamily: "inter" }}>
        <a href="/help#RAG" target="blank" className={classes.ragLink}>
          <b>Click here</b>
        </a>{" "}
        to see <span style={{ color: "#F2617A" }}>R</span>
        <span style={{ color: "#CC850A" }}>A</span>
        <span style={{ color: "#6B9E78" }}>G</span> definitions
      </Typography>
    </div>
  );
}
