import React, { useState } from "react";
import { IconButton, Popover, Typography, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import RagDefinitionPopover from "./RagDefinitionPopover";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
}));

export default function Heading({ start, end, children }) {
  const [anchorElRAGDefinition, setAnchorElRAGDefinition] = useState(null);
  const classes = useStyles();

  const getCurrentTitle = (selected) => {
    return (
      "(Currently selected " +
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(selected.weekEnding)) +
      ")"
    );
  };

  return (
    <>
      <Typography variant="h6" component="span">
        <IconButton
          color="primary"
          aria-label="Show RAG definition"
          onMouseEnter={(e) => setAnchorElRAGDefinition(e.currentTarget)}
          onMouseLeave={() => setAnchorElRAGDefinition(null)}
        >
          <InfoIcon />
        </IconButton>
        Delivery report from{" "}
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(start)}{" "}
        to{" "}
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(end)}{" "}
        {children && getCurrentTitle(children)}
      </Typography>

      <Popover
        open={Boolean(anchorElRAGDefinition)}
        onClose={() => {
          setAnchorElRAGDefinition(null);
        }}
        anchorEl={anchorElRAGDefinition}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popover}
        aria-label="RAG Definition"
      >
        <RagDefinitionPopover />
      </Popover>
    </>
  );
}
