import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import { Delete as DeleteButton } from "../Buttons";
import * as PropTypes from "prop-types";
import { isEmailValid, isNameValid } from "../../../../shared/utils";

const useStyles = makeStyles({
  emailDomainLabel: {
    "&>p": {
      padding: 0,
    },
  },
});

ReporterForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  onNameChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  handleDelete: PropTypes.func,
};

const thoughtworksDomain = "@thoughtworks.com";

export default function ReporterForm(props) {
  const classes = useStyles();
  const {
    name,
    email,
    reporters,
    onNameChange,
    onEmailChange,
    handleDelete,
    emailError,
  } = props;

  const [reporterName, setReporterName] = useState({
    error: false,
    errorMsg: "",
  });

  const [reporterEmail, setReporterEmail] = useState({
    error: false,
    errorMsg: "",
  });

  return (
    <Grid item style={{ paddingTop: 10 }}>
      <TextField
        id="project-delivery-reporter-name"
        label="Name"
        value={name || ""}
        onBlur={() => setReporterName({ ...isNameValid(name) })}
        onFocus={() => setReporterName({ error: false, errorMsg: "" })}
        error={reporterName.error}
        helperText={reporterName.error && reporterName.errorMsg}
        onChange={onNameChange}
        variant="standard"
      />

      <TextField
        id="project-delivery-reporter-email"
        label="Email"
        value={email?.replace(thoughtworksDomain, "") || ""}
        error={reporterEmail.error || emailError}
        onBlur={() => setReporterEmail({ ...isEmailValid(email, reporters) })}
        onFocus={() => setReporterEmail({ error: false, errorMsg: "" })}
        helperText={
          emailError
            ? "*Email already in use"
            : reporterEmail.error && reporterEmail.errorMsg
        }
        onChange={onEmailChange}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment className={classes.emailDomainLabel} position="end">
              {thoughtworksDomain}
            </InputAdornment>
          ),
        }}
      />
      <DeleteButton onDelete={handleDelete} />
    </Grid>
  );
}
