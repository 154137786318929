import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core";
import useMarkets from "../../shared/hooks/useMarkets";

export default function Markets({
  onChange,
  defaultValue,
  styling,
  error = false,
}) {
  const [value, setValue] = useState(defaultValue ? defaultValue : -1);
  const markets = useMarkets(false);

  return (
    <>
      {markets.isError && (
        <Typography>
          There was an issue fetching data please reload the browser or try
          again in a bit.
        </Typography>
      )}
      {markets.data && markets.data.length > 0 && (
        <FormControl variant={styling && "outlined"} error={error} fullWidth>
          {!styling && <InputLabel id="market-label">Market *</InputLabel>}
          <Select
            id="markets"
            labelId="market-label"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChange && onChange(e.target.value);
            }}
            fullWidth
          >
            <MenuItem selected disabled value={-1}>
              <em>Choose a market</em>
            </MenuItem>
            {markets.data.map((market) => (
              <MenuItem key={market.crmMarketReference} value={market.crmMarketReference}>
                {market.name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>Market required</FormHelperText>}
        </FormControl>
      )}
    </>
  );
}
