import React from "react";
import { Fab } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import * as PropTypes from "prop-types";
import AddButton from "./AddButton";

AddButton.propTypes = {
  handleEdit: PropTypes.func
};

export default function EditButton({ handleEdit }) {
  return (
    <div className={""}>
      <Fab
        color="primary"
        aria-label="Edit Delivery Reporter"
        size="small"
        onClick={handleEdit}
      >
        <EditIcon />
      </Fab>
    </div>
  );
}
