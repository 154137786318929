import React from "react";
import * as PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";

DeleteButton.propTypes = {
  onDelete: PropTypes.func
};

export default function DeleteButton({ onDelete }) {
  return (<Fab
      color="primary"
      aria-label="Delete Delivery Reporter"
      size="small"
      onClick={onDelete}
    >
      <DeleteIcon />
    </Fab>
  );
}
