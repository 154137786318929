import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from '../SecureRoute';

import HomePage from "../../../pages/HomePage";
import UsersPage from "../../../pages/UsersPage";
import HelpPage from "../../../pages/HelpPage";
import ContributingPage from "../../../pages/ContributingPage";
import TrendsPage from "../../../pages/TrendsPage";
import ProjectsPage from "../../../pages/ProjectsPage";
import DeliveryReportPage from "../../../pages/DeliveryReportPage";


import Loading from '../Loading';

const AppRoutes = () => {
  return (
    <Routes>   
        <Route path='implicit/callback' element={<LoginCallback loadingElement={<Loading />} />} />
        <Route path='/' element={<RequiredAuth />}>
            <Route path='/' element={<HomePage />} />
        </Route>
        
        <Route path='/users' element={<RequiredAuth />}>
            <Route path='' element={<UsersPage />} />
        </Route>

        <Route path='/help' element={<RequiredAuth />}>
            <Route path='' element={<HelpPage />} />
        </Route>

        <Route path='/contributing' element={<RequiredAuth />}>
            <Route path='' element={<ContributingPage />} />
        </Route>

        <Route path='/trends' element={<RequiredAuth />}>
            <Route path='' element={<TrendsPage />} />
        </Route>

        <Route path='/projects' element={<RequiredAuth />}>
            <Route path='/projects/:clientName?/:projectName?' element={<ProjectsPage />} />
        </Route>

        <Route path='/delivery-report' element={<RequiredAuth />}>
            <Route path='/delivery-report/:type?/:id?' element={<DeliveryReportPage />} />
        </Route>
    </Routes>
  );
};

export default AppRoutes;