import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import likelihoodData from "./Likelihood_metadata.json";

export default function LikelihoodTable() {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(likelihoodData);
  }, []);
  const whiteSpace = { whiteSpace: "pre-line" };
  return (
    <Grid container style={{ paddingLeft: "2em", paddingRight: "2em" }}>
      <Grid item xs={12}>
        <Box mt={8}>
          <Box mb={3}>
            <Typography variant="h4" id="Likelihood">
              Likelihood Rating
            </Typography>
          </Box>
          <TableContainer component={Paper} id="likelihood table">
            <Table aria-label="likelihood table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Likelihood Rating
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Probability
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", backgroundColor: "#003d4f" }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {row.rating}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.probability}
                    </TableCell>
                    <TableCell align="left" style={whiteSpace}>
                      {row.description}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
