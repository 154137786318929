import React from "react";
import { MenuItem, Select } from "@material-ui/core";

function SensitivitySelector({ onChange, value }) {
  return (
    <Select
      id="sensitivity-selector"
      value={value}
      variant="outlined"
      onChange={(e) => onChange(e.target.value)}
    >
      <MenuItem value="UNKNOWN">Unknown</MenuItem>
      <MenuItem value="TW_SENSITIVE">TW Sensitive</MenuItem>
      <MenuItem value="PUBLIC_DOMAIN">Public Domain</MenuItem>
    </Select>
  );
}

export default SensitivitySelector;
