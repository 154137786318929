import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  statusChange: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: "5px",
    backgroundColor: "#003D4F",
    padding: "2.35rem 0 1.4rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  arrangement: {
    padding: "0.5em 0",
  },
  projectTotals: {
    marginTop: "0.8rem",
    color: "#003D4F",
  },
  projectTotalText: {
    lineHeight: "1.6rem",
    // fontWeight:"600",
    fontSize: "16px",
  },
  statusText: {
    fontWeight: "500",
    fontSize: "2.5rem",
    fontFamily: "inter",
    marginTop: "3.5px",
  },
});

export default function StatusChange(props) {
  const classes = useStyles();

  return (
    <div className={classes.arrangement}>
      <div className={classes.header}>
        <Typography
          variant="h4"
          style={{ color: "#003D4F", fontFamily: "bitter" }}
        >
          Status Change
        </Typography>
        <Typography component="span">
          compared to the previous report
        </Typography>
      </div>
      <Grid container className={classes.statusChange}>
        <Grid item>
          <span title="Deteriorated">
            <img
              src="/icons/deteriorated.svg"
              alt="Deteriorated status changes"
            />
            <Typography
              className={classes.statusText}
              style={{ color: "#F2617A" }}
            >
              {props.getStatus(props.statusChange.DETERIORATED)}
            </Typography>
          </span>
        </Grid>
        <Grid item>
          <span title="Stationary">
            <img src="/icons/stationary.svg" alt="Stationary status changes" />
            <Typography
              className={classes.statusText}
              style={{ color: "#CC850A" }}
            >
              {props.getStatus(props.statusChange.STATIONARY)}
            </Typography>
          </span>
        </Grid>
        <Grid item>
          <span title="Improved">
            <img src="/icons/improved.svg" alt="Improved status changes" />
            <Typography
              className={classes.statusText}
              style={{ color: "#6B9E78" }}
            >
              {props.getStatus(props.statusChange.IMPROVED)}
            </Typography>
          </span>
        </Grid>

        <Grid item>
          <span title="Unknown">
            <img src="/icons/unknown.svg" alt="Unknown status changes" />
            <Typography
              className={classes.statusText}
              style={{ color: "white" }}
            >
              {props.getStatus(props.statusChange.UNKNOWN)}
            </Typography>
          </span>
        </Grid>
      </Grid>

      <div>
        <div className={classes.projectTotals}>
          <Typography className={classes.projectTotalText}>
            Active projects: {props.nonInternalActiveProjects}
          </Typography>
          <Typography className={classes.projectTotalText}>
            Delivery reports submitted: {props.deliveredProjects}
          </Typography>
        </div>
      </div>
    </div>
  );
}

StatusChange.propTypes = {
  getStatus: PropTypes.func.isRequired,
  statusChange: PropTypes.shape({
    DETERIORATED: PropTypes.number,
    STATIONARY: PropTypes.number,
    IMPROVED: PropTypes.number,
    UNKNOWN: PropTypes.number,
  }).isRequired,
  nonInternalActiveProjects: PropTypes.number.isRequired,
  deliveredProjects: PropTypes.number.isRequired,
};
